import { Box, makeStyles, Typography } from "@material-ui/core";
import { strings } from "common";
import PropTypes from "prop-types";
import { TableVirtuoso } from "react-virtuoso";
import React, { Dispatch, SetStateAction } from "react";
import { buildDateString, buildPreAdviceFileName } from "../../utils/cases";
import { useSalesOrder } from "../../utils/salesOrders";
import { LightGreyRow, MainButton } from "../Common";
import CasesDataRow from "./CasesDataRow";
import { CaseDataSchema, SalePriceObj, CreateSaleOrderDataSchema, ShipSchema } from "../../interfaces/case.interface";
import CasesLabelRow from "./CasesLabelRow";

const useStyles = makeStyles((theme) => ({
  summaryContainer: {
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    gap: theme.spacing(8),
  },
  summary: {
    backgroundColor: "#FAFAFA",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: theme.spacing(8, 0),
    padding: theme.spacing(8, 8, 0, 8),
  },
  title: {
    marginBottom: theme.spacing(8),
  },
  text: {
    lineHeight: "21px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#1C1C20",
  },
  rowName: {
    fontSize: "12px",
    color: "#1D4854",
    marginRight: theme.spacing(4),
  },
  backButton: {
    marginRight: theme.spacing(10),
    background: "white",
    color: "grey",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
}));

interface SummaryProps {
  selectedRows: Array<CaseDataSchema>;
  handleBack: React.MouseEventHandler<HTMLButtonElement> | undefined;
  setOpen: (val: boolean) => void;
  data: CreateSaleOrderDataSchema;
  shipper: ShipSchema | null;
  isFromDeal: boolean;
  setSuccessModal?: (val: boolean) => void;
  setErrorModal?: (val: string) => void;
  setSalePrices: Dispatch<SetStateAction<SalePriceObj[]>>;
  salePrices: SalePriceObj[];
  deSelectAll?: () => void;
}

const timeConverter = (date: Date | null) => {
  return date ? `${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}` : "";
};

const customerCodes = ["CULTMM", "CULTKD"];

const Summary = ({
  selectedRows,
  handleBack,
  setOpen,
  data,
  shipper,
  isFromDeal,
  setSuccessModal,
  setErrorModal,
  salePrices,
  setSalePrices,
  deSelectAll,
}: SummaryProps) => {
  const classes = useStyles();
  const { mutateAsync: postSalesOrder, isLoading } = useSalesOrder();

  const transferOrderBondType = "T";

  const updatedValuesSelectedRows = selectedRows.map((el) => {
    if (salePrices.find((sp) => sp.id === el.id)) {
      const obj = salePrices.find((sp) => sp.id === el.id)!;
      return {
        ...el,
        clientPurchasePrice: obj.salePrice,
      };
    } else {
      return el;
    }
  });

  const totalPrice = updatedValuesSelectedRows
    .reduce((prev, curr) => {
      return prev + (curr.clientPurchasePrice || 0);
    }, 0)
    .toFixed(2);

  const handleSubmit = async () => {
    const salePricesArray = [...salePrices];
    selectedRows.forEach((item) => {
      const index = salePricesArray.findIndex((e) => e.id === item.id);
      if (index === -1) {
        salePricesArray.push({
          id: item.id,
          salePrice: parseFloat(String(item.clientPurchasePrice || item.cwPurchasePrice)),
        });
      }
    });

    const selectedWineIds = selectedRows.map((caseRow) => caseRow.id);
    const hasExceptCustomerCode = customerCodes.indexOf(data.deliveryAddress1 ? data.deliveryAddress1 : "") > -1;
    const needCreatePAXml = data.hasExceptAddress && hasExceptCustomerCode;

    const requestBody = {
      AWRS: data.AWRS,
      documentRef: data.documentRef,
      address: data.address,
      bondType: data.bondType,
      bookedOrCollectionDate: data.bookedOrCollectionDate,
      collectionBy: data.collectionBy,
      collectionDeliveryArea: data.collectionDeliveryArea,
      collectionTime: timeConverter(data.collectionTime as Date | null),
      consigneeAddress1: data.consigneeAddress1,
      consigneeAddress2: data.consigneeAddress2,
      consigneeAddress3: data.consigneeAddress3,
      consigneeCountry: data.consigneeCountry,
      consigneeName: data.consigneeName,
      consigneePostcode: data.consigneePostcode,
      consigneeTown: data.consigneeTown,
      contactName: data.contactName,
      contactPhoneNumber: data.contactPhoneNumber,
      customerPORef: data.customerPORef,
      deliveryAddress1: data.deliveryAddress1,
      deliveryAddress2: data.deliveryAddress2,
      deliveryAddress3: data.deliveryAddress3,
      deliveryCountry: data.deliveryCountry,
      deliveryEntityName: data.deliveryEntityName,
      deliveryInstructions: data.deliveryInstructions,
      deliveryName: data.deliveryName,
      deliveryPostcode: data.deliveryPostcode,
      deliveryTownOrCounty: data.deliveryTownOrCounty,
      dpAccountRef: data.dpAccountRef,
      earliestTime: timeConverter(data.earliestTime as Date | null),
      earliestTime2: timeConverter(data.earliestTime2 as Date | null),
      fkCustomer: data.fkCustomer,
      fkSalesOrderType: data.fkSalesOrderType,
      hasExceptAddress: data.hasExceptAddress,
      isBookingRequired: data.isBookingRequiredAPIField,
      latestTime: timeConverter(data.latestTime as Date | null),
      latestTime2: timeConverter(data.latestTime2 as Date | null),
      orderDate: data.orderDate,
      payCustomsDuty: data.payCustomsDuty,
      pickInstructions: data.pickInstructions,
      siteCode: data.siteCode,
      vehicleRegistration: data.vehicleRegistration,
      caseIds: selectedWineIds,
      destinationWareouseId:
        data.bondType === transferOrderBondType ? (data.destinationWareouseId = shipper ? shipper.id : null) : null,
      needCreatePAXml: needCreatePAXml,
      dataToUpdate: salePricesArray,
    };

    await postSalesOrder(requestBody)
      .then((result) => {
        if (data.bondType === transferOrderBondType && needCreatePAXml) {
          result.preadviceXml.xmlPayloadList.forEach((item) => {
            const fileName = buildPreAdviceFileName(
              item.purchaseOrderNumber,
              data.deliveryAddress1 ? data.deliveryAddress1 : ""
            );
            downloadPreAdviceXmlContent(fileName, item.xmlPayload);
          });
          downloadXmlContent(result.salesOrderXml.xmlContent, "SO", result.salesOrderXml.warehouseVisionCode);
        } else {
          downloadXmlContent(result.xmlContent, "SO", result.warehouseVisionCode);
        }
        if (isFromDeal) {
          if (setSuccessModal) {
            setSuccessModal(true);
          }
        }
      })
      .catch((error: Error) => {
        if (setErrorModal) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          setErrorModal(error.description);
        }
      })
      .finally(() => {
        setOpen(false);
        deSelectAll && deSelectAll();
      });
  };

  const downloadXmlContent = (xmlContent: string, filePrefix: string, suffix: string) => {
    // TODO: remove, when Vision EDI testing moves from sending emails to sending API calls
    const element = document.createElement("a");
    const file = new Blob([xmlContent], { type: "text/xml" });
    const blobUrl = URL.createObjectURL(file);
    element.href = blobUrl;
    if (filePrefix === "PO") {
      element.download = filePrefix + new Date().toISOString().split("T")[0] + ".CULTMM.xml";
    } else {
      element.download = buildFileName(filePrefix, suffix);
    }
    document.body.appendChild(element);
    element.click();
    window.URL.revokeObjectURL(blobUrl);
  };

  const downloadPreAdviceXmlContent = (fileName: string, xmlContent: string) => {
    // TODO: remove, when Vision EDI testing moves from sending emails to sending API calls
    const element = document.createElement("a");
    const file = new Blob([xmlContent], { type: "text/xml" });
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element);
    element.click();
  };

  const buildFileName = (filePrefix: string, suffix: string) => {
    const nowDate = new Date();
    const dateString = buildDateString(nowDate);
    const milliseconds = new Intl.DateTimeFormat("en-GB", { fractionalSecondDigits: 3 }).format(nowDate);
    return `${filePrefix}${dateString}${milliseconds}.${suffix}`;
  };

  const handleSalePriceChange = (id: number, price: string) => {
    const tempSalePrices: SalePriceObj[] = [...salePrices];
    const itemIndex = salePrices.findIndex((item) => item.id === id);
    if (itemIndex > -1) {
      if (tempSalePrices.length > 0) {
        if (tempSalePrices[itemIndex] && tempSalePrices[itemIndex] != null) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tempSalePrices[itemIndex].salePrice = parseFloat(price.replace(/,/g, ""));
        }
      }
    } else {
      tempSalePrices.push({ id: id, salePrice: parseFloat(price.replace(/,/g, "")) });
    }
    setSalePrices(tempSalePrices);
  };

  const information = [
    [
      {
        label: strings.general_information,
      },
      {
        label: strings.fk_customer,
        value: data.fkCustomer,
      },
      {
        label: strings.bond_type,
        value: data.bondType,
      },
      {
        label: strings.dp_account_reference,
        value: data.dpAccountRef,
      },
      {
        label: strings.order_date,
        value: data.orderDate ? data.orderDate : "",
      },
      {
        label: strings.receiving_warehouse_or_shipper,
        value: data.deliveryEntityName,
      },
      {
        label: strings.delivery_address_line_1,
        value: data.deliveryAddress1,
      },
      {
        label: strings.delivery_address_line_2,
        value: data.deliveryAddress2,
      },
      {
        label: strings.delivery_address_line_3,
        value: data.deliveryAddress3,
      },
      {
        label: strings.delivery_town_county,
        value: data.deliveryTownOrCounty,
      },
      {
        label: strings.delivery_postcode,
        value: data.deliveryPostcode,
      },
      {
        label: strings.delivery_country,
        value: data.deliveryCountry,
      },
    ],
    [
      {
        label: strings.delivery,
      },
      {
        label: strings.fk_sales_order_type,
        value: data.fkSalesOrderType,
      },
      {
        label: strings.site_code,
        value: data.siteCode,
      },
      {
        label: strings.delivery_instructions,
        value: data.deliveryInstructions,
      },
      {
        label: strings.pick_instructions,
        value: data.pickInstructions,
      },
      {
        label: strings.earliest_time,
        value: data.earliestTime ? timeConverter(data.earliestTime as Date | null) : "",
      },
      {
        label: strings.latest_time,
        value: data.latestTime ? timeConverter(data.latestTime as Date | null) : "",
      },
      {
        label: strings.earliest_time_2,
        value: data.earliestTime2 ? timeConverter(data.earliestTime2 as Date | null) : "",
      },
      {
        label: strings.latest_time_2,
        value: data.latestTime2 ? timeConverter(data.latestTime2 as Date | null) : "",
      },
      {
        label: strings.customer_po_reference,
        value: data.customerPORef,
      },
      {
        label: strings.booked_or_collection_date,
        value: data.bookedOrCollectionDate,
      },
      {
        label: strings.collection_delivery_area,
        value: data.collectionDeliveryArea,
      },
      {
        label: strings.collection_by,
        value: data.collectionBy,
      },
    ],
    [
      {
        label: strings.customs,
      },
      {
        label: strings.excise_deferment_number,
        value: "",
      },
      {
        label: strings.customs_deferment_number,
        value: "",
      },
      {
        label: strings.pay_customs_duty,
        value: String(data.payCustomsDuty),
      },
      {
        label: strings.consignee_name,
        value: data.consigneeName,
      },
      {
        label: strings.consignee_address_1,
        value: data.consigneeAddress1,
      },
      {
        label: strings.consignee_town,
        value: data.consigneeTown,
      },
      {
        label: strings.consignee_postcode,
        value: data.consigneePostcode,
      },
      {
        label: strings.consignee_country,
        value: data.consigneeCountry,
      },
    ],
  ];

  return (
    <Box>
      <Typography className={classes.title} variant="h4">
        Cases
      </Typography>
      <Box display="flex" className={classes.summaryContainer}>
        <Typography variant="body2">
          {strings.sumSelected} {updatedValuesSelectedRows.length}
        </Typography>
        <Typography variant="body2">
          {strings.ClientPurchasePrice} £{totalPrice}
        </Typography>
      </Box>
      <TableVirtuoso
        style={{ height: 400 }}
        fixedHeaderContent={() => <CasesLabelRow />}
        totalCount={updatedValuesSelectedRows.length}
        data={updatedValuesSelectedRows}
        itemContent={(_, row: CaseDataSchema) => (
          <LightGreyRow key={row.id}>
            <CasesDataRow
              data={row}
              handleChange={(e) => handleSalePriceChange(row.id, e.target.value)}
              error={false}
            />
          </LightGreyRow>
        )}
      />

      <Box className={classes.summary}>
        {information.map((column) => (
          <Box>
            {column.map((row, i) => (
              <div className={classes.row}>
                {i === 0 ? (
                  <Typography className={classes.text}>{row.label}</Typography>
                ) : (
                  <Typography className={classes.rowName} variant="body2">
                    {row.label}
                  </Typography>
                )}
                <Typography>{row.value}</Typography>
              </div>
            ))}
          </Box>
        ))}
      </Box>
      <div>
        <MainButton className={classes.backButton} onClick={handleBack}>
          {strings.back}
        </MainButton>
        <MainButton disabled={isLoading} onClick={handleSubmit}>
          {strings.confirm}
        </MainButton>
      </div>
    </Box>
  );
};

Summary.propTypes = {
  selectedRows: PropTypes.array,
  setOpen: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  data: PropTypes.object,
  shipper: PropTypes.object,
  isFromDeal: PropTypes.bool,
  setSuccessModal: PropTypes.func,
  setErrorModal: PropTypes.func,
};

export default Summary;
