import { makeStyles, Typography, Box, withStyles, TablePagination } from "@material-ui/core";
import React from "react";

import PropTypes from "prop-types";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { getPages } from "../../utils/pagination";
import { rowsPerPageOptions } from "../../constants";
import { ClickableIcon } from "../Common";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.grey.A100,
    alignItems: "center",
    paddingLeft: theme.spacing(8),
  },
  tablePagination: {
    flex: 1,
  },
  paginationItems: {
    color: theme.palette.primary.light,
  },
  pageCount: {
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    whiteSpace: "nowrap",
  },
}));

const ActionRow = withStyles((theme) => ({
  root: {
    marginRight: theme.spacing(10),
    marginLeft: -theme.spacing(15),
    display: "flex",
    width: theme.spacing(150),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    bottom: 0,
  },
}))(Box);

interface PaginationProps {
  page: number;
  count: number;
  pageSize: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
  onPageChangeSize: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  ExportComponent?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
  nextPage: React.MouseEventHandler<HTMLButtonElement> | undefined;
  goToLastPage: React.MouseEventHandler<HTMLButtonElement> | undefined;
  goToFirstPage: React.MouseEventHandler<HTMLButtonElement> | undefined;
  previousPage: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const Pagination = ({
  page,
  count,
  pageSize,
  onPageChange,
  onPageChangeSize,
  ExportComponent,
  nextPage,
  goToLastPage,
  goToFirstPage,
  previousPage,
}: PaginationProps) => {
  const pages = getPages(pageSize, count);

  const classes = useStyles();

  return (
    <Box display="flex" className={classes.container}>
      {ExportComponent}

      <TablePagination
        className={classes.tablePagination}
        component={Box}
        rowsPerPageOptions={rowsPerPageOptions}
        count={count}
        rowsPerPage={pageSize}
        page={page}
        SelectProps={{
          inputProps: { "aria-label": "rows per page" },
          native: true,
        }}
        onPageChange={onPageChange}
        onRowsPerPageChange={onPageChangeSize}
        labelDisplayedRows={() => <span />}
        ActionsComponent={() => (
          <ActionRow>
            <ClickableIcon data-testid="firstPage" onClick={goToFirstPage}>
              <SkipPreviousIcon className={classes.paginationItems} />
            </ClickableIcon>

            <ClickableIcon data-testid="previousPage" onClick={previousPage}>
              <KeyboardArrowLeftIcon className={`${classes.paginationItems}`} />
            </ClickableIcon>

            <Typography variant="body1" className={classes.pageCount}>
              {`${page + 1} of ${pages}`}
            </Typography>

            <ClickableIcon data-testid="nextPage" onClick={nextPage}>
              <KeyboardArrowRightIcon className={`${classes.paginationItems}`} />
            </ClickableIcon>

            <ClickableIcon data-testid="lastPage" onClick={goToLastPage}>
              <SkipNextIcon className={classes.paginationItems} />
            </ClickableIcon>
          </ActionRow>
        )}
      />
    </Box>
  );
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  nextPage: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  goToLastPage: PropTypes.func.isRequired,
  goToFirstPage: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPageChangeSize: PropTypes.func.isRequired,
  ExportComponent: PropTypes.node,
};

Pagination.defaultProps = {
  ExportComponent: null,
};

export default Pagination;
