import { useCallback, useEffect, useMemo, useState } from "react";
import { strings } from "common";
import { atom, RecoilState, useRecoilState } from "recoil";
import { SetFilterSchema } from "../interfaces/table.interface";

const useFilters = (filterValues: Array<string>, param: string, initFilter: string | undefined, name: string) => {
  const initFilters = useCallback(
    (val: boolean, activeFilter: boolean) =>
      filterValues.reduce((accum: { [filer: string]: boolean | string }, filter: string) => {
        return activeFilter
          ? {
              ...accum,
              [filter]: Boolean(filter) === activeFilter,
            }
          : { ...accum, [filter]: val };
      }, {}),
    [filterValues]
  );

  const initialFilters = initFilters(true, false);
  const [isReady, setIsReady] = useState(false);

  const filtersState = useMemo(
    () =>
      atom({
        key: `${name}filtersState`,
        default: initialFilters,
      }),
    [name]
  );

  const allFilterState = useMemo<RecoilState<boolean>>(
    () =>
      atom({
        key: `${name}allFilterState`,
        default: Object.values(initialFilters).every((filter) => Boolean(filter)),
      }),
    [name]
  );

  const [filters, setFilters] = useRecoilState(filtersState);

  const [filterAll, setAllFilter] = useRecoilState(allFilterState);

  const getAllState = useCallback(() => {
    const values = filterValues.map((filter) => filters[filter]);
    return values.every((filter) => filter === true);
  }, [filterValues, filters]);

  useEffect(() => {
    if (initFilter && isReady) {
      setFilters(initFilters(true, Boolean(initFilter)));
    }
  }, [initFilter, isReady]);

  useEffect(() => {
    // controlls the filter all action
    setIsReady(true);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setFilters(initFilters(filterAll, false));
  }, [filterAll]);

  useEffect(() => {
    setAllFilter(getAllState());
  }, [filters, getAllState]);

  const setFilter: SetFilterSchema = {
    set(filter: string, value: boolean) {
      return filter === strings.all
        ? setAllFilter(value)
        : setFilters({
            ...filters,
            [filter]: value,
          });
    },
  };

  const getFilterQuery = () => {
    if (filterAll) return {};
    const activeFilters = filterValues.filter((filter) => filters[filter]);

    if (activeFilters.length === 0) {
      // hammer for the BE to not return results
      return { [param]: " " };
    }

    return { [param]: activeFilters };
  };

  const filterQuery = getFilterQuery();

  return [
    {
      ...filters,
      [strings.all]: filterAll,
    },
    setFilter,
    filterQuery,
  ];
};

export default useFilters;
