import { useQuery } from "react-query";
import { useClient } from "../providers/auth-context";
import { UserDetailSchema } from "../interfaces/user.interface";

function useUsersDetail(id: string, options: { enabled: boolean }) {
  const client = useClient<UserDetailSchema>();

  const result = useQuery({
    queryKey: ["usersDetail", { id }],
    queryFn: () => client(`users/${id}/details`, {}).then((data) => data),
    ...options,
  });

  return { ...result, data: result.data };
}

export { useUsersDetail };
