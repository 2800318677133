import { Box, withStyles } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { types, strings } from "common";
import { MainButton } from "../Common";

const ButtonRow = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    marginLeft: theme.spacing(8),
    paddingBottom: theme.spacing(3),
  },
}))(Box);

interface RowDataSchema {
  id: number;
  accountHolderName: string;
}

interface ShipButtonProps {
  selectedrows: RowDataSchema[];
}

const ShipButton = ({ selectedrows, ...props }: ShipButtonProps) => {
  const history = useHistory();
  const dealIds = selectedrows.map((row: RowDataSchema) => row.id);

  const handleShipCases = () => {
    if (dealIds && dealIds.length === 1) {
      history.push(`/deals/${dealIds[0]}`);
    } else {
      history.push(`/deals/${dealIds.join(",")}/ship-multiple`);
    }
  };

  return (
    <ButtonRow>
      <MainButton
        id="deal_select_cases_to_ship"
        {...props}
        startIcon={<Send />}
        onClick={handleShipCases}
        disabled={
          selectedrows.length === 0 ||
          !selectedrows.every((el: RowDataSchema) => el.accountHolderName === selectedrows[0]?.accountHolderName)
        }
      >
        {strings.select_cases_to_ship}
      </MainButton>
    </ButtonRow>
  );
};

ShipButton.propTypes = {
  selectedrows: PropTypes.arrayOf(types.stockDataShape).isRequired,
};

export default ShipButton;
