import { makeStyles, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  info: {
    display: "flex",
    alignItems: "center",
    background: "#C5E4FC",
    borderRadius: "40px",
    height: "45px",
    padding: theme.spacing(7),
    marginBottom: theme.spacing(8),
  },
  text: {
    marginLeft: theme.spacing(7),
  },
}));

const ExtraInfo = ({ data }: { data: string }) => {
  const classes = useStyles();
  return (
    <div className={classes.info}>
      <InfoIcon />
      <Typography className={classes.text}>{data}</Typography>
    </div>
  );
};

ExtraInfo.propTypes = {
  data: PropTypes.string,
};

export default ExtraInfo;
