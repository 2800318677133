import React from "react";
import { Box, makeStyles, Paper, Tab, Tabs, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import TicketTable from "./TicketTable";
import HistoryTable from "./HistoryTable";
import { strings, types } from "common";
import { useIsFetching } from "react-query";
import { TicketDataSchema } from "../../interfaces/ticket.interface";

const useStyles = makeStyles((theme) => ({
  selected: {
    background: theme.palette.grey.A100,
  },
  loader: {
    marginTop: theme.spacing(8),
    marginRight: theme.spacing(8),
  },
  history: {
    marginTop: -theme.spacing(8),
  },
  paper: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    flexDirection: "row",
  },
}));

const BottomTable = ({ ticketData }: { ticketData: Array<TicketDataSchema> }) => {
  const [tab, setTab] = React.useState(0);

  const handleChange = (_: object, newValue: number) => {
    setTab(newValue);
  };

  const isFetchingHistory = useIsFetching(["auditSearch"]) > 0;

  const classes = useStyles();

  return (
    <Box>
      <Box display="flex" marginTop={8}>
        <Paper className={classes.paper}>
          <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={handleChange} aria-label="tabs">
            <Tab aria-label="ticketTab" label={strings.tickets} className={tab === 0 ? classes.selected : ""} />
            <Tab aria-label="historyTab" className={tab === 1 ? classes.selected : ""} label={strings.history} />
          </Tabs>
          {isFetchingHistory && (
            <Box data-testid="loading" className={classes.loader}>
              <CircularProgress size={20} />
            </Box>
          )}
        </Paper>
      </Box>
      {tab === 0 ? (
        <TicketTable data={ticketData} />
      ) : (
        <Box className={classes.history}>
          <HistoryTable />
        </Box>
      )}
    </Box>
  );
};

BottomTable.propTypes = {
  ticketData: PropTypes.arrayOf(types.ticketDataShape).isRequired,
};

export default BottomTable;
