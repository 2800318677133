import { Box, CircularProgress, Typography, useTheme } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { useHistory } from "react-router";
import { BackButton } from "../Common";
import PropTypes from "prop-types";
import { StateSchema } from "../../interfaces/case.interface";

interface BreadCrumbProps {
  title: string;
  path: string;
  isLoading: boolean;
}

export const BreadCrumb = ({ title, path, isLoading }: BreadCrumbProps) => {
  const theme = useTheme();
  const history = useHistory();
  const handleGoBack = () => {
    if ((history.location.state as StateSchema)?.discrepancy) {
      history.push(`/stock`, { discrepancy: true });
    } else if (history.location.pathname.includes("/tickets") && (history.location.state as StateSchema)?.status) {
      history.push(`/tickets`, { status: (history.location.state as StateSchema)?.status });
    } else {
      history.goBack();
    }
  };

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box display="flex" alignItems="center" marginRight={7}>
        <BackButton goBack={handleGoBack} />
      </Box>
      <Typography variant="h4">{path}</Typography>
      <Box display="flex" alignItems="center" marginRight={7} marginLeft={7} color={theme.palette.text.secondary}>
        <ChevronRight fontSize="small" />
      </Box>
      <Typography variant="body2">{isLoading ? "" : title}</Typography>

      {isLoading && (
        <>
          <Box display="flex" justifyContent="flex-end" flex="1">
            <Box data-testid="loading">
              <CircularProgress size={20} />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

BreadCrumb.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default BreadCrumb;
