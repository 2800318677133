import { Box, makeStyles, TableRow } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import PropTypes from "prop-types";
import { Cell, CheckBox, ClickableIcon } from "../Common/";
import { types } from "common";
import { useHistory } from "react-router";
import { cells } from "./cells";
import { FormattedCell } from "../Table";
import { TicketDataSchema } from "../../interfaces/ticket.interface";

const useStyles = makeStyles((theme) => ({
  collapsableCell: {
    paddingBottom: 0,
    paddingTop: 0,
    backgroundColor: theme.palette.grey.A100,
  },
  collapseIcon: {
    paddingLeft: 8,
    color: theme.palette.text.secondary,
  },
  visibilityIcon: {
    marginLeft: theme.spacing(18),
    display: "flex",
    justifyContent: "flex-start",
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}));

interface DataRowProps {
  data: TicketDataSchema;
  selectRow: () => void;
  selected: boolean;
  statusFilter?: string;
}

const DataRow = ({ data, selectRow, selected, statusFilter }: DataRowProps) => {
  const classes = useStyles();
  const history = useHistory();

  const goToDetail = () => {
    history.push(`/tickets/${data.id}`, { status: statusFilter });
  };

  const renderCells = () =>
    cells.map(({ key }) => {
      const value = data[key as keyof TicketDataSchema] as string;

      return <FormattedCell key={key} label={key} value={value} disabled={false} />;
    });

  return (
    <>
      <TableRow data-testid="datarow">
        <Cell>
          <CheckBox
            color="primary"
            onChange={selectRow}
            checked={selected}
            inputProps={{ "aria-label": "rowCheckBox" }}
          />
        </Cell>

        {renderCells()}

        <Cell>
          <Box className={classes.visibilityIcon}>
            <ClickableIcon data-testid="rowDetail" onClick={goToDetail}>
              <Visibility fontSize="small" color="primary" />
            </ClickableIcon>
          </Box>
        </Cell>
      </TableRow>
    </>
  );
};

DataRow.propTypes = {
  data: types.ticketDataShape,
  selectRow: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

DataRow.defaultProps = {
  selected: false,
};

export default DataRow;
