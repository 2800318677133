import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ReactQueryDevtools } from "react-query/devtools";
import AppProviders from "./providers";
import { QueryClient, QueryClientProvider } from "react-query";
import OneSignalReact from "react-onesignal";

OneSignalReact.init({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  appId: window.env.REACT_APP_ONESIGNAL_APP_ID,
  notifyButton: {
    enable: true,
  },
  allowLocalhostAsSecureOrigin: true,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      refetchOnWindowFocus: false,
      retry(failureCount, error: any) {
        if (error.status === 404) return false;
        else if (failureCount < 2) return true;
        else return false;
      },
    },
    mutations: {
      // mutation options
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <AppProviders>
      <App />
    </AppProviders>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
