import { useState } from "react";
import {
  Checkbox,
  Input,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { boolToString, boolToStringForRelation, filtersToParams } from "../../constants";
import { Cell, LightGreyRow, SecondaryButton, SmallCell } from "../Common";
import { strings } from "common";
import { TableCellSchema, TableSearchSchema } from "../../interfaces/table.interface";

const useStyles = makeStyles((theme) => ({
  filterTable: {
    background: theme.palette.grey.A200,
  },
  icon: {
    color: theme.palette.text.secondary,
  },
  input: {
    marginBottom: theme.spacing(2),
  },
  selectKeyText: {
    paddingRight: theme.spacing(4),
  },
  clearButton: {
    paddingTop: 0,
    paddingBottom: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
}));

interface InputRowProps {
  setFieldD: (key: string, val: string) => void;
  setField: (key: string, val: string) => void;
  cells: Array<TableCellSchema>;
  firstColspan: number;
  searchFields: TableSearchSchema;
  clearFields: () => void;
  dense: boolean;
}

const InputRow = ({ setField, cells, firstColspan = 1, searchFields, clearFields, dense }: InputRowProps) => {
  const classes = useStyles();
  // since we are using a debounced onchange and
  // we are not using controlled inputs here
  // we use a clearkey to force a re-render and clean the inputs
  const [clearKey, setClearKey] = useState<boolean>(false);

  const CellToRender = dense ? SmallCell : Cell;

  return (
    <LightGreyRow>
      {[...Array(firstColspan).keys()].map((index) => (
        <CellToRender key={index} />
      ))}

      {cells.map(({ key, text, disableInput, selectOptions, datePicker, type }) => (
        <CellToRender key={text}>
          {!disableInput && !selectOptions && !datePicker && (
            <Input
              type={type || "text"}
              onChange={(e) => setField(key, e.target.value)}
              className={classes.input}
              fullWidth
              value={searchFields[key]}
            />
          )}

          {datePicker && (
            <TextField
              type="date"
              onChange={(e) => setField(key, e.target.value)}
              className={classes.input}
              fullWidth
              value={searchFields[key]}
            />
          )}

          {selectOptions && (
            <Select
              multiple
              value={searchFields[key as keyof typeof searchFields] || []}
              onChange={(e) => setField(key, e.target.value as string)}
              renderValue={(val: unknown) => {
                const selected = val as string[];
                if (selected.length === 0) {
                  return "";
                }

                if (selected.some((val) => val === "true" || val === "false")) {
                  return key === "linkedToPhysical"
                    ? selected
                        .map((val) => boolToStringForRelation[val as keyof typeof boolToStringForRelation])
                        .join(", ")
                    : selected.map((val) => boolToString[val as keyof typeof boolToString]).join(", ");
                }

                const mappedSelected = selected.map((value) => filtersToParams[value as keyof typeof filtersToParams]);

                return mappedSelected.join(", ");
              }}
              fullWidth
              inputProps={{ "aria-label": `selectInput${key}` }}
            >
              {selectOptions.map((option) => (
                <MenuItem key={option.key} value={option.value.toString()}>
                  <ListItemIcon>
                    <Checkbox
                      color="primary"
                      checked={searchFields[key]?.toString().includes(option.value.toString())}
                    />
                  </ListItemIcon>
                  <ListItemText className={classes.selectKeyText} primary={option.key} />
                </MenuItem>
              ))}
            </Select>
          )}
        </CellToRender>
      ))}
      <CellToRender>
        <SecondaryButton
          className={classes.clearButton}
          onClick={() => {
            setClearKey(!clearKey);
            clearFields();
          }}
        >
          {strings.clear.toUpperCase()}
        </SecondaryButton>
      </CellToRender>
    </LightGreyRow>
  );
};

InputRow.propTypes = {
  cells: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  firstColspan: PropTypes.number,
  lastColSpan: PropTypes.number,
  setFieldD: PropTypes.func.isRequired,
  setField: PropTypes.func.isRequired,
  searchFields: PropTypes.shape({}).isRequired,
  clearFields: PropTypes.func,
  dense: PropTypes.bool,
};

export default InputRow;
