import { DateTime } from 'luxon';

// avoid cases like 2009 being a valid date
export const isValidDate = (value) => DateTime.fromISO(value).isValid && !Number(value);

const units = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};

const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

export const getRelativeTime = (d1, d2 = new Date()) => {
  const elapsed = d1 - d2;

  for (const u in units) if (Math.abs(elapsed) > units[u] || u == 'second') return rtf.format(Math.round(elapsed / units[u]), u);
};
