import { strings } from "common";
import { TableCellSchema } from "../../interfaces/table.interface";

export const cells: TableCellSchema[] = [
  {
    width: "7.5%",
    text: strings.deal_number,
    key: "dealNumber",
  },
  {
    width: "12.5%",
    text: strings.accountHolderName,
    key: "accountHolderName",
  },
  {
    width: "12.5%",
    text: strings.client,
    key: "clientName",
  },
  {
    width: "7.5%",
    text: strings.deal_transaction_type,
    key: "type",
  },
  {
    width: "12.5%",
    text: strings.delivery_location,
    key: "deliveryCountry",
  },
  {
    width: "10%",
    text: strings.paid_date,
    key: "paidDate",
    datePicker: true,
  },
  {
    width: "10%",
    text: strings.pm,
    key: "portfolioManagerName",
  },
  {
    width: "10%",
    text: strings.status,
    key: "status",
    disableInput: true,
  },
  {
    width: "24%",
    text: strings.comment,
    key: "note",
  },
];

export const emptyCells = {
  firstCell: {
    width: "1.6%",
  },
  lastCell: {
    width: "10%",
  },
};
