import React, { useEffect } from "react";
import { Switch, Route, useLocation, RouteComponentProps, Redirect } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import OneSignal from "react-onesignal";
import Layout from "./components/Layout/Layout";
import {
  Stock,
  Orders,
  Tickets,
  StockDetail,
  TicketsDetail,
  // Dashboard,
  // Deals,
  ErrorFallBack,
  // DealsDetail,
  Users,
  UsersDetail,
  SalesOrderDetail,
} from "./screens";
import { getCurrentRoute } from "./utils/routes";
import { useMyUser } from "./utils/users";
import RefreshToken from "./components/Common/RefreshToken";
// import ShipMultipleDeals from "./screens/ShipMultipleDeals";
// import Assignments from "./screens/Assignments";
import { StaticContext } from "react-router";

export default function AuthenticatedApp() {
  const [selectedOption, setOption] = React.useState(getCurrentRoute());

  const { data } = useMyUser();

  const location = useLocation();

  useEffect(() => {
    if (getCurrentRoute() !== selectedOption) {
      setOption(getCurrentRoute());
    }
  }, [location, selectedOption]);

  useEffect(() => {
    if (data && data.id) {
      OneSignal.setExternalUserId(`${data.id}`);
      OneSignal.registerForPushNotifications();
    }
  }, [data?.id]);

  return (
    <ErrorBoundary resetKeys={[selectedOption]} FallbackComponent={ErrorFallBack}>
      <Layout selectedOption={selectedOption} setOption={setOption}>
        <RefreshToken />
        <Switch>
          <Redirect exact from="/" to="/stock" />

          <Route
            path="/stock"
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`} component={Stock} exact />
                <Route path={`${url}/:id`} component={StockDetail} />
              </>
            )}
          />

          <Route
            path="/orders"
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`} component={Orders} exact />
                <Route path={`${url}/:id`} component={SalesOrderDetail} />
              </>
            )}
          />

          <Route
            path="/tickets"
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`} component={Tickets} exact />
                <Route path={`${url}/:id`} component={TicketsDetail} />
              </>
            )}
          />

          {/* <Route
            path="/deals"
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`} component={Deals} exact />
                <Route path={`${url}/:id`} component={DealsDetail} exact />
                <Route path={`${url}/:ids/ship-multiple`} component={ShipMultipleDeals} />
              </>
            )}
          /> */}

          <Route
            path="/users"
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`} component={Users} exact />
                <Route
                  path={`${url}/create`}
                  render={(props: RouteComponentProps<object, StaticContext, unknown>) => (
                    <UsersDetail {...props} createUser />
                  )}
                  exact
                />
                <Route path={`${url}/details/:id`} component={UsersDetail} exact />
              </>
            )}
          />

          {/* <Route
            path="/assignments"
            render={({ match: { url } }) => (
              <>
                <Route path={`${url}/`} component={Assignments} exact />
              </>
            )}
          /> */}

          {/* <Route exact path="/" render={() => <Dashboard />} /> */}
        </Switch>
      </Layout>
    </ErrorBoundary>
  );
}
