import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router } from "react-router-dom";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import AuthenticatedApp from "./AuthenticatedApp";

import { Login } from "./screens";
import { useMyUser } from "./utils/users";

const useStyles = makeStyles(() => ({
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
}));

export default function App() {
  const { data, isLoading } = useMyUser();

  const classes = useStyles();
  const renderApp = () => (data && data.id ? <AuthenticatedApp /> : <Login />);

  return (
    <Router>
      <CssBaseline>
        {isLoading && (
          <Box data-testid="loading" className={classes.spinner}>
            <CircularProgress size={30} />
          </Box>
        )}
        {!isLoading && renderApp()}
      </CssBaseline>
    </Router>
  );
}
