import { Box, makeStyles, Typography } from "@material-ui/core";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";
import { numberToNearThousand } from "../../utils/currency";

const useStyles = makeStyles((theme) => ({
  boxBackground: {
    backgroundColor: theme.palette.grey[50],
  },
  header: {
    padding: `${theme.spacing(8)}px ${theme.spacing(8)}px`,
  },
  chartBox: {
    height: 311,
    padding: theme.spacing(8),
  },
}));

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom" as const,
      align: "start" as const,
    },
  },
  barThickness: 60,
  scales: {
    y: {
      stacked: true,
      categoryPercentage: 0.8,
      barPercentage: 1,
      categorySpacing: 0,
      ticks: {
        beginAtZero: true,
        callback: function (value: number | string) {
          return numberToNearThousand(Number(value));
        },
      },
    },
    x: {
      stacked: true,
      categoryPercentage: 0.1,
      barPercentage: 1,
      categorySpacing: 0,
      barThickness: 0,
      grid: {
        display: false,
      },
    },
  },
};

interface DataSetSchema {
  backgroundColor: string;
  data: Array<string>;
  label: string;
  stack: string;
}

interface StackedBarProps {
  title: string;
  labels: Array<string>;
  datasets: Array<DataSetSchema>;
}

const StackedBar = ({ title, labels, datasets }: StackedBarProps) => {
  const classes = useStyles();

  const data = {
    labels,
    datasets,
  };

  return (
    <Box className={classes.boxBackground}>
      <Typography className={classes.header} variant="h6">
        {title}
      </Typography>
      <Box className={classes.chartBox}>
        <Bar data={data} options={options} />
      </Box>
    </Box>
  );
};

StackedBar.propTypes = {
  title: PropTypes.string.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  datasets: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.string),
      label: PropTypes.string,
      backgroundColor: PropTypes.string,
      stack: PropTypes.string,
    })
  ).isRequired,
};

export default StackedBar;
