import { Box, makeStyles, Modal, Typography } from "@material-ui/core";
import { strings } from "common";
import React from "react";
import PropTypes from "prop-types";
import logo from "../../assets/Logo.svg";
import successIcon from "../../assets/assignment_success.svg";

import { MainButton } from "../Common";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    width: "400px",
    background: "white",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
    padding: theme.spacing(12, 12, 12, 12),
  },
  center: {
    width: "100%",
    paddingBottom: theme.spacing(12),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.8em",
  },
  button: {
    width: "90%",
  },
  labels: {
    marginTop: theme.spacing(12),
  },
  errorMsg: {
    color: "#FF0000",
  },
}));

interface ShipSuccessModalProps {
  isOpen: boolean;
  handleEvent: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

const ShipSuccessModal = ({ isOpen, handleEvent }: ShipSuccessModalProps) => {
  const classes = useStyles();

  return (
    <Modal
      open={isOpen}
      className={classes.modal}
      data-testid="deal-ship-success-modal"
      aria-labelledby="deal ship success"
      aria-describedby="deal ship success modal"
    >
      <Box className={classes.modalContent}>
        <Box className={classes.center}>
          <img src={logo} alt="logo" />
        </Box>
        <div className={`${classes.center}`}>
          <Typography className={classes.title}>{strings.assignment_success_title}</Typography>
        </div>
        <div className={classes.center}>
          <img src={successIcon} alt="errorIcon" width="50" height="50" />
        </div>

        <div className={classes.center}>
          <Typography variant="h4">{strings.ship_success_msg}</Typography>
        </div>

        <div className={classes.center}>
          <MainButton className={classes.button} id="deal_ship_success_next" onClick={handleEvent}>
            {strings.ship_success_btn}
          </MainButton>
        </div>
      </Box>
    </Modal>
  );
};

ShipSuccessModal.propTypes = {
  isOpen: PropTypes.bool,
  handleEvent: PropTypes.func,
};

export default ShipSuccessModal;
