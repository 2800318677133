import React from "react";
import { getPages } from "../utils/pagination";
import { PaginationSchema } from "../interfaces/table.interface";

const usePagination = (count = 1) => {
  const [pageSize, setpageSize] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const numPages = getPages(pageSize, count);

  const previousPage = () => {
    setPage(page - 1 >= 0 ? page - 1 : 0);
  };

  const nextPage = () => {
    setPage(page + 1 < numPages ? page + 1 : page);
  };

  const onPageChangeSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setpageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const goToLastPage = () => {
    setPage(numPages - 1);
  };
  const goToFirstPage = () => {
    setPage(0);
  };

  const onPageChange = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const result: PaginationSchema = {
    page,
    onPageChange,
    nextPage,
    previousPage,
    goToLastPage,
    goToFirstPage,
    pageSize,
    onPageChangeSize,
  };

  return result;
};

export default usePagination;
