import { QueryClient, useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { useClient } from "../providers/auth-context";
import { paramsToQueryString } from "./api-client";
import { nextPageOptions } from "./pagination";
import {
  NotificationDataSchema,
  NotificationResponseSchema,
  QueryDataUpdaterSchema,
} from "../interfaces/notification.interface";

function useNotificationsSearch() {
  const client = useClient<NotificationResponseSchema>();

  const notificationsSearch = ({ pageParam = 0 }) =>
    client(`users/notifications?${paramsToQueryString({ toShow: true, page: pageParam })}`, {}).then((data) => data);

  const result = useInfiniteQuery("notificationsSearch", notificationsSearch, {
    ...nextPageOptions,
  });

  const mergedData = result.data?.pages?.reduce((accum: NotificationDataSchema[], page: NotificationResponseSchema) => {
    return [...accum, ...page.data];
  }, []);

  const data = {
    count: result.data?.pages?.[0]?.count || 0,
    data: mergedData || [],
  };

  return { data };
}

const onUpdateMutation = (queryClient: QueryClient) => (newItem: number) => {
  const queryKey = "notificationsSearch";

  const previousItems = queryClient.getQueryData(queryKey);

  queryClient.setQueryData(queryKey, (old: any) => {
    const pages = old.pages.reduce((accum: NotificationDataSchema[], page: NotificationResponseSchema) => {
      const newPageData = page.data.map((item: NotificationDataSchema) => {
        if (newItem) {
          return item.id === newItem ? { ...item, acknowledged: true } : item;
        }
        return { ...item, acknowledged: true };
      });

      return [...accum, { ...page, data: newPageData }];
    }, []);

    const result: QueryDataUpdaterSchema = {
      ...old,
      pages,
    };
    return result;
  });

  return () => queryClient.setQueryData(queryKey, previousItems);
};

function useNotificationsAck() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    (id) =>
      client(`users/notifications/${id}/acknowledge`, {
        method: "POST",
      }),
    {
      onMutate: onUpdateMutation(queryClient),
    }
  );
}

function useNotificationsAckAll() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    () =>
      client(`users/notifications/acknowledge`, {
        method: "POST",
      }),
    {
      onMutate: onUpdateMutation(queryClient),
    }
  );
}

export { useNotificationsSearch, useNotificationsAck, useNotificationsAckAll };
