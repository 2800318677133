import { Box, Typography } from "@material-ui/core";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { strings, types } from "common";
import PropTypes from "prop-types";
import { ChangeEvent } from "react";
import { Cell } from "../Common";
import { CaseDataSchema } from "../../interfaces/case.interface";

interface CasesDataRowProps {
  data: CaseDataSchema;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error: boolean;
}

const CasesDataRow = ({ data, handleChange, error }: CasesDataRowProps) => {
  return (
    <>
      <Cell>
        <Box display="flex">
          <Typography variant="body1">{data.lwin}</Typography>
        </Box>
      </Cell>

      <Cell>
        <Box style={{ width: 220 }} display="flex">
          <Typography noWrap variant="body1">
            {data.wineName}
          </Typography>
        </Box>
      </Cell>

      <Cell>
        <Box display="flex">
          <Typography variant="body1">{data.vintage}</Typography>
        </Box>
      </Cell>

      <Cell>
        <Box display="flex">
          <Typography variant="body1">{data.unitSize}</Typography>
        </Box>
      </Cell>

      <Cell>
        <Box display="flex">
          <CurrencyTextField
            name="salePrice"
            error={Boolean(error)}
            helperText={error && strings.invalid_price}
            variant="standard"
            value={data.clientPurchasePrice || data.cwPurchasePrice}
            currencySymbol="£"
            inputProps={{ "aria-label": "salePrice" }}
            fullWidth
            decimalPlaces="2"
            minimumValue="0"
            decimalCharacter="."
            digitGroupSeparator=","
            onChange={handleChange}
          />
        </Box>
      </Cell>
    </>
  );
};

CasesDataRow.propTypes = {
  data: types.stockDataShape,
  handleChange: PropTypes.func,
  error: PropTypes.string,
};

export default CasesDataRow;
