import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { strings, types, utils } from "common";
import { dateFormat, issueType } from "common/constants";
import { DateTime } from "luxon";
import { useHistory } from "react-router";
import { LabelValue, MainButton } from "../Common";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.grey.A100,
    padding: theme.spacing(4, 4, 4, 4),
    width: 400,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  button: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    width: 100,
    maxHeight: 24,
    marginLeft: 10,
  },
  description: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flex: 1,
  },
}));

const TicketItem = ({ data }: { data: any }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleNavigation = () => {
    history.push(`/tickets/${data.issueId}`);
  };

  return (
    <Box className={classes.card}>
      <Box className={classes.header}>
        <Box className={classes.description}>
          {data.description && (
            <Typography variant="body2">{`${data.description.slice(0, 35)} ${
              data.description.length > 35 ? "..." : ""
            }`}</Typography>
          )}
        </Box>

        <MainButton className={classes.button} onClick={handleNavigation}>
          {strings.go_ticket}
        </MainButton>
      </Box>

      <Box>
        <Grid container>
          <Grid item xs={6}>
            <LabelValue direction="row" label={strings.type} value={issueType[data.type as keyof typeof issueType]} />
            <LabelValue
              direction="row"
              label={strings.author}
              value={utils.formatters.getFirstLetter(data.authorName).toUpperCase()}
            />
          </Grid>
          <Grid item xs={6}>
            <LabelValue label={strings.rotation} value={data.rotationNumber} direction="row" />
            <LabelValue
              direction="row"
              label={strings.creation_date}
              value={
                utils.date.isValidDate(data.createdAt) ? DateTime.fromISO(data.createdAt).toFormat(dateFormat) : ""
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

TicketItem.propTypes = {
  data: types.ticketNotification,
};

export default TicketItem;
