import { MouseEventHandler } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Paper,
  TableCell,
  TableRow,
  Typography,
  withStyles,
  IconButton,
  Grid,
  Checkbox,
  TableHead,
  makeStyles,
  GridSpacing,
  GridDirection,
} from "@material-ui/core";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import { ArrowBack } from "@material-ui/icons";

export const PaperFilterRow = withStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: theme.palette.grey.A200,
    boxShadow: "none",
  },
}))(Paper);

export const Filter = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(9),
    display: "flex",
    alignItems: "center",
  },
}))(Box);

export const FilterLabel = withStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(5),
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    variant: "body1",
  },
}))(Typography);

export const Cell = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    whiteSpace: "nowrap",
  },
}))(TableCell);

export const SmallCell = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    whiteSpace: "nowrap",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))(TableCell);

export const AssignmentCell = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    whiteSpace: "normal",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))(TableCell);

// row background not working??
export const GreyCell = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey.A100,
  },
}))(Cell);

export const SmallGreyCell = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey.A100,
  },
}))(SmallCell);

export const AssignmentGreyCell = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey.A100,
    height: 50,
  },
}))(AssignmentCell);

export const GreyRow = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey.A100,
  },
}))(TableRow);

export const DarkGreyRow = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[200],
  },
}))(TableRow);

export const LightGreyRow = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[50],
  },
}))(TableRow);

export const BorderlessTableRow = withStyles(() => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
}))(TableRow);

export const StickyTableHead = withStyles((theme) => ({
  root: {
    "& > * > *": {
      backgroundColor: theme.palette.grey.A100,
    },
  },
}))(TableHead);

export const MainButton = withStyles((theme) => ({
  root: {
    "color": theme.palette.common.white,
    "paddingTop": theme.spacing(2),
    "paddingBottom": theme.spacing(2),
    "borderRadius": 0,
    "backgroundColor": theme.palette.primary.main,
    "paddingLeft": theme.spacing(6),
    "paddingRight": theme.spacing(6),
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
    },
  },
  disabled: {
    backgroundColor: theme.palette.action.disabledBackground,
  },
}))(Button);

export const SecondaryButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
  },
}))(MainButton);

export const CancelButton = withStyles((theme) => ({
  root: {
    "backgroundColor": theme.palette.common.white,
    "color": theme.palette.error.main,
    "&:hover": {
      background: theme.palette.grey[200],
    },
  },
}))(MainButton);

export const CancelFilledButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
}))(MainButton);

export const IndeterminateCheckBox = withStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    fontSize: "1.5rem",
  },
}))(IndeterminateCheckBoxIcon);

export const CheckBox = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1, 1, 1, 1),
  },
}))(Checkbox);

export const ClickableIcon = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 2, 2, 2),
  },
}))(IconButton);

// move to another place

export const NavButton = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    backgroundColor: theme.palette.grey.A200,
    borderRadius: "1px",
  },
}))(Button);

export const BackButton = ({ goBack }: { goBack: MouseEventHandler }) => (
  <NavButton onClick={goBack} size="large" startIcon={<ArrowBack />}>
    <Typography variant="button">{"back"}</Typography>
  </NavButton>
);

BackButton.propTypes = {
  goBack: PropTypes.func.isRequired,
};

export const Label = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
  },
}))(Typography);

const useStyles = makeStyles(() => ({
  label: {
    overflowWrap: "anywhere",
  },
  item: {
    width: "200px",
  },
}));

interface LabelValueProps {
  label: string;
  value: string | number | null | undefined;
  direction: GridDirection | undefined;
  spacing?: GridSpacing | undefined;
  isTransaction?: boolean;
}

//TODO: move this to own component
export const LabelValue = ({ label, value, direction, spacing = 2, isTransaction }: LabelValueProps) => {
  const classes = useStyles();

  return (
    <Grid container direction={direction} spacing={spacing} alignItems={direction === "row" ? "center" : "flex-start"}>
      <Grid item className={isTransaction ? classes.item : ""}>
        <Typography color="primary" variant="caption">
          {label}
        </Typography>
      </Grid>

      <Grid item className={classes.label}>
        <Typography>{value}</Typography>
      </Grid>
    </Grid>
  );
};

LabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  direction: PropTypes.oneOf(["column", "row"]),
  spacing: PropTypes.number,
  isTransaction: PropTypes.bool,
};

LabelValue.defaultProps = {
  direction: "column",
};
