import { Grid, MenuItem, Select, Typography } from "@material-ui/core";
import { DateTime } from "luxon";
import React from "react";
import { dateFormat, issueStatusList, issueType } from "../../constants";
import { strings, types } from "common";
import { useUpdateStatus } from "../../utils/issuesDetail";
import { LabelValue } from "../Common";
import SnackAlert from "../Common/SnackBar";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import { IssueDetailDataSchema } from "../../interfaces/ticket.interface";

const TicketInformation = ({ data }: { data: IssueDetailDataSchema }) => {
  const { mutate, isSuccess } = useUpdateStatus();

  const onChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    mutate({ issueId: data.id.toString(), status: typeof e.target.value === "string" ? e.target.value : "" });
  };

  return (
    <>
      {isSuccess && <SnackAlert message={strings.ticket_status_changed} />}

      <Typography variant="h5">{strings.information}</Typography>

      <Grid container>
        <Grid xs={6} item container direction={"column"} spacing={10}>
          <Grid item>
            <LabelValue label={strings.type} value={issueType[data.type as keyof typeof issueType]} />
          </Grid>

          <Grid item>
            <Typography color="primary" variant="caption">
              {strings.status}
            </Typography>
            <Select
              fullWidth
              value={issueStatusList[data.status as keyof typeof issueStatusList]}
              onChange={onChange}
              inputProps={{ "aria-label": `selectStatus` }}
            >
              <MenuItem value={issueStatusList.OPEN}>{strings.open}</MenuItem>
              <MenuItem value={issueStatusList.CLOSED}>{strings.closed}</MenuItem>
              <MenuItem value={issueStatusList.PENDING}>{strings.pending}</MenuItem>
            </Select>
          </Grid>

          <Grid item>
            <LabelValue label={strings.author} value={data.authorName} />
          </Grid>

          <Grid item>
            <LabelValue
              label={strings.creation_date}
              value={DateTime.fromISO(data.createdAt as string).toFormat(dateFormat)}
            />
          </Grid>

          <Grid item>
            <LabelValue
              label={strings.updated_date}
              value={DateTime.fromISO(data.updatedAt as string).toFormat(dateFormat)}
            />
          </Grid>
        </Grid>
        <Grid xs={6} item>
          {data?.issueImages?.length > 0 && (
            <ImageGallery items={data.issueImages as ReactImageGalleryItem[]} showPlayButton={false} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

TicketInformation.propTypes = {
  data: types.ticketDataShape,
};

export default TicketInformation;
