import { actionTypes, actionTypesList, entityTypes, entityTypesList } from "../../constants";
import { strings } from "common";
import { TableCellSchema } from "../../interfaces/table.interface";

export const cells: TableCellSchema[] = [
  {
    width: "10%",
    text: strings.entity,
    key: "entity",
    selectOptions: Object.keys(entityTypesList).map((entity) => ({
      key: entityTypes[entity] ?? "Case",
      value: entityTypesList[entity as keyof typeof entityTypesList],
    })),
  },
  {
    width: "10%",
    text: strings.action,
    key: "action",
    selectOptions: Object.keys(actionTypesList).map((entity) => ({
      key: actionTypes[entity] ?? "Created",
      value: actionTypesList[entity as keyof typeof actionTypesList],
    })),
  },
  {
    width: "20%",
    text: strings.change,
    key: "change",
    disableInput: true,
    disableSort: true,
  },
  {
    width: "30%",
    text: strings.content,
    key: "content",
    disableInput: true,
    disableSort: true,
  },
  {
    width: "10%",
    text: strings.by,
    key: "userName",
  },
  {
    width: "10%",
    text: strings.timestamp,
    key: "createdAt",
  },
];

export const emptyCells = {
  expandableCell: {
    width: "2.5%",
  },
  lastCell: {
    width: "3%",
  },
};
