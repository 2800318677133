import { useQuery } from "react-query";
import { useClient } from "../providers/auth-context";
import { strings } from "common";
import { paramsToQueryString } from "./api-client";
import { actionTypesList, entityTypesList, filtersToParams } from "../constants";
import { AuditDataSchema } from "../interfaces/audit.interface";
import { AuditResponseData, TableSearchSchema } from "../interfaces/table.interface";
import { ClientType } from "../interfaces/apiClient.interface";

const fieldToStrings = {
  initialPurchasePricePence: strings.purchase_price,
  stockId: strings.stock_id,
  supplierName: strings.supplier,
  purchasePrice: strings.purchase_price,
  status: strings.status,
  lastTransPricePence: strings.last_trans_price,
  acknowledgeAllNotifications: strings.acknowledgeAllNotifications,
  photoUrl: strings.photo_url,
};

interface HistoryRowSchema extends AuditDataSchema {
  change: string;
  content: string;
  grouped: object | null;
}

const fieldsToShowOnContent = ["stockId", "supplierName", "lastTransPricePence", "status"];

function mapHistoryRows(data: Array<AuditDataSchema>) {
  return data.reduce((accum: Array<HistoryRowSchema>, row) => {
    let contentChanged = {
      change: "",
      content: "",
    };
    let grouped = null;
    const numDataKeys = Object.keys(row.data).length;

    // create actions should have no contentChanged
    if (row.action === actionTypesList.CREATE || numDataKeys === 0) {
      if (row.entity === entityTypesList.ISSUE_COMMENTS) {
        contentChanged.change = strings.new_comment;
      }

      accum.push({
        ...row,
        grouped,
        ...contentChanged,
      });

      return accum;
    }

    // should group into new rows
    if (numDataKeys > 1) {
      const changedOverview = Object.keys(row.data)
        .filter((change) => fieldsToShowOnContent.includes(change))
        .map(
          (change) =>
            `${fieldToStrings[change as keyof typeof fieldToStrings]}: ${
              filtersToParams[row.data[change as keyof typeof row.data] as keyof typeof filtersToParams] ||
              row.data[change as keyof typeof row.data]
            }`
        )
        .join("\xa0 \xa0 \xa0");

      contentChanged = {
        change: strings.multiple_changes,
        content: changedOverview,
      };

      grouped = Object.keys(row.data).map((change) => ({
        change: fieldToStrings[change as keyof typeof fieldToStrings] || change,
        content:
          filtersToParams[row.data[change as keyof typeof row.data] as keyof typeof filtersToParams] ||
          row.data[change as keyof typeof row.data],
      }));
    }

    if (numDataKeys === 1) {
      const change = Object.keys(row.data)[0];
      if (change) {
        contentChanged = {
          change: fieldToStrings[change as keyof typeof fieldToStrings] || change,
          content:
            filtersToParams[row.data[change as keyof typeof row.data] as keyof typeof filtersToParams] ||
            row.data[change as keyof typeof row.data],
        };
      }
    }

    accum.push({
      ...row,
      grouped,
      ...contentChanged,
    });

    return accum;
  }, []);
}

const getAuditSearchConfig = (client: ClientType<AuditResponseData>, params: TableSearchSchema) => ({
  queryKey: ["auditSearch", { params }],
  queryFn: () => client(`audit?${paramsToQueryString(params)}`).then((data) => data),
});

function useAuditSearch(params: TableSearchSchema) {
  const client = useClient<AuditResponseData>();
  const result = useQuery(getAuditSearchConfig(client, params));

  const data = {
    ...result.data,
    data: mapHistoryRows(result?.data?.data || []),
  };

  return { data };
}

export { useAuditSearch };
