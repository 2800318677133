import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import theme from "./theme";
import { RecoilRoot } from "recoil";
import { reactPlugin } from "../telemetry/Appinsights";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";

const AppProviders = ({ children }: { children: JSX.Element }) => (
  <AppInsightsContext.Provider value={reactPlugin}>
    <ThemeProvider theme={theme}>
      <RecoilRoot>{children}</RecoilRoot>
    </ThemeProvider>
  </AppInsightsContext.Provider>
);

AppProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProviders;
