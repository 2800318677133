import { strings } from "common";
import { roles, rolesList } from "../../constants";
import { TableCellSchema } from "../../interfaces/table.interface";

export const cells: TableCellSchema[] = [
  {
    width: "20%",
    text: strings.name,
    key: "name",
  },
  {
    width: "20%",
    text: strings.email,
    key: "email",
  },
  {
    width: "20%",
    text: strings.role,
    key: "role",
    selectOptions: [
      { key: roles.WAREHOUSING, value: rolesList.WAREHOUSING },
      { key: roles.ADMIN_WAREHOUSING, value: rolesList.ADMIN_WAREHOUSING },
      { key: roles.OPERATIONS, value: rolesList.OPERATIONS },
    ],
  },
  {
    width: "20%",
    text: strings.warehouses,
    key: "warehouseNames",
  },
  {
    width: "20%",
    text: strings.admin,
    key: "admin",
    selectOptions: [
      { key: strings.yes, value: true },
      { key: strings.no, value: false },
    ],
  },
];

export const emptyCells = {
  lastCell: {
    width: "10%",
  },
};
