import { createTheme } from "@material-ui/core/styles";

const defaultTheme = createTheme({
  spacing: 2, // check this
  palette: {
    type: "light",
    common: {
      black: "#000A1E",
      white: "#ffffff",
    },
    primary: {
      light: "#B1DED8",
      main: "#1D4854",
      dark: "#143741",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#B5981B",
      main: "#FF906D",
      dark: "#DD7352",
      contrastText: "#ffffff",
    },
    error: {
      main: "#F44336",
    },
    warning: {
      main: "#FF9800",
      dark: "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #FF9800;",
      light: "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FF9800",
    },
    info: {
      main: "#2196F3",
      dark: "#0B79D0",
      light: "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #2196F3;",
      contrastText: "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #2196F3;",
    },
    success: {
      main: "#4CAF50",
    },
    action: {
      disabled: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "rgba(0, 0, 0, 0.12)",
    },
    grey: {
      50: "#FAFAFA",
      100: "#F5F5F5",
      200: "#EEEEEE",
      300: "#E0E0E0",
      400: "#BDBDBD",
      500: "#9E9E9E",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
      A100: "#EFF1F2",
      A200: "#E1E4E7",
      A700: "#73838D",
    },
    text: {
      primary: "#1C1C20",
      secondary: "rgba(28, 28, 32, 0.54)",
      disabled: "rgba(28, 28, 32, 0.38);",
      hint: "rgba(28, 28, 32, 0.38)",
    },
    background: {
      default: "#ffffff",
      paper: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Source Sans Pro, Arial, sans-serid",
    h1: {
      fontWeight: "bold",
      fontSize: "2rem",
      lineHeight: "1.25",
    },
    h2: {
      fontWeight: "bold",
      fontSize: "1.75rem",
      lineHeight: "1.25",
    },
    h3: {
      fontWeight: "bold",
      fontSize: "1.5rem",
      lineHeight: "1.25",
    },
    h4: {
      fontWeight: "bold",
      fontSize: "1.25rem",
      lineHeight: "1.25",
    },
    h5: {
      fontWeight: "bold",
      fontSize: "1.125rem",
      lineHeight: "1.25",
    },
    h6: {
      fontWeight: "bold",
      fontSize: "1.125rem",
      lineHeight: "1.5",
    },
    body1: {
      fontWeight: "normal",
      fontSize: "0.875rem",
      lineHeight: "1.5",
    },
    body2: {
      fontWeight: "bold",
      fontSize: "0.875rem",
      lineHeight: "1.5",
    },
    subtitle1: {
      fontWeight: "normal",
      fontSize: "1rem",
      lineHeight: "1.5",
    },
    subtitle2: {
      fontWeight: "bold",
      fontSize: "1rem",
      lineHeight: "1.5",
    },
    caption: {
      fontWeight: 700,
      fontSize: "0.75rem",
      lineHeight: "1",
      color: "#C4C4C4",
    },
    overline: {
      fontWeight: "normal",
      fontSize: "0.75rem",
      lineHeight: "1.5",
    },
    button: {
      fontWeight: "bold",
      fontSize: "0.75rem",
      lineHeight: "1.5rem",
      textTransform: "uppercase",
    },
  },
});

export default createTheme({
  ...defaultTheme,
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 0,
        boxShadow: "none",
      },
    },
  },
});
