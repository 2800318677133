import PropTypes from "prop-types";
import { Box, makeStyles, Table, TableBody, TableContainer, TableHead, Typography } from "@material-ui/core";
import { Cell, ClickableIcon, GreyRow, Label, LightGreyRow } from "../Common";
import { Visibility } from "@material-ui/icons";
import { strings, types } from "common";
import { useHistory } from "react-router";
import { datetimeFormat, filtersToParams, issueType } from "../../constants";
import { DateTime } from "luxon";
import { TicketDataSchema } from "../../interfaces/ticket.interface";

const useStyles = makeStyles((theme) => ({
  downIcon: {
    fontSize: "0.75rem",
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(5),
  },
  eyeIcon: {
    color: theme.palette.primary.main,
  },
}));

const TicketTable = ({ data }: { data: Array<TicketDataSchema> }) => {
  const classes = useStyles();
  const history = useHistory();

  const goToDetail = (id: number) => {
    history.push(`/tickets/${id}`);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <GreyRow>
            <Cell>
              <Label variant="caption">{strings.id}</Label>
            </Cell>

            <Cell>
              <Label variant="caption">{strings.type}</Label>
            </Cell>

            <Cell>
              <Label variant="caption">{strings.rotation}</Label>
            </Cell>

            <Cell>
              <Label variant="caption">{strings.status}</Label>
            </Cell>

            <Cell>
              <Label variant="caption">{strings.author}</Label>
            </Cell>

            <Cell>
              <Label variant="caption">{strings.creation_date}</Label>
            </Cell>

            <Cell>
              <Label variant="caption">{strings.update_date}</Label>
            </Cell>

            <Cell>
              <Label variant="caption">{strings.comment}</Label>
            </Cell>
            <Cell></Cell>
          </GreyRow>
        </TableHead>

        <TableBody>
          {data.map((data) => (
            <LightGreyRow key={data.id}>
              <Cell>
                <Typography>{data.id}</Typography>
              </Cell>
              <Cell>
                <Typography>{issueType[data.type as keyof typeof issueType]}</Typography>
              </Cell>
              <Cell>
                <Typography>{data.rotationNumber}</Typography>
              </Cell>
              <Cell>
                <Typography>{filtersToParams[data.status as keyof typeof filtersToParams]}</Typography>
              </Cell>
              <Cell>
                <Typography>{data.authorName}</Typography>
              </Cell>
              <Cell>
                <Typography>{DateTime.fromISO(data.createdAt as string).toFormat(datetimeFormat)}</Typography>
              </Cell>
              <Cell>
                <Typography>{DateTime.fromISO(data.createdAt as string).toFormat(datetimeFormat)}</Typography>
              </Cell>
              <Cell>
                <Typography>{data.comment}</Typography>
              </Cell>
              <Cell align="right">
                <Box display="flex">
                  <ClickableIcon onClick={() => goToDetail(data.id)}>
                    <Visibility data-testid="ticketDetail" fontSize="small" className={classes.eyeIcon} />
                  </ClickableIcon>
                </Box>
              </Cell>
            </LightGreyRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TicketTable.propTypes = {
  data: PropTypes.arrayOf(types.ticketDataShape).isRequired,
};

export default TicketTable;
