import { DataRow, ExportComponent } from "../components/Tickets";
import { strings } from "common";
import TableSkeleton from "../components/Table/Skeleton";
import { useIssuesSearch } from "../utils/issues";
import { issueType } from "../constants";
import { cells, emptyCells } from "../components/Tickets/cells";
import { useLocation } from "react-router";
import { Typography } from "@material-ui/core";
import { StateSchema } from "../interfaces/case.interface";
import React from "react";

const filterValues = [...Object.keys(issueType)];

const Tickets = () => {
  const [statusFilter, setStatusFilter] = React.useState<string>();
  const location = useLocation();

  let initQuery;

  if ((location?.state as StateSchema)?.status.length) {
    initQuery = { status: [...(location?.state as StateSchema)?.status] };
  }

  return (
    <>
      <Typography variant="h4">{strings.tickets}</Typography>

      <TableSkeleton
        name="tickets"
        query={useIssuesSearch}
        cells={cells}
        emptyCells={emptyCells}
        filterParam={"type"}
        filterValues={filterValues}
        initQuery={initQuery}
        DataRow={(props) => <DataRow {...props} statusFilter={statusFilter} />}
        firstColspan={1}
        ExportComponent={ExportComponent}
        keyExtractor={(row) => `${row.id}${row.caseId}`}
        setStatusFilter={setStatusFilter}
      ></TableSkeleton>
    </>
  );
};

export default Tickets;
