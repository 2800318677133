import { Box, CircularProgress, makeStyles, TextField } from "@material-ui/core";
import { Formik } from "formik";
import { Label, MainButton } from "../components/Common";
import logo from "../assets/Logo.svg";
import { strings } from "common";
import * as Yup from "yup";
import { useLogin, useMyUser } from "../utils/users";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    flexFlow: "column",
    background: "white",
    height: "100vh",
  },
  loginBox: {
    backgroundColor: theme.palette.grey[100],
    marginTop: theme.spacing(20),
    width: "368px",
    height: "212px",
    display: "flex",
    padding: theme.spacing(12, 12, 12, 12),
    flexDirection: "column",
  },
  label: {
    marginBottom: theme.spacing(2),
  },
  inputRow: {
    width: "100%",
    flexDirection: "column",
    display: "flex",
  },
  emailrow: {
    display: "flex",
    justifyContent: "space-between",
  },
  loginButton: {
    marginTop: theme.spacing(12),
  },
}));

const Login = () => {
  const classes = useStyles();
  const { mutateAsync: login, isLoading } = useLogin();
  const { refetch } = useMyUser({ enabled: false });

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().required(),
        password: Yup.string().required(),
      })}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        setStatus({});
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          await login(values);
          await refetch();
        } catch (error) {
          if (error instanceof Error) {
            setStatus({ error: error.message });
          }
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ errors, status, handleChange, handleSubmit, isSubmitting }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Box className={classes.container}>
            <img src={logo} alt="logo" />

            <Box className={classes.loginBox}>
              <Box className={classes.inputRow}>
                <Box className={classes.emailrow}>
                  <Label className={classes.label} variant="caption">
                    {strings.email}
                  </Label>

                  {isLoading && (
                    <Box data-testid="loading">
                      <CircularProgress size={20} />
                    </Box>
                  )}
                </Box>

                <TextField
                  error={Boolean(errors.email)}
                  helperText={errors?.email}
                  onChange={handleChange}
                  name="email"
                  fullWidth
                />
              </Box>
              <Box marginTop={12} className={classes.inputRow}>
                <Label className={classes.label} variant="caption">
                  {strings.password}
                </Label>

                <TextField
                  error={status?.error || Boolean(errors.password)}
                  helperText={status?.error || errors?.password}
                  onChange={handleChange}
                  name="password"
                  type="password"
                  fullWidth
                  inputProps={{ "aria-label": `password` }}
                />
              </Box>
              <MainButton disabled={isSubmitting} type="submit" className={classes.loginButton}>
                {strings.login}
              </MainButton>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Login;
