import React from "react";
import clsx from "clsx";
import { PreAdviceDataRow, SalesOrderDataRow, ExportButtons, SaleOrderButtons } from "../components/Orders";
import { strings } from "common";
import { cells as preadviceCells, emptyCells as preadviceEmptyCells } from "../components/Orders/preadviceCells";
import { cells as salesOrderCells, emptyCells as salesOrderEmptyCells } from "../components/Orders/salesOrderCells";
import TableSkeleton from "../components/Table/Skeleton";
import { preadviceStatuses, SalesOrderStatuses } from "../constants";
import { useLocation } from "react-router";
import { Box, CircularProgress, makeStyles, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import { useIsFetching } from "react-query";
import { usePreadvices } from "../utils/preadvices";
import { useSearchSalesorders } from "../utils/salesOrders";
import { StateSchema } from "../interfaces/case.interface";

const useStyles = makeStyles((theme) => ({
  selected: {
    background: theme.palette.grey.A100,
  },
  spinner: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: theme.spacing(8),
  },
}));

const filterValues = [...Object.keys(preadviceStatuses)];
const SOfilterValues = [...Object.keys(SalesOrderStatuses)];

const firstColspan = 2;

const Orders = () => {
  const classes = useStyles();
  const location = useLocation();

  let initFilter;

  if (filterValues.includes((location?.state as StateSchema)?.status)) {
    initFilter = (location.state as StateSchema).status;
  }
  const renderOverDueInitially = (location?.state as StateSchema)?.overdue;

  const [tab, setTab] = React.useState(renderOverDueInitially ? 1 : 0);

  const handleChange = (_: object, newValue: number) => {
    setTab(newValue);
  };

  const isFetching = useIsFetching(["casesSearch"]) > 0;

  return (
    <>
      <Typography variant="h4">{strings.orders}</Typography>

      <Box marginTop={8}>
        <Paper>
          <Tabs value={tab} indicatorColor="primary" textColor="primary" onChange={handleChange} aria-label="tabs">
            <Tab
              aria-label="preAdviceTable"
              className={clsx({ [classes.selected]: tab === 0 })}
              label={strings.pre_advice}
            />
            <Tab
              aria-label="salesOrderTable"
              className={clsx({ [classes.selected]: tab === 1 })}
              label={strings.sales_order}
            />
            {isFetching && tab === 1 && (
              <Box data-testid="loading" className={classes.spinner}>
                <CircularProgress size={20} />
              </Box>
            )}
          </Tabs>
        </Paper>
      </Box>

      <Box marginTop={-8}>
        {tab === 0 && (
          <TableSkeleton
            dense
            name="preadvices"
            query={usePreadvices}
            cells={preadviceCells}
            emptyCells={preadviceEmptyCells}
            filterParam={"status"}
            filterValues={filterValues}
            firstColspan={firstColspan}
            initFilter={initFilter}
            DataRow={PreAdviceDataRow}
            ExportComponent={ExportButtons}
          ></TableSkeleton>
        )}
        {tab === 1 && (
          <TableSkeleton
            dense
            name="salesorders"
            query={useSearchSalesorders}
            cells={salesOrderCells}
            emptyCells={salesOrderEmptyCells}
            filterParam={"status"}
            filterValues={SOfilterValues}
            firstColspan={firstColspan}
            initFilter={initFilter}
            DataRow={SalesOrderDataRow}
            ExportComponent={SaleOrderButtons}
          ></TableSkeleton>
        )}
      </Box>
    </>
  );
};

export default Orders;
