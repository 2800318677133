import { Box, makeStyles, Table, TableBody, Typography } from "@material-ui/core";
import { strings } from "common";
import { LightGreyRow } from "../components/Common";
import SalePricesLabelRow from "../components/DealsDetail/SalePricesLabelRow";
import SalesPricesDataRow from "../components/DealsDetail/SalesPricesDataRow";
import { CaseDataSchema, CreateSaleOrderDataSchema } from "../interfaces/case.interface";

const useStyles = makeStyles((theme) => ({
  summary: {
    backgroundColor: "#FAFAFA",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: theme.spacing(8, 0),
    padding: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(8),
  },
  text: {
    lineHeight: "21px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#1C1C20",
  },
  rowName: {
    fontSize: "12px",
    color: "#1D4854",
    marginRight: theme.spacing(4),
  },
  backButton: {
    marginRight: theme.spacing(10),
    background: "white",
    color: "grey",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
}));

const SalesOrderDetail = () => {
  const classes = useStyles();

  const data: CreateSaleOrderDataSchema = {};
  const selectedRows: CaseDataSchema[] = [];

  const information = [
    [
      {
        label: strings.general_information,
      },
      {
        label: strings.fk_customer,
        value: data.fkCustomer,
      },
      {
        label: strings.bond_type,
        value: data.bondType,
      },
      {
        label: strings.document_reference,
        value: data.documentRef,
      },
      {
        label: strings.dp_account_reference,
        value: data.dpAccountRef,
      },
      {
        label: strings.order_date,
        value: "", //data.orderDate.toLocaleDateString("en-US")
      },
      {
        label: strings.receiving_warehouse_or_shipper,
        value: data.deliveryEntityName,
      },
      {
        label: strings.delivery_address_line_1,
        value: data.deliveryAddress1,
      },
      {
        label: strings.delivery_address_line_2,
        value: data.deliveryAddress2,
      },
      {
        label: strings.delivery_address_line_3,
        value: data.deliveryAddress3,
      },
      {
        label: strings.delivery_town_county,
        value: data.deliveryTownOrCounty,
      },
      {
        label: strings.delivery_postcode,
        value: data.deliveryPostcode,
      },
      {
        label: strings.delivery_country,
        value: data.deliveryCountry,
      },
    ],
    [
      {
        label: strings.delivery,
      },
      {
        label: strings.fk_sales_order_type,
        value: data.fkSalesOrderType,
      },
      {
        label: strings.site_code,
        value: data.siteCode,
      },
      {
        label: strings.delivery_instructions,
        value: data.deliveryInstructions,
      },
      {
        label: strings.pick_instructions,
        value: data.pickInstructions,
      },
      {
        label: strings.earliest_time,
        value: "", //timeConverter(data.earliestTime)
      },
      {
        label: strings.latest_time,
        value: "", //timeConverter(data.latestTime)
      },
      {
        label: strings.earliest_time_2,
        value: "", //timeConverter(data.earliestTime2)
      },
      {
        label: strings.latest_time_2,
        value: "", //timeConverter(data.latestTime2)
      },
      {
        label: strings.customer_po_reference,
        value: data.customerPORef,
      },
      {
        label: strings.booked_or_collection_date,
        value: data.bookedOrCollectionDate,
      },
      {
        label: strings.collection_delivery_area,
        value: data.collectionDeliveryArea,
      },
      {
        label: strings.collection_by,
        value: data.collectionBy,
      },
    ],
    [
      {
        label: strings.customs,
      },
      {
        label: strings.excise_deferment_number,
        value: "",
      },
      {
        label: strings.customs_deferment_number,
        value: "",
      },
      {
        label: strings.pay_customs_duty,
        value: "",
      },
      {
        label: strings.consignee_name,
        value: data.consigneeName,
      },
      {
        label: strings.consignee_address_1,
        value: data.consigneeAddress1,
      },
      {
        label: strings.consignee_town,
        value: data.consigneeTown,
      },
      {
        label: strings.consignee_postcode,
        value: data.consigneePostcode,
      },
      {
        label: strings.consignee_country,
        value: data.consigneeCountry,
      },
    ],
  ];

  return (
    <Box>
      <Box className={classes.summary}>
        {information.map((column) => (
          <Box>
            {column.map((row, i) => (
              <div className={classes.row}>
                {i === 0 ? (
                  <Typography className={classes.text}>{row.label}</Typography>
                ) : (
                  <Typography className={classes.rowName} variant="body2">
                    {row.label}
                  </Typography>
                )}
                <Typography>{row.value}</Typography>
              </div>
            ))}
          </Box>
        ))}
      </Box>
      <Table>
        <SalePricesLabelRow />
        <TableBody>
          {selectedRows.map((row) => (
            <LightGreyRow key={row.id}>
              <SalesPricesDataRow data={row} />
            </LightGreyRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default SalesOrderDetail;
