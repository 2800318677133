import { Box, FormControl, InputLabel, makeStyles, MenuItem, Select, Snackbar, withStyles } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import { Publish } from "@material-ui/icons";
import { MainButton } from "../Common";
import PropTypes from "prop-types";
import { types, strings } from "common";
import { useBulkUpdateStatus, useTicketsExport } from "../../utils/issues";
import { TicketDataSchema } from "../../interfaces/ticket.interface";
import { issueStatusList } from "../../constants";

const ButtonRow = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    marginLeft: theme.spacing(8),
    paddingBottom: theme.spacing(3),
    display: "flex",
    alignItems: "center",
  },
}))(Box);

const useStyles = makeStyles((theme) => ({
  export: {
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
  },
  selectStatus: {
    marginLeft: theme.spacing(6),
    minWidth: theme.spacing(70),
    marginBottom: theme.spacing(8),
  },
}));

interface ExportComponentProps {
  selectedrows: Array<TicketDataSchema>;
}

const ExportComponent = ({ selectedrows, ...props }: ExportComponentProps) => {
  const [status, setStatus] = React.useState<keyof typeof issueStatusList | "">("");
  const [successUpdate, setSuccessUpdate] = React.useState<string | null>(null);
  const classes = useStyles();

  const ticketIds = selectedrows.map((row) => row.id);

  const { mutateAsync: bulkUpdateStatus } = useBulkUpdateStatus();
  const downloadTicketExport = useTicketsExport({ ticketIds });

  const handleExportTickets = () => {
    downloadTicketExport();
  };

  const handleStatusChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    const status = event.target.value as keyof typeof issueStatusList;
    setStatus(status);
    const issues = selectedrows.map((row) => ({
      id: row.id,
      status,
    }));
    await bulkUpdateStatus(issues);
    setSuccessUpdate(status);
  };

  return (
    <>
      <ButtonRow>
        <MainButton
          id="ticket_export"
          {...props}
          className={classes.export}
          startIcon={<Publish />}
          onClick={handleExportTickets}
        >
          {strings.export}
        </MainButton>

        <FormControl className={classes.selectStatus}>
          <InputLabel id="demo-multiple-name-label">{strings.select_status}</InputLabel>
          <Select disabled={!selectedrows.length} id="ticket_bulk_update" value={status} onChange={handleStatusChange}>
            {Object.keys(issueStatusList).map((issueStatus) => (
              <MenuItem value={issueStatus}>{issueStatus}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </ButtonRow>

      <Snackbar
        open={!!successUpdate}
        autoHideDuration={3500}
        onClose={() => {
          setSuccessUpdate(null);
          setStatus("");
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          severity="success"
        >{`${strings.success_bulk_update} ${status} !`}</MuiAlert>
      </Snackbar>
    </>
  );
};

ExportComponent.propTypes = {
  selectedrows: PropTypes.arrayOf(types.ticketDataShape).isRequired,
};

export default ExportComponent;
