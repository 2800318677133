import { useEffect, useState } from "react";
import { Box, makeStyles, TableHead, TableRow, useTheme } from "@material-ui/core";
import PropTypes from "prop-types";

import { ButtonBase } from "@material-ui/core";

import { GreyCell, Label, CheckBox, SmallGreyCell, AssignmentGreyCell } from "../Common";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { SortFieldSchema, TableCellSchema, TableEmptyCellSchema } from "../../interfaces/table.interface";

const useStyles = makeStyles((theme) => ({
  borderlessRow: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  icon: {
    color: theme.palette.text.secondary,
  },
  arrow: {
    fontSize: "0.75rem",
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(5),
  },
  outlineBoxCell: {
    width: theme.spacing(10),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
}));

interface LabelRowProps {
  cells: Array<TableCellSchema>;
  emptyCells: TableEmptyCellSchema;
  selectAll: () => void;
  deSelectAll: () => void;
  sortField?: SortFieldSchema;
  isAllSelected: () => boolean;
  hasSelections: () => boolean;
  dense: boolean;
  needCheckBox: boolean;
}

const LabelRow = ({
  cells,
  emptyCells,
  selectAll,
  deSelectAll,
  sortField,
  isAllSelected,
  hasSelections,
  dense,
  needCheckBox,
}: LabelRowProps) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    setSelected(hasSelections());
  }, [hasSelections()]);

  const select = () => {
    if (hasSelections()) {
      setSelected(false);
      deSelectAll();
      return;
    }

    if (selected) {
      setSelected(false);
      deSelectAll();
      return;
    }
    setSelected(true);
    selectAll();
  };

  const renderArrow = (key: string) => {
    if (!sortField?.sort || sortField?.sort !== key) return null;

    return sortField?.sortOrder === "DESC" ? (
      <ArrowDownward color="primary" className={classes.arrow} />
    ) : (
      <ArrowUpward color="primary" className={classes.arrow} />
    );
  };

  const isIndeterminate = hasSelections() && !isAllSelected();
  const theme = useTheme();

  const CellToRender = !needCheckBox ? AssignmentGreyCell : dense ? SmallGreyCell : GreyCell;

  return (
    <TableHead>
      <TableRow className={classes.borderlessRow}>
        {emptyCells?.expandableCell && <GreyCell width={emptyCells.expandableCell?.width}></GreyCell>}

        {needCheckBox && emptyCells.firstCell && (
          <CellToRender width={emptyCells.firstCell.width}>
            <Box display="flex">
              <CheckBox
                indeterminate={isIndeterminate}
                style={{
                  color: isIndeterminate ? theme.palette.text.secondary : theme.palette.primary.main,
                }}
                checked={selected}
                onChange={select}
                inputProps={{ "aria-label": "selectAll" }}
              />
            </Box>
          </CellToRender>
        )}

        {!needCheckBox && <CellToRender key={""} width={30}></CellToRender>}

        {cells.map(({ width, text, key }) => (
          <CellToRender key={text} width={width}>
            {/* <ButtonBase display="flex" onclick={() => !Boolean(disableSort) && setSortBy(key)}> */}
            <ButtonBase>
              <Label variant="caption">{text}</Label>
              {renderArrow(key)}
            </ButtonBase>
          </CellToRender>
        ))}

        {emptyCells.lastCell && <GreyCell width={emptyCells.lastCell.width}></GreyCell>}
      </TableRow>
    </TableHead>
  );
};

LabelRow.propTypes = {
  emptyCells: PropTypes.shape({
    firstCell: PropTypes.shape({
      width: PropTypes.string,
    }),
    lastCell: PropTypes.shape({
      width: PropTypes.string,
    }),
    expandableCell: PropTypes.shape({
      width: PropTypes.string,
    }),
  }),
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.string,
      text: PropTypes.string,
      key: PropTypes.string,
    })
  ).isRequired,
  selectAll: PropTypes.func.isRequired,
  deSelectAll: PropTypes.func.isRequired,
  isAllSelected: PropTypes.func.isRequired,
  hasSelections: PropTypes.func.isRequired,
  dense: PropTypes.bool,
  needCheckBox: PropTypes.bool,
};

LabelRow.defaultProps = {
  needCheckBox: true,
};

export default LabelRow;
