import React from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Modal } from "@material-ui/core";
import { useParams } from "react-router";
import ModalButtons from "../Common/ModalButtons";
import { types, strings } from "common";
import ShippingReview from "./ShippingReview";
import { Formik } from "formik";
import * as Yup from "yup";
import ModalContent from "./ModalContent";
import { useShipDeal } from "../../utils/deals";
import { DealDetailInformationSchema } from "../../interfaces/deal.interface";
import { CaseDataSchema } from "../../interfaces/case.interface";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    width: "800px",
    background: "white",
    outline: "none",
    padding: theme.spacing(12, 12, 12, 12),
  },
}));

const priceValidation = Yup.object().shape({
  salePrice: Yup.lazy((obj) =>
    Yup.object(
      Object.keys(obj).reduce(
        (accum, key) => ({
          ...accum,
          [key]: Yup.number().required(strings.required),
        }),
        {}
      )
    )
  ),
});

const shippingValidation = Yup.object().shape({
  clientName: Yup.string().required(strings.required),
  address: Yup.string().required(strings.required),
  postCode: Yup.string()
    // .trim(" ")
    // .matches(ukPostalCode)
    .required(strings.required),
  country: Yup.string().required(strings.required),
  city: Yup.string().required(strings.required),
  releaseType: Yup.string().required(strings.required),
  transporter: Yup.string().required(strings.required),
});

interface DealsModalProps {
  open: boolean;
  data: DealDetailInformationSchema;
  setModalOpen: (val: boolean) => void;
  casesToShip: Array<CaseDataSchema>;
  step: number;
  setStep: (val: number) => void;
}

const DealsModal = ({ open, data, setModalOpen, casesToShip, step, setStep }: DealsModalProps) => {
  const classes = useStyles();

  const closeModal = () => {
    setStep(0);
    setModalOpen(false);
  };

  const setSalePrice =
    (setFieldValue: any, values: any) => (id: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValue("salePrice", { ...values.salePrice, [id]: e.target.value });
    };

  const { mutateAsync: shipDeal } = useShipDeal();

  const { id: dealId } = useParams<{ id: string }>();

  return (
    <Modal
      id="dealsDetailModal"
      open={open}
      className={classes.modal}
      onClose={closeModal}
      data-testid="shipDealModal"
      aria-labelledby="ship deal"
      aria-describedby="ship deal modal"
    >
      <Box className={classes.modalContent}>
        <Formik
          initialValues={{
            salePrice: casesToShip.map((item) => {
              return {
                id: item.id,
                salePrice: item.salePrice,
              };
            }),
            clientName: data.clientName,
            address: data?.address?.address,
            postCode: data?.address?.postCode,
            city: data?.address?.city,
            country: data?.address?.country,
            releaseType: "",
            transporter: "",
          }}
          validationSchema={step === 0 ? priceValidation : shippingValidation}
          onSubmit={async (values, {}) => {
            if (step === 0) {
              setStep(1);
              return;
            }

            const dataToSend = {
              dealId,
              cases: values.salePrice,
              deliveryAddressId: 1, // TODO: we will receive this ID from a service
              releaseType: values.releaseType,
              transporter: values.transporter,
            };

            // setSubmitting(true);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await shipDeal(dataToSend);
            // setSubmitting(false);
            closeModal();
          }}
        >
          {({ values, handleSubmit, errors, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              {step === 0 && (
                <ModalContent
                  closeId="closeStep0DealModal"
                  casesToShip={casesToShip}
                  closeModal={closeModal}
                  title={strings.sale_prices}
                  values={values}
                  setSalePrice={setSalePrice(setFieldValue, values)}
                  errors={errors}
                  editablePrice
                />
              )}

              {step === 1 && (
                <>
                  <ModalContent
                    closeId="closeStep1DealModal"
                    casesToShip={casesToShip}
                    closeModal={closeModal}
                    title={strings.review_shipping}
                    ShippingReview={ShippingReview}
                    setSalePrice={setSalePrice(setFieldValue, values)}
                    values={values}
                    errors={errors}
                  />
                </>
              )}

              <ModalButtons
                submitId="submitDealModal"
                cancelId="cancelDealModal"
                step={step}
                onClick={handleSubmit}
                isSubmitting={false}
                closeModal={() => {
                  setStep(0);
                  setModalOpen(false);
                }}
                actionText={""}
              />
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

DealsModal.propTypes = {
  data: types.dealsDetailDataShape,
  step: PropTypes.number,
  setStep: PropTypes.func,
  casesToShip: PropTypes.arrayOf(types.stockDataShape),
  setModalOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DealsModal;
