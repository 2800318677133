import { useState } from "react";
import { Box, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { strings } from "common";
import { useParams } from "react-router";
import { LocalShipping } from "@material-ui/icons";
import { BreadCrumb } from "../components/Table";
import { useMultipleDealsDetail } from "../utils/dealsDetail";
import { DealsCaseListTable } from "../components/DealsDetail";
import { MainButton } from "../components/Common";
import { useCasesSearch } from "../utils/cases";
import CreateSaleOrder from "../components/Stock/CreateSaleOrder";
import MultipleDealsInformation from "../components/DealsDetail/MultipleDealsInformation";
import ShipErrorModal from "../components/DealsDetail/ShipErroModal";
import ShipSuccessModal from "../components/DealsDetail/ShipSuccessModal";
import { CaseSelectRowSchema } from "../interfaces/deal.interface";
import { CaseDataSchema } from "../interfaces/case.interface";

const useStyles = makeStyles((theme) => ({
  caseList: {
    background: theme.palette.grey[50],
  },
  caseListHeader: {
    padding: theme.spacing(8, 8, 8, 8),
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  spinner: {},
}));
const ShipMultipleDeals = () => {
  const classes = useStyles();
  const { ids } = useParams<{ ids: string }>();
  const idsList = ids.split(",");

  const [isModalOpen, setModalOpen] = useState(true);
  const [casesToShip, setCasesToShip] = useState<Array<CaseDataSchema>>([]);
  const { data } = useMultipleDealsDetail(idsList);
  const [queryParam, setQueryParam] = useState({ dealId: idsList, currentDate: new Date().getTime() });

  const [showErrorModal, setShowErrorModal] = useState<string | null>(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // get shipcounts
  const { isLoading: isLoadingCases } = useCasesSearch({
    dealId: idsList,
    shippingCounts: true,
  });

  const openModal = async (rows: CaseDataSchema[]) => {
    setCasesToShip(rows);
    setModalOpen(true);
  };

  const handleSuccessEvent = () => {
    setQueryParam({ dealId: idsList, currentDate: new Date().getTime() });
    setShowSuccessModal(false);
  };

  const handleErrorEvent = () => {
    setShowErrorModal(null);
    setModalOpen(true);
  };

  const exportComponent = ({ selectedrows, ...props }: CaseSelectRowSchema) => {
    return (
      <MainButton onClick={() => openModal(selectedrows)} startIcon={<LocalShipping />} {...props}>
        {strings.ship}
      </MainButton>
    );
  };

  return (
    <Box>
      <BreadCrumb
        path="Deals"
        title={`${strings.deals}${idsList.map((_id, index) => " " + data[index]?.dealNumber || "-") ?? "-"}`}
        isLoading={isLoadingCases}
      />

      <>
        {!isLoadingCases && <MultipleDealsInformation data={data} />}

        <Box marginTop={8} className={classes.caseList}>
          <Box className={classes.caseListHeader}>
            <Typography variant="h4">{strings.case_list}</Typography>

            {isLoadingCases && (
              <Box data-testid="loading" className={classes.spinner}>
                <CircularProgress size={20} />
              </Box>
            )}
          </Box>

          <DealsCaseListTable queryParam={queryParam} ExportComponent={exportComponent} showDealNumber={true} />
        </Box>
      </>

      {casesToShip.length > 0 && isModalOpen && (
        <CreateSaleOrder
          selectedRows={casesToShip}
          open={isModalOpen}
          setOpen={setModalOpen}
          isFromDeal={true}
          handleErrorEvent={handleErrorEvent}
          setSuccessModal={setShowSuccessModal}
          setErrorModal={setShowErrorModal}
        />
      )}
      {showErrorModal && <ShipErrorModal isOpen={!!showErrorModal} handleEvent={handleErrorEvent} />}
      {showSuccessModal && <ShipSuccessModal isOpen={showSuccessModal} handleEvent={handleSuccessEvent} />}
    </Box>
  );
};

export default ShipMultipleDeals;
