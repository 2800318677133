import { strings } from "common";
import { useMutation, UseMutationResult, useQuery } from "react-query";
import { useClient } from "../providers/auth-context";
import { paramsToQueryString } from "./api-client";
import { validate as uuidValidate } from "uuid";
import { ClientType } from "../interfaces/apiClient.interface";
import {
  CaseDashboardDataResponseSchema,
  CaseDataSchema,
  CreatePreadviceResponseSchema,
} from "../interfaces/case.interface";
import { DashboardDataSchema } from "../interfaces/dashboard.interface";
import { CaseResponseData, PhysicalCaseResponseData, TableSearchSchema } from "../interfaces/table.interface";
import { formatCurrency } from "./currency";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const apiURL = window.env.REACT_APP_API_URL;

// const removePO_Reg = new RegExp("PO", "g");

const getCasesSearchConfig = (client: ClientType<CaseResponseData>, params: TableSearchSchema) => ({
  queryKey: ["casesSearch", { params }],
  queryFn: () =>
    client(`asset-units?${paramsToQueryString(params)}`)
      .then((data) => data)
      .catch((err) => console.log(err)),
});

const getCasesForDashboardConfig = (client: ClientType<CaseDashboardDataResponseSchema>, type: string) => ({
  queryKey: ["casesDashboard", { type }],
  queryFn: () => client(`cases/dashboard?type=${type}`).then((data) => data),
});

const getPhysicalCasesSearchConfig = (client: ClientType<PhysicalCaseResponseData>, params: TableSearchSchema) => ({
  queryKey: ["PhysicalRecordSearch", { params }],
  queryFn: () => client(`physical-cases?${paramsToQueryString(params)}`).then((data) => data),
});

function useCasesSearch(params: TableSearchSchema) {
  params.assetUnitId = uuidValidate(params.assetUnitId ?? "") ? params.assetUnitId : "";
  const client = useClient<CaseResponseData>();
  const result = useQuery(getCasesSearchConfig(client, params));
  return { isLoading: result.isLoading, data: result.data };
}

function useCasesForDashboard(type: string) {
  const client = useClient<CaseDashboardDataResponseSchema>();
  const result = useQuery(getCasesForDashboardConfig(client, type));

  const defaultResult: DashboardDataSchema[] = [];

  return { data: result.data?.data ?? defaultResult };
}

const getCasesStatusConfig = (client: ClientType<Array<DashboardDataSchema>>, params?: TableSearchSchema) => ({
  queryKey: ["casesStatus", { params }],
  queryFn: () =>
    client(`cases/warehouse-status${params?.warehouseId ? `?warehouseId=${params.warehouseId}` : ""}`).then(
      (data) => data
    ),
});

function useCasesStatus(params?: TableSearchSchema) {
  const client = useClient<Array<DashboardDataSchema>>();
  const result = useQuery(getCasesStatusConfig(client, params));

  return { ...result, data: result.data || [] };
}

const useCasesExport = (params: { caseIds: Array<number> }, route: string) => {
  const url = `${apiURL}/${route}/export?ids=[${params.caseIds}]`;
  const nowDate = new Date();
  const dateString = buildDateString(nowDate);
  const filename = `${route}_exported_stock_${dateString}.xlsx`;

  return () => fetchFileGet(url, filename);
};

function useResendSaleOrder() {
  const client = useClient();

  return useMutation((body) =>
    client(`sales-order/resend`, {
      method: "POST",
      body: JSON.stringify(body),
    })
  );
}

function useResendPreAdvice() {
  const client = useClient();

  return useMutation((body) =>
    client(`preadvices/resend`, {
      method: "POST",
      body: JSON.stringify(body),
    })
  );
}

function useCasesRequestPhoto() {
  const client = useClient();

  return useMutation((body) =>
    client(`cases/request-photo`, {
      method: "POST",
      body: JSON.stringify(body),
    })
  );
}

function useCasesPreadvice(): UseMutationResult<CreatePreadviceResponseSchema> {
  const client = useClient<CreatePreadviceResponseSchema>();

  return useMutation((body) =>
    client(`preadvices`, {
      method: "POST",
      body: JSON.stringify(body),
    })
  );
}

function buildPreAdviceFileName(purchaseOrderNumber: string, suffix: string) {
  const nowDate = new Date();
  const dateString = buildDateString(nowDate);
  // if (purchaseOrderNumber.includes("PO")) {
  //   purchaseOrderNumber = purchaseOrderNumber.replace(removePO_Reg, "");
  // }
  return `PA${dateString}_${purchaseOrderNumber}.${suffix}`;
}

function buildDateString(nowDate: Date) {
  const year = new Intl.DateTimeFormat("en-GB", { year: "2-digit" }).format(nowDate);
  const month = new Intl.DateTimeFormat("en-GB", { month: "2-digit" }).format(nowDate);
  const day = new Intl.DateTimeFormat("en-GB", { day: "2-digit" }).format(nowDate);
  const hour = new Intl.DateTimeFormat("en-GB", { hour: "2-digit" }).format(nowDate);
  const minute = nowDate.getMinutes();
  const second = nowDate.getSeconds();

  return `${year}${month}${day}_${hour}${minute > 9 ? minute : "0" + minute}${second > 9 ? second : "0" + second}`;
}

function fetchFile(url: string, fileNamePrefix: string, ids: Array<number>) {
  const isoDateString = new Date().toISOString();
  const fileName = `${fileNamePrefix}_${isoDateString}.xlsx`;
  fetch(url, {
    credentials: "include",
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify({ ids: ids }),
  }).then((res) => {
    res.blob().then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(blobUrl);
    });
  });
}

function fetchFileGet(url: string, fileName: string) {
  fetch(url, {
    credentials: "include",
    method: "GET",
  }).then((res) => {
    res.blob().then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(blobUrl);
    });
  });
}

function getCellValue(key: string, data: CaseDataSchema) {
  let value = data[key as keyof typeof data];
  if (key === "physicalRecord") {
    value = "-";
  } else if (key === strings.tradeable.toLowerCase()) {
    value = data["isLocked"] ? "Not tradeable" : "Tradeable";
  } else if (key === "clientPurchasePrice" || key === "cwPurchasePrice") {
    value = formatCurrency(data[key as keyof typeof data] as string);
  }
  return value as string;
}

function usePhysicalRecordSearch(params: TableSearchSchema) {
  const client = useClient<PhysicalCaseResponseData>();
  const result = useQuery(getPhysicalCasesSearchConfig(client, params));

  return { data: result.data };
}

function useCaseLinkAdjust() {
  const client = useClient();
  return useMutation((body) =>
    client(`cases/link/adjust`, {
      method: "POST",
      body: JSON.stringify(body),
    })
  );
}

export {
  buildDateString,
  buildPreAdviceFileName,
  fetchFile,
  getCellValue,
  useCaseLinkAdjust,
  useCasesExport,
  useCasesForDashboard,
  useCasesPreadvice,
  useCasesRequestPhoto,
  useCasesSearch,
  useCasesStatus,
  usePhysicalRecordSearch,
  useResendPreAdvice,
  useResendSaleOrder,
};
