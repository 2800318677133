const auditData = {
  count: 12,
  data: [
    {
      id: 15,
      service: "/api/cases/:id",
      entity: "CASES",
      auxiliarEntityId: null,
      entityId: 46,
      data: {
        stockId: 2,
      },
      userName: null,
      action: "UPDATE",
      createdAt: "2021-06-08T13:48:43",
    },

    {
      id: 13,
      service: "attitude-oriented",
      entity: "ISSUES",
      auxiliarEntityId: 10,
      entityId: 1,
      data: {
        Fantastic: "task-force",
      },
      userName: "Kaz Brekker",
      action: "UPDATE",
      createdAt: "2021-06-07T12:16:25",
    },
    {
      id: 12,
      service: "Ports",
      entity: "CASES",
      auxiliarEntityId: 27185,
      entityId: 1,
      data: {
        Squares: "generating",
      },
      userName: "Kaz Brekker",
      action: "UPDATE",
      createdAt: "2021-06-07T12:16:25",
    },
    {
      id: 8,
      service: "/api/issues/comment",
      entity: "ISSUE_COMMENTS",
      auxiliarEntityId: 2,
      entityId: 2,
      data: {
        id: 20,
        userId: 1,
        comment: "tee",
        issueId: 2,
        createdAt: "2021-06-07T10:52:09.008Z",
        updatedAt: "2021-06-07T10:52:09.008Z",
      },
      userName: null,
      action: "CREATE",
      createdAt: "2021-06-07T11:52:09",
    },
    {
      id: 7,
      service: "/api/issues/:id",
      entity: "ISSUES",
      auxiliarEntityId: 2,
      entityId: 2,
      data: {
        statusId: 1,
      },
      userName: null,
      action: "UPDATE",
      createdAt: "2021-06-07T11:52:06",
    },
    {
      id: 6,
      service: "/api/cases/:id",
      entity: "CASES",
      auxiliarEntityId: null,
      entityId: 2,
      data: {
        note: "teste",
        stockId: 3,
        supplierName: "supplier name2",
      },
      userName: null,
      action: "UPDATE",
      createdAt: "2021-06-07T11:51:27",
    },
    {
      id: 5,
      service: "/api/cases/:id",
      entity: "CASES",
      auxiliarEntityId: null,
      entityId: 2,
      data: {
        initialPurchasePricePence: 209,
      },
      userName: null,
      action: "UPDATE",
      createdAt: "2021-06-07T11:51:27",
    },
    {
      id: 4,
      service: "/api/issues/comment",
      entity: "ISSUE_COMMENTS",
      auxiliarEntityId: 3,
      entityId: 1,
      data: {
        id: 19,
        userId: 1,
        comment: "ts",
        issueId: 3,
        createdAt: "2021-06-07T10:33:42.105Z",
        updatedAt: "2021-06-07T10:33:42.105Z",
      },
      userName: null,
      action: "CREATE",
      createdAt: "2021-06-07T11:33:42",
    },
    {
      id: 3,
      service: "/api/issues/:id",
      entity: "ISSUES",
      auxiliarEntityId: 3,
      entityId: 1,
      data: {
        statusId: 2,
      },
      userName: null,
      action: "UPDATE",
      createdAt: "2021-06-07T11:33:39",
    },
    {
      id: 2,
      service: "/api/cases/:id",
      entity: "CASES",
      auxiliarEntityId: null,
      entityId: 2,
      data: {
        note: "this note",
        stockId: 2,
        supplierName: "supplier name",
      },
      userName: null,
      action: "UPDATE",
      createdAt: "2021-09-07T11:32:53",
    },
    {
      id: 1,
      service: "/api/cases/:id",
      entity: "CASES",
      auxiliarEntityId: null,
      entityId: 2,
      data: {
        initialPurchasePricePence: 100,
      },
      userName: null,
      action: "UPDATE",
      createdAt: "2021-06-09T11:32:53",
    },
  ],
};

export default auditData;
