import { Box, makeStyles, withStyles } from "@material-ui/core";
import { Publish, Send } from "@material-ui/icons";
import PropTypes from "prop-types";
import { useState } from "react";
import { types, strings } from "common";
import { fetchFile, useResendSaleOrder } from "../../utils/cases";
import { MainButton } from "../Common";
import { ResendModal } from "./index";
import { OrderDataSchema } from "../../interfaces/order.interface";

const ButtonRow = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    marginLeft: theme.spacing(8),
    paddingBottom: theme.spacing(3),
  },
}))(Box);

const useStyles = makeStyles((theme) => ({
  rightButton: { marginLeft: theme.spacing(4) },
}));

interface SaleOrderButtonsProps {
  selectedrows: Array<OrderDataSchema>;
}

const SaleOrderButtons = ({ selectedrows, ...props }: SaleOrderButtonsProps) => {
  const classes = useStyles();

  const caseIds = selectedrows.map((row) => row.id);

  const [isReSend, setIsReSend] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { mutateAsync: resendSaleOrder } = useResendSaleOrder();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const apiURL = window.env.REACT_APP_API_URL;
  const url = `${apiURL}/sales-order/export`;

  const handleExportSO = () => {
    fetchFile(url, "SO_CASES", caseIds);
  };

  const handleResendSO = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resendSaleOrder({ ids: caseIds })
      .then(() => {
        closeResendSOModal();
      })
      .catch((error) => {
        setErrorMsg(error.message);
      });
  };

  const showResendSOModal = () => {
    setIsReSend(true);
  };

  const closeResendSOModal = () => {
    setIsReSend(false);
    setErrorMsg("");
  };

  return (
    <ButtonRow>
      <MainButton id="order_so_export" {...props} startIcon={<Publish />} onClick={handleExportSO}>
        {strings.export}
      </MainButton>

      <MainButton
        className={`${classes.rightButton} `}
        id="order_so_resent"
        {...props}
        startIcon={<Send />}
        onClick={showResendSOModal}
      >
        {strings.resend}
      </MainButton>

      {isReSend && (
        <ResendModal
          modalId="resendSOModal"
          isOpen={isReSend}
          handleEvent={handleResendSO}
          closeModal={closeResendSOModal}
          errorMsg={errorMsg}
        />
      )}
    </ButtonRow>
  );
};

SaleOrderButtons.propTypes = {
  selectedrows: PropTypes.arrayOf(types.stockDataShape).isRequired,
};

export default SaleOrderButtons;
