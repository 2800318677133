import { Box, makeStyles, TableRow } from "@material-ui/core";
import { Visibility, Flag } from "@material-ui/icons";
import { CheckBox, ClickableIcon, SmallCell } from "../Common/";
import { types } from "common";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { FormattedCell } from "../Table";
import { getCellValue } from "../../utils/cases";
import { CaseDataSchema } from "../../interfaces/case.interface";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { TableCellSchema } from "../../interfaces/table.interface";

const useStyles = makeStyles((theme) => ({
  borderlessRow: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  collapsableCell: {
    paddingBottom: 0,
    paddingTop: 0,
    backgroundColor: theme.palette.grey.A100,
  },
  collapseIcon: {
    paddingLeft: 8,
    color: theme.palette.text.secondary,
  },
  visibilityIcon: {
    marginRight: theme.spacing(6),
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}));

interface formatCellProps {
  key: string;
  data: CaseDataSchema;
  classes: ClassNameMap<"icon">;
}

interface DataRowProps {
  data: CaseDataSchema;
  selectRow: () => void;
  selected: boolean;
  cells: Array<TableCellSchema>;
}

const formatCell = ({ key, data, classes }: formatCellProps) => {
  if (key === "hasNote") {
    return (
      <SmallCell key={key}>
        <Box display="flex">{data.hasNote && <Flag data-testid="hasNote" className={classes.icon} />}</Box>
      </SmallCell>
    );
  }

  if (key === "ticketed") {
    return (
      <SmallCell key={key}>
        <Box display="flex">{data.ticketed && <Flag data-testid="ticketed" className={classes.icon} />}</Box>
      </SmallCell>
    );
  }
  const newValue = getCellValue(key, data);

  return <FormattedCell dense key={key} label={key} value={newValue} disabled={false} />;
};

const DataRow = ({ data, selectRow, selected, cells }: DataRowProps) => {
  const classes = useStyles();
  const history = useHistory();

  const renderCells = () => cells.map(({ key }) => formatCell({ key, data, classes }));

  return (
    <>
      <TableRow data-testid="datarow" className={classes.borderlessRow}>
        <SmallCell>
          <Box display="flex" paddingLeft={4} />
        </SmallCell>
        <SmallCell>
          <Box display="flex">
            <CheckBox
              color="primary"
              onChange={selectRow}
              checked={Boolean(selected)}
              inputProps={{ "aria-label": "rowCheckBox" }}
            />
          </Box>
        </SmallCell>

        {renderCells()}

        <SmallCell>
          <Box display="flex" justifyContent="center">
            <ClickableIcon
              aria-label="rowDetail"
              onClick={() => history.push(`/tickets/${data.issueId}`)}
              className={classes.visibilityIcon}
              disabled={!data.issueId}
            >
              <Visibility fontSize="small" color={data.issueId ? "primary" : "disabled"} />
            </ClickableIcon>
          </Box>
        </SmallCell>
      </TableRow>
    </>
  );
};

DataRow.propTypes = {
  data: types.stockDataShape,
  selectRow: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

export default DataRow;
