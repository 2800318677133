import { useQuery } from "react-query";
import { useClient } from "../providers/auth-context";
import { SupplierSchema } from "../interfaces/user.interface";

interface SuppliersListResponse {
  count: number;
  data: Array<SupplierSchema>;
}

function useSuppliersList(name: string) {
  const client = useClient<SuppliersListResponse>();
  const result = useQuery({
    queryKey: ["suppliersList", { name }],
    queryFn: () =>
      client(`frontoffice/VintradeSuppliers${name && `?name=${name}`}`, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        customURL: window.env.REACT_APP_API_URL.split("/ims")[0],
      }).then((data) => data),
  });

  return { isLoading: result.isLoading, data: result.data };
}

export { useSuppliersList };
