import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query";
import { useClient } from "../providers/auth-context";
import { IssueDetailDataSchema } from "../interfaces/ticket.interface";
import { ClientType } from "../interfaces/apiClient.interface";

const getTicketDetailSearchConfig = (client: ClientType<IssueDetailDataSchema>, params: { id: string }) => ({
  queryKey: ["issuesDetails", { id: params.id }],
  queryFn: () => client(`issues/${params.id}/details`).then((data) => data),
});

function useIssueDetails(params: { id: string }) {
  const client = useClient<IssueDetailDataSchema>();
  const result = useQuery(getTicketDetailSearchConfig(client, params));

  return { isLoading: result.isLoading, data: result.data };
}

const defaultMutationOptions = (queryClient: QueryClient) => ({
  onSettled: () => queryClient.invalidateQueries("issuesDetails"),
});

const onUpdateMutation = (queryClient: QueryClient) => (newItem: { issueId: string; status: string }) => {
  // need to check this
  const updateKey = ["issuesDetails", { id: `${newItem.issueId}` }];
  const previousItem = queryClient.getQueryData(updateKey);

  return () => queryClient.setQueryData(updateKey, previousItem);
};

function usePostComment() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    ({ issueId, comment }: { issueId: string; comment: string }) =>
      client(`issues/${issueId}/comments`, {
        data: {
          comment,
        },
      }),
    {
      ...defaultMutationOptions(queryClient),
    }
  );
}

function useUpdateStatus() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    ({ issueId, status }: { issueId: string; status: string }) =>
      client(`issues/${issueId}/`, {
        method: "PATCH",
        body: JSON.stringify({ status }),
      }),
    {
      ...defaultMutationOptions(queryClient),
      onMutate: onUpdateMutation(queryClient),
    }
  );
}

export { useIssueDetails, usePostComment, useUpdateStatus };
