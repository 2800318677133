import { useEffect, useState } from "react";
import { Box, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { BottomTable, WineBanner, StockModal } from "../components/StockDetail";
import { strings } from "common";
import { useParams } from "react-router";
import { BreadCrumb } from "../components/Table";
import { useCasesDetailsSearch } from "../utils/casesDetails";
import { useIssuesSearch } from "../utils/issues";

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(8),
  },
  vintageText: {
    paddingLeft: theme.spacing(8),
    fontWeight: "normal",
  },
  tablesContainer: {
    paddingBottom: theme.spacing(10),
  },
  loader: {
    marginTop: theme.spacing(8),
  },
}));

const StockDetail = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useCasesDetailsSearch(id);

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { data: ticketData, isLoading: isLoadingTickets } = useIssuesSearch({
    caseId: id,
  });

  return data ? (
    <Box>
      <BreadCrumb path="Stock" title={`${data.wineName} ${data.vintage}`} isLoading={isLoading} />

      {!isLoading && (
        <>
          <Box className={classes.headerTitle}>
            <Typography variant="h1">{data.wineName}</Typography>
            <Typography variant="h1" className={classes.vintageText}>
              {data.vintage}
            </Typography>
          </Box>

          {data.hash && (
            <>
              <Box marginTop={8}>
                <Typography variant="caption" color="primary">
                  {strings.blockchain_hash}
                </Typography>
              </Box>
              <Box marginTop={2}>
                <Typography variant="body1">{data.hash}</Typography>
              </Box>
            </>
          )}

          <WineBanner data={data} openModal={() => setModalOpen(true)} />

          <Box className={classes.tablesContainer}>
            {!isLoadingTickets && ticketData ? (
              <BottomTable ticketData={ticketData.data}></BottomTable>
            ) : (
              // check loading states rquery
              <Box data-testid="loading" className={classes.loader}>
                <CircularProgress size={20} />
              </Box>
            )}

            <StockModal open={isModalOpen} setModalOpen={setModalOpen} data={data} />
          </Box>
        </>
      )}
    </Box>
  ) : (
    <Typography>{strings.no_data}</Typography>
  );
};

export default StockDetail;
