import PropTypes from "prop-types";
import { Box, makeStyles, Table, TableBody, Typography } from "@material-ui/core";
import { Cell, ClickableIcon, GreyRow, Label, LightGreyRow, MainButton, StickyTableHead } from "../Common";
import { Visibility } from "@material-ui/icons";
import { isValidDate } from "../../utils/date";
import { DateTime } from "luxon";
import { dateFormat } from "../../constants";
import { get } from "lodash";
import { formatCurrency } from "../../utils/currency";
import { strings } from "common";
import { FormattedCell } from "../Table";
import {
  DashboardCellSchema,
  DashboardDataSchema,
  DashboardEmptyCellSchema,
  DashboardGroupCellSchema,
} from "../../interfaces/dashboard.interface";

const useStyles = makeStyles((theme) => ({
  boxBackground: {
    backgroundColor: theme.palette.grey[50],
  },
  header: {
    padding: `${theme.spacing(8)}px ${theme.spacing(8)}px`,
  },
  tableHeight: {
    height: 311,
  },
  noScroll: {
    overflow: "hidden",
  },
  seeAllButton: {
    padding: 0,
    fontWeight: 700,
    float: "right",
  },
}));

interface DashboardSummaryTableProps {
  title: string;
  data: Array<DashboardDataSchema>;
  cells: Array<DashboardCellSchema>;
  groupCells?: Array<DashboardGroupCellSchema>;
  emptyCells: DashboardEmptyCellSchema;
  hasSeeAllButton: boolean;
  hasNestedValues: boolean;
  onClickParent?: (action: string, id?: number) => void;
  hasScroll: boolean;
}

const DashboardSummaryTable = ({
  title,
  data,
  cells,
  groupCells,
  emptyCells,
  hasSeeAllButton,
  hasNestedValues,
  onClickParent,
  hasScroll,
}: DashboardSummaryTableProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxBackground} data-testid={`table${title}`}>
      <Typography className={classes.header} variant="h6">
        {title}
        {Boolean(hasSeeAllButton) && (
          <MainButton
            onClick={() => {
              if (onClickParent) {
                onClickParent(title);
              }
            }}
            className={classes.seeAllButton}
          >
            {strings.see_all}
          </MainButton>
        )}
      </Typography>

      <Box>
        <Table className={`${classes.tableHeight} ${hasScroll ? "" : classes.noScroll}`}>
          <Table stickyHeader>
            <StickyTableHead data-testid="tablehead">
              {groupCells && (
                <GreyRow>
                  {groupCells.map(({ colSpan, text }: { colSpan: number | undefined; text: string }) => (
                    <Cell key={text} colSpan={colSpan}>
                      <Label variant="caption">{text}</Label>
                    </Cell>
                  ))}
                </GreyRow>
              )}
              <GreyRow>
                {emptyCells?.firstCell && <Cell width={emptyCells.firstCell.width}></Cell>}

                {cells.map(({ width, text, key }) => (
                  <Cell key={`${text}-${key}`} width={width}>
                    <Label variant="caption">{text}</Label>
                  </Cell>
                ))}

                {emptyCells?.lastCell && <Cell width={emptyCells.lastCell.width}></Cell>}
              </GreyRow>
            </StickyTableHead>

            <TableBody>
              {data?.map((data, index) => (
                <LightGreyRow key={data.caseId ? `${data.id}${data.caseId}` : data.id || index}>
                  {emptyCells?.firstCell && emptyCells?.firstCell?.key && (
                    <Cell key={emptyCells.firstCell.key || "firstCell"}>
                      <Typography variant="body1">
                        {emptyCells.firstCell.key === "bookedDate"
                          ? isValidDate(data["bookedDate"])
                            ? `${DateTime.fromISO(data["bookedDate"]).toFormat(dateFormat)}`
                            : `${data[emptyCells.firstCell.key]}`
                          : ""}
                      </Typography>
                    </Cell>
                  )}

                  {cells.map(({ key, isCurrency }) => {
                    const value = hasNestedValues ? get(data, key) : data[key as keyof DashboardDataSchema];
                    const convertedValue = isCurrency ? formatCurrency(value.toString()) : value;
                    return (
                      <FormattedCell
                        key={key}
                        label={key}
                        value={convertedValue ? convertedValue.toString() : ""}
                        disabled={false}
                        isDeal={true}
                      />
                    );
                  })}

                  {emptyCells?.lastCell && (
                    <Cell key="lastCell" align="right">
                      <Box display="flex">
                        <ClickableIcon
                          aria-label="goToDetail"
                          onClick={() => {
                            if (onClickParent) {
                              onClickParent(title, data.id);
                            }
                          }}
                        >
                          <Visibility fontSize="small" color="primary" />
                        </ClickableIcon>
                      </Box>
                    </Cell>
                  )}
                </LightGreyRow>
              ))}
            </TableBody>
          </Table>
        </Table>
      </Box>
    </Box>
  );
};

DashboardSummaryTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  emptyCells: PropTypes.shape({
    lastCell: PropTypes.shape({
      width: PropTypes.string,
    }),
    firstCell: PropTypes.shape({
      width: PropTypes.string,
      key: PropTypes.string,
    }),
  }),
  cells: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.string,
      text: PropTypes.string,
      key: PropTypes.string,
    })
  ).isRequired,
  groupCells: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.string,
      colSpan: PropTypes.number,
    })
  ),
  hasSeeAllButton: PropTypes.bool,
  hasNestedValues: PropTypes.bool,
  onClickParent: PropTypes.func,
  hasScroll: PropTypes.bool,
};

export default DashboardSummaryTable;
