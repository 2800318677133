import React, { ChangeEvent } from "react";
import { Box, makeStyles, TableRow, TextField } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { useHistory } from "react-router";
import { Cell, CheckBox, ClickableIcon, SmallCell } from "../Common/";
import { strings } from "common";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { cells } from "./cells";
import { FormattedCell } from "../Table";
import { useUpdateDeal } from "../../utils/deals";
import { useQueryClient } from "react-query";
import PropTypes from "prop-types";
import { DealDataSchema } from "../../interfaces/deal.interface";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

const useStyles = makeStyles((theme) => ({
  visibilityIcon: {
    marginRight: theme.spacing(6),
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
  close: {
    color: theme.palette.error.main,
  },
}));

interface DataRowProps {
  data: DealDataSchema;
  selectRow: () => void;
  selected: boolean;
}

const DataRow = ({ data, selectRow, selected }: DataRowProps) => {
  const classes = useStyles();
  const [isFocused, setFocus] = React.useState<boolean>();
  const [comment, setComment] = React.useState(data.note || "");
  const [error, setError] = React.useState<boolean>(false);
  const inputRef = React.useRef<any>();
  const history = useHistory();

  const { mutateAsync: updateDeal } = useUpdateDeal();
  const queryClient = useQueryClient();

  const goToDetail = () => {
    history.push(`/deals/${data.id}`);
  };

  const onFocus = () => {
    setFocus(true);
  };

  const onCancel = () => {
    setFocus(false);
    setComment(data.note || "");

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    inputRef?.onBlur?.();

    if (error) {
      queryClient.invalidateQueries("dealsSearch");
      setError(false);
    }
  };

  const onAccept = async () => {
    setFocus(false);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    inputRef?.onBlur?.();

    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await updateDeal({ id: data.id, note: comment });

      setError(false);
    } catch (error) {
      setError(true);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const note = e.target.value;
    setComment(note);
  };

  const formatCell = (
    key: keyof DealDataSchema,
    data: DealDataSchema,
    classes: ClassNameMap<"checkIcon" | "close">
  ) => {
    if (key === "note") {
      return (
        <Cell key={key}>
          <Box display="flex">
            <TextField
              inputProps={{ "aria-label": "comment" }}
              ref={inputRef}
              fullWidth
              value={comment}
              error={Boolean(error)}
              helperText={error ? strings.comment_failed : ""}
              onChange={onChange}
              onFocus={onFocus}
            />
            {isFocused && (
              <Box display="flex" alignItems="center" paddingLeft={3}>
                <ClickableIcon aria-label="accept" onClick={onAccept}>
                  <CheckIcon fontSize="small" className={classes.checkIcon} />
                </ClickableIcon>

                <ClickableIcon aria-label="cancel" onClick={onCancel}>
                  <CloseIcon fontSize="small" className={classes.close} />
                </ClickableIcon>
              </Box>
            )}
          </Box>
        </Cell>
      );
    }

    return <FormattedCell key={key} label={key} value={data[key] as string} disabled={false} isDeal={true} />;
  };

  const renderCells = () => cells.map(({ key }) => formatCell(key as keyof DealDataSchema, data, classes));

  return (
    <>
      <TableRow data-testid="datarow">
        <SmallCell>
          <Box display="flex" paddingLeft={6}>
            <CheckBox
              color="primary"
              onChange={selectRow}
              checked={Boolean(selected)}
              inputProps={{ "aria-label": "rowCheckBox" }}
            />
          </Box>
        </SmallCell>

        {renderCells()}

        <Cell>
          <Box display="flex" justifyContent="flex-end">
            <ClickableIcon aria-label="rowDetail" onClick={goToDetail} className={classes.visibilityIcon}>
              <Visibility fontSize="small" color="primary" />
            </ClickableIcon>
          </Box>
        </Cell>
      </TableRow>
    </>
  );
};

DataRow.propTypes = {
  data: PropTypes.any.isRequired,
  selectRow: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

export default DataRow;
