import { useState } from "react";
import { Box, makeStyles, TableRow } from "@material-ui/core";
import { Edit, RadioButtonChecked, Delete } from "@material-ui/icons";
import { useHistory } from "react-router";
import { Cell, ClickableIcon } from "../Common/";
import { types, strings } from "common";
import { cells } from "./cells";
import { FormattedCell } from "../Table";
import { roles, rolesList } from "../../constants";
import UserDeleteModal from "./UserDeleteModal";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { UserDataSchema } from "../../interfaces/user.interface";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
  },
  close: {
    color: theme.palette.error.main,
  },
  deleteIcon: {
    marginLeft: theme.spacing(4),
  },
}));

const formatCell = (
  key: keyof UserDataSchema,
  data: UserDataSchema,
  classes: ClassNameMap<"icon" | "close" | "deleteIcon">
) => {
  let value = data[key];

  if (key === "admin") {
    return (
      <Cell key={key}>
        <Box display="flex">
          {data.admin && <RadioButtonChecked color="secondary" data-testid="isAdmin" className={classes.icon} />}
        </Box>
      </Cell>
    );
  }

  if (key === "role") {
    value = roles[data[key] as keyof typeof roles];
  }

  if (key === "warehouseNames") {
    value = data[key].length > 0 ? data[key].join(", ") : data.role === rolesList.OPERATIONS ? strings.all : "-";
  }

  return <FormattedCell key={key} label={key} value={value as string} disabled={false} />;
};

const DataRow = ({ data }: { data: UserDataSchema }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isDeleting, setIsDeleting] = useState(false);

  const goToDetail = () => {
    history.push(`/users/details/${data.id}`);
  };

  const closeModal = () => {
    setIsDeleting(false);
  };

  return (
    <>
      <TableRow data-testid="datarow">
        {cells.map(({ key }) => formatCell(key as keyof UserDataSchema, data, classes))}
        <Cell>
          <Box display="flex" justifyContent="flex-end">
            <ClickableIcon data-testid="userDetail" aria-label="userDetail" onClick={goToDetail}>
              <Edit fontSize="small" color="primary" />
            </ClickableIcon>

            <ClickableIcon
              data-testid="deleteUser"
              aria-label="deleteUser"
              onClick={() => setIsDeleting(true)}
              className={classes.deleteIcon}
            >
              <Delete fontSize="small" color="error" />
            </ClickableIcon>
          </Box>
        </Cell>

        {isDeleting && (
          <UserDeleteModal
            isOpen={isDeleting}
            id={data.id}
            role={roles[data.role as keyof typeof roles] || "-"}
            name={data.name || "-"}
            closeModal={closeModal}
          />
        )}
      </TableRow>
    </>
  );
};

DataRow.propTypes = {
  data: types.users.isRequired,
};

export default DataRow;
