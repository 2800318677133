import { useState } from "react";
import { strings } from "common";
import { DataSchema, SelectedCaseSchema, SelectionSchema } from "../interfaces/table.interface";
import { SelectDataSchema } from "../interfaces/case.interface";

const useSelect = (data: SelectDataSchema[]) => {
  const [selectedCases, setSelectedCases] = useState<SelectedCaseSchema>({});
  const [selectedData, setSelectedData] = useState<DataSchema[]>([]);

  const selectRow = (row: DataSchema, from: string) => {
    if (from === strings.assignments) {
      setSelectedCases({
        [row.id]: selectedCases[row.id] ? null : true,
      });
    } else if (from === strings.discrepancy) {
      setSelectedCases({
        [row.issueId]: selectedCases[row.issueId] ? null : true,
      });
    } else {
      setSelectedCases({
        ...selectedCases,
        [row.id]: selectedCases[row.id] ? null : true,
      });
    }
    const index = selectedData.findIndex((item: { id: number }) => item.id === row.id);
    if (index === -1) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSelectedData((prevState: SelectedCaseSchema[]) => [...prevState, row]);
    } else {
      selectedData.splice(index, 1);
      setSelectedData(selectedData);
    }
  };

  const selectCase = (id: number) => {
    setSelectedCases({
      ...selectedCases,
      [id]: selectedCases[id] ? null : true,
    });
  };

  const selectAll = () => {
    if (data.length > 0) {
      setSelectedCases(
        data.reduce(
          (accum: SelectedCaseSchema, row: DataSchema) => ({
            ...accum,
            [row.id]: true,
          }),
          {}
        )
      );

      data.forEach((row) => {
        const index = selectedData.findIndex((item: { id: number }) => item.id === row.id);
        if (index === -1) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setSelectedData((prevState) => [...prevState, row]);
        }
      });
    }
  };

  const deSelectAll = () => {
    const currentSelectedData = [...selectedData];
    data.forEach((row) => {
      const index = currentSelectedData.findIndex((item: { id: number }) => item.id === row.id);
      if (index !== -1) {
        currentSelectedData.splice(index, 1);
      }
    });

    setSelectedData(currentSelectedData);
  };

  const isAllSelected = () => {
    if (data?.length > 0) {
      const map = selectedData.reduce((r: SelectionSchema, o) => ((r[o.id] = true), r), {});
      return data.every((o) => map[o.id as keyof typeof map]);
    }
    return false;
  };

  const hasSelections = () => {
    if (data?.length > 0) {
      const map = data.reduce((r: SelectionSchema, o) => ((r[o.id] = true), r), {});
      return selectedData.some((o) => map[o.id]);
    }
    return false;
  };

  return {
    hasSelections,
    selectRow,
    selectCase,
    selectedCases,
    selectAll,
    deSelectAll,
    isAllSelected,
    setSelectedCases,
    selectedData,
  };
};

export default useSelect;
