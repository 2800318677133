import { useMutation, useQuery, useQueryClient } from "react-query";
import bottle from "../assets/bottle.jpg";
import { useClient } from "../providers/auth-context";
import { CaseDataSchema, CaseDetailResponseSchema, CasePhotoSchema } from "../interfaces/case.interface";
import { ClientType } from "../interfaces/apiClient.interface";
import { QueryClient } from "react-query/core";
const getCasesDetailsConfig = (client: ClientType<CaseDetailResponseSchema>, id: string) => ({
  queryKey: ["casesDetails", { id }],
  queryFn: () => client(`cases/${id}/details`).then((data: CaseDetailResponseSchema) => data),
});

function useCasesDetailsSearch(id: string) {
  const client = useClient<CaseDetailResponseSchema>();
  const result = useQuery(getCasesDetailsConfig(client, id));

  const photos = result.data?.photos?.map((photo: CasePhotoSchema) => {
    const newPhoto: CasePhotoSchema = {
      id: photo.id,
      photoUrl: photo.photoUrl,
      main: photo.main,
      original: `${photo.photoUrl}-thumbnail`,
      thumbnail: `${photo.photoUrl}-thumbnail`,
      fullScreen: photo.photoUrl ?? "",
    };
    return newPhoto;
  });

  const defaultPhoto: Array<CasePhotoSchema> = [
    {
      original: bottle,
      thumbnail: bottle,
      fullScreen: bottle,
    },
  ];

  if (result.data) {
    const newData: CaseDetailResponseSchema = {
      ...result.data,
      photos: result.data?.photos
        ? result.data.photos?.length > 0
          ? (result.data.photos = photos as CasePhotoSchema[])
          : defaultPhoto
        : defaultPhoto,
    };

    return {
      isLoading: result.isLoading,
      data: newData,
    };
  } else {
    return {
      isLoading: result.isLoading,
      data: undefined,
    };
  }
}

const defaultMutationOptions = (queryClient: QueryClient) => ({
  onSettled: () => queryClient.invalidateQueries("casesDetails"),
});

function useUpdateCase() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    ({ caseId, ...params }: { caseId: string; param: Partial<CaseDataSchema> }) =>
      client(`cases/${caseId}/`, {
        method: "PATCH",
        body: JSON.stringify(params),
      }),
    {
      ...defaultMutationOptions(queryClient),
    }
  );
}

export { useCasesDetailsSearch, useUpdateCase };
