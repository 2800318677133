import { useState } from "react";
import { Box, makeStyles, Table, TableCell, TableRow, TableBody, Collapse } from "@material-ui/core";
import { ChevronRight, KeyboardArrowDown } from "@material-ui/icons";
import { cells } from "./preadviceCells";
import { CheckBox, SmallCell } from "../Common";
import { types } from "common";
import PropTypes from "prop-types";
import { FormattedCell } from "../Table";
import { StockModal } from "../StockDetail";

import { LightGreyRow } from "../Common";
import SalePricesLabelRow from "../DealsDetail/SalePricesLabelRow";
import SalesPricesDataRow from "../DealsDetail/SalesPricesDataRow";
import { PreadviceDataSchema } from "../../interfaces/order.interface";

const useStyles = makeStyles((theme) => ({
  borderlessRow: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  collapsableCell: {
    paddingBottom: 0,
    paddingTop: 0,
    backgroundColor: theme.palette.grey.A100,
  },
  collapseIcon: {
    paddingLeft: 8,
    color: theme.palette.text.secondary,
  },
  visibilityIcon: {
    marginRight: theme.spacing(6),
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}));

const formatCell = (key: keyof PreadviceDataSchema, data: PreadviceDataSchema) => {
  return <FormattedCell dense key={key} label={key} value={data[key] as string} disabled={false} />;
};

interface DataRowProps {
  data: PreadviceDataSchema;
  selectRow: () => void;
  selected: boolean;
}

const DataRow = ({ data, selectRow, selected }: DataRowProps) => {
  const classes = useStyles();
  const [casesInPreadvice] = useState(data.assetUnits);

  const [collapsed, setCollapsed] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const renderCells = () => cells.map(({ key }) => formatCell(key as keyof PreadviceDataSchema, data));

  return (
    <>
      <TableRow data-testid="datarow" className={classes.borderlessRow}>
        <SmallCell>
          <Box display="flex" paddingLeft={4}>
            {collapsed ? (
              <KeyboardArrowDown onClick={() => setCollapsed(false)} />
            ) : (
              <ChevronRight onClick={() => setCollapsed(true)} />
            )}
          </Box>
        </SmallCell>
        <SmallCell>
          <Box display="flex">
            <CheckBox
              color="primary"
              onChange={selectRow}
              checked={Boolean(selected)}
              inputProps={{ "aria-label": "rowCheckBox" }}
            />
          </Box>
        </SmallCell>

        {renderCells()}
      </TableRow>

      <TableRow>
        <TableCell colSpan={17} className={classes.collapsableCell}>
          <Collapse in={collapsed} timeout="auto" unmountOnExit>
            <Box>
              <Table>
                <SalePricesLabelRow />
                <TableBody>
                  {casesInPreadvice.map((row) => (
                    <LightGreyRow key={row.id}>
                      <SalesPricesDataRow data={row} />
                    </LightGreyRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>

        {isModalOpen && <StockModal open={isModalOpen} setModalOpen={setModalOpen} data={data} />}
      </TableRow>
    </>
  );
};

DataRow.propTypes = {
  data: types.stockDataShape,
  selectRow: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

export default DataRow;
