import { Box, makeStyles, Switch, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { constants, regexes, strings } from "common";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useState } from "react";
import * as Yup from "yup";
import { MainButton } from "../Common";
import { countries } from "../Orders/countries";
import ExtraInfo from "./ExtraInfo";
import { CreateSaleOrderDataSchema, DeliveryCountrySchema } from "../../interfaces/case.interface";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(0, 9),
  },
  inputs: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(8),
  },
  input: {
    width: "100%",
    marginRight: theme.spacing(8),
  },
  backButton: {
    marginRight: theme.spacing(10),
    background: "white",
    color: "grey",
  },
  greyText: {
    color: "grey",
  },
  label: {
    fontSize: "16px",
    color: "#1D4854",
    fontWeight: "bold",
  },
  errorMessage: {
    color: "#F44336",
  },
}));

const validation = Yup.object().shape({
  consigneeName: Yup.string().required(strings.required),
  consigneeAddress1: Yup.string().required(strings.required),
  consigneeTown: Yup.string().min(4, strings.value_too_short),
  consigneePostcode: Yup.string().required(strings.required).matches(regexes.basicPostalCode, strings.invalid_postcode),
  consigneeCountry: Yup.string()
    .required(strings.required)
    .matches(regexes.twoDigitCountryCode, strings.invalid_country_code),
});

interface CountrySchema {
  Code: string;
  Name: string;
}

interface CustomsProps {
  bondType: string;
  handleBack: React.MouseEventHandler<HTMLButtonElement>;
  handleNext: () => void;
  data: CreateSaleOrderDataSchema;
  setData: (val: object) => void;
  consigneeCountry?: CountrySchema;
  setConsigneeCountry: React.Dispatch<React.SetStateAction<DeliveryCountrySchema | undefined>>;
}

interface ValueSchema {
  consigneeName: string | undefined;
  consigneeAddress1: string | undefined;
  consigneeAddress2: string | undefined;
  consigneeAddress3: string | undefined;
  consigneeTown: string | undefined;
  consigneePostcode: string | undefined;
  consigneeCountry: string | undefined;
  payCustomsDuty: boolean;
}

const Customs = ({
  bondType,
  handleBack,
  handleNext,
  data,
  setData,
  consigneeCountry,
  setConsigneeCountry,
}: CustomsProps) => {
  const classes = useStyles();
  const [error, setError] = useState<string | null>(null);
  const isDutyPaid = strings.payCustomDutyBondTypes.includes(bondType);

  return (
    <Box className={classes.content}>
      <Formik
        initialValues={{
          consigneeName: data.consigneeName || (isDutyPaid ? data.deliveryName : ""),
          consigneeAddress1: data.consigneeAddress1 || (isDutyPaid ? data.deliveryAddress1 : ""),
          consigneeAddress2: data.consigneeAddress2 || (isDutyPaid ? data.deliveryAddress2 : ""),
          consigneeAddress3: data.consigneeAddress3 || (isDutyPaid ? data.deliveryAddress3 : ""),
          consigneeTown: data.consigneeTown || (isDutyPaid ? data.deliveryTownOrCounty : ""),
          consigneePostcode: data.consigneePostcode || (isDutyPaid ? data.deliveryPostcode : ""),
          consigneeCountry: data.consigneeCountry || (isDutyPaid ? data.deliveryCountry : consigneeCountry?.Code),
          payCustomsDuty:
            typeof data.payCustomsDuty === "undefined"
              ? strings.payCustomDutyBondTypes.includes(bondType)
              : data.payCustomsDuty,
        }}
        validationSchema={validation}
        onSubmit={async (values, { setSubmitting }) => {
          setData((prev: ValueSchema) => {
            return { ...prev, ...values };
          });
          try {
            setSubmitting(true);
            setError(null);
            handleNext();
          } catch (e) {
            setError(strings.wrong);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ values, handleChange, handleSubmit, errors, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className={classes.inputs}>
                <Switch
                  checked={values.payCustomsDuty}
                  disabled={strings.payCustomDutyDisabledBondTypes.includes(bondType)}
                  onChange={(_e, value) => setFieldValue("payCustomsDuty", value)}
                  size="small"
                />
                <Typography className={classes.greyText} variant="body1">
                  {strings.pay_customs_duty}
                </Typography>
              </div>
              <ExtraInfo data="Enter here the name and address of company or person buying the goods" />
              <div className={classes.inputs}>
                <TextField
                  className={classes.input}
                  label={strings.consignee_name}
                  name="consigneeName"
                  onChange={handleChange}
                  value={values.consigneeName}
                  error={Boolean(errors?.consigneeName)}
                  helperText={values?.consigneeName}
                  inputProps={{ maxLength: constants.visionEdiLimits.mediumInputLength }}
                  InputLabelProps={{
                    classes: { shrink: classes.label },
                  }}
                />
                <TextField
                  className={classes.input}
                  label={strings.consignee_address_1}
                  name="consigneeAddress1"
                  onChange={handleChange}
                  value={values.consigneeAddress1}
                  error={Boolean(errors?.consigneeAddress1)}
                  inputProps={{ maxLength: constants.visionEdiLimits.mediumInputLength }}
                  InputLabelProps={{
                    classes: { shrink: classes.label },
                  }}
                />
                <TextField
                  className={classes.input}
                  label={strings.consignee_address_2}
                  name="consigneeAddress2"
                  onChange={handleChange}
                  value={values.consigneeAddress2}
                  error={Boolean(errors?.consigneeAddress2)}
                  inputProps={{ maxLength: constants.visionEdiLimits.mediumInputLength }}
                  InputLabelProps={{
                    classes: { shrink: classes.label },
                  }}
                />
                <TextField
                  className={classes.input}
                  label={strings.consignee_address_3}
                  name="consigneeAddress3"
                  onChange={handleChange}
                  value={values.consigneeAddress3}
                  error={Boolean(errors?.consigneeAddress3)}
                  inputProps={{ maxLength: constants.visionEdiLimits.mediumInputLength }}
                  InputLabelProps={{
                    classes: { shrink: classes.label },
                  }}
                />
              </div>
              <div className={classes.inputs}>
                <TextField
                  className={classes.input}
                  label={strings.consignee_town}
                  name="consigneeTown"
                  onChange={handleChange}
                  value={values.consigneeTown}
                  error={Boolean(errors?.consigneeTown)}
                  helperText={values?.consigneeTown}
                  inputProps={{ maxLength: constants.visionEdiLimits.mediumInputLength }}
                  InputLabelProps={{
                    classes: { shrink: classes.label },
                  }}
                />
                <TextField
                  className={classes.input}
                  label={strings.consignee_postcode}
                  name="consigneePostcode"
                  onChange={handleChange}
                  value={values.consigneePostcode}
                  error={Boolean(errors?.consigneePostcode)}
                  helperText={values?.consigneePostcode}
                  inputProps={{ maxLength: constants.visionEdiLimits.shortInputLength }}
                  InputLabelProps={{
                    classes: { shrink: classes.label },
                  }}
                />
                <Autocomplete
                  id="consignee-country-dropdown"
                  value={countries.find((country) => country.Code === values.consigneeCountry)}
                  onChange={(_e, option) => {
                    setConsigneeCountry(option === null ? undefined : option);
                    setFieldValue("consigneeCountry", option?.Code);
                  }}
                  options={countries}
                  className={classes.input}
                  getOptionLabel={(option) => option.Name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={strings.consignee_country}
                      variant="outlined"
                      error={Boolean(errors?.consigneeCountry)}
                      helperText={values?.consigneeCountry}
                    />
                  )}
                />
              </div>
              <div>
                <MainButton className={classes.backButton} onClick={handleBack}>
                  {strings.back}
                </MainButton>
                <MainButton type="submit">{strings.next}</MainButton>
                <Typography className={classes.errorMessage}>{error}</Typography>
              </div>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};

Customs.propTypes = {
  data: PropTypes.object,
  bondType: PropTypes.string.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  setData: PropTypes.func,
  consigneeCountry: PropTypes.object,
  setConsigneeCountry: PropTypes.func,
};

export default Customs;
