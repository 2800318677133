import PropTypes from "prop-types";
import { Box, IconButton, Input, InputAdornment, makeStyles, Modal, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ModalButtons from "../Common/ModalButtons";
import { types, strings } from "common";
import { useUpdateCase } from "../../utils/casesDetails";
import * as Yup from "yup";
import { Formik } from "formik";
import { useQueryClient } from "react-query";
import { StockEditDataSchema } from "../../interfaces/case.interface";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    width: "600px",
    background: "white",
    outline: "none",
    padding: theme.spacing(12, 12, 12, 12),
  },
  modalHeader: {
    width: "100%",
    paddingBottom: theme.spacing(7),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  editRow: {
    marginTop: theme.spacing(5),
  },
  cancelButton: {
    marginLeft: theme.spacing(12),
  },
  closeIcon: {
    color: theme.palette.text.secondary,
  },
}));

const editCaseSchema = Yup.object().shape({
  supplierName: Yup.string().required(strings.required),
  purchasePrice: Yup.number().typeError(strings.number_error).required(strings.required),
  stockId: Yup.number().typeError(strings.number_error).required(strings.required),
  note: Yup.string().nullable(),
});

interface StockModalProps {
  data: StockEditDataSchema;
  open: boolean;
  setModalOpen: (val: boolean) => void;
}

const StockModal = ({ data, open, setModalOpen }: StockModalProps) => {
  const classes = useStyles();
  const { mutateAsync: updateStatus } = useUpdateCase();

  const onChange = (handleChange: (val: object) => void, setStatus: () => void) => (e: object) => {
    setStatus();
    handleChange(e);
  };

  const queryClient = useQueryClient();

  return (
    <Formik
      initialValues={{
        supplierName: data.supplierName,
        purchasePrice: data.cwPurchasePrice,
        stockId: data.stockId,
        note: data.note,
      }}
      validationSchema={editCaseSchema}
      onSubmit={async (values, { setSubmitting, setStatus }) => {
        setSubmitting(true);
        setStatus({});
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          await updateStatus({ note: values.note, caseId: data.id });
          setModalOpen(false);
        } catch (error) {
          setStatus({ error: strings.wrong });
        } finally {
          setSubmitting(false);
          queryClient.invalidateQueries("auditSearch");
          queryClient.refetchQueries("casesSearch");
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, status, setStatus, resetForm }) => (
        <Modal
          id="stockModal"
          open={open}
          className={classes.modal}
          data-testid="editCaseModal"
          aria-labelledby="edit case"
          aria-describedby="edit case modal"
        >
          <Box className={classes.modalContent}>
            <Box className={classes.modalHeader}>
              <Typography variant="h3">{strings.edit_case}</Typography>
              <IconButton onClick={() => setModalOpen(false)}>
                <CloseIcon id="closeStockModal" className={classes.closeIcon} />
              </IconButton>
            </Box>

            <form onSubmit={handleSubmit}>
              <Box className={classes.editRow}>
                <Typography color="primary" variant="caption">
                  {strings.supplier}
                </Typography>
                <Input
                  name="supplierName"
                  inputProps={{ "aria-label": "supplierName" }}
                  fullWidth
                  value={values.supplierName}
                  disabled
                  onChange={onChange(handleChange, setStatus)}
                />
              </Box>

              <Box className={classes.editRow}>
                <Typography color="primary" variant="caption">
                  {strings.purchase_price}
                </Typography>
                <Input
                  startAdornment={
                    <InputAdornment position="start">
                      <Typography color="textPrimary" variant="caption">
                        £
                      </Typography>
                    </InputAdornment>
                  }
                  disabled
                  inputProps={{ "aria-label": "purchasePrice" }}
                  name="purchasePrice"
                  fullWidth
                  value={values.purchasePrice}
                  onChange={onChange(handleChange, setStatus)}
                />

                <Typography color="error" variant="caption">
                  {Boolean(errors?.purchasePrice) && Boolean(touched?.purchasePrice)}
                </Typography>
              </Box>

              <Box className={classes.editRow}>
                <Typography color="primary" variant="caption">
                  {strings.stock_id}
                </Typography>
                <Input
                  name="stockId"
                  inputProps={{ "aria-label": "stockId" }}
                  fullWidth
                  value={values.stockId}
                  disabled
                  onChange={onChange(handleChange, setStatus)}
                />
                <Typography color="error" variant="caption">
                  {Boolean(errors.stockId) && Boolean(touched.stockId)}
                </Typography>
              </Box>

              <Box className={classes.editRow}>
                <Typography color="primary" variant="caption">
                  {strings.note}
                </Typography>
                <Input
                  multiline
                  name="note"
                  inputProps={{ "aria-label": "note" }}
                  fullWidth
                  value={values.note}
                  onChange={onChange(handleChange, setStatus)}
                />

                <Typography color="error" variant="caption">
                  {(status?.error && status.error) || errors.note}
                </Typography>
              </Box>

              <ModalButtons
                submitId="submitStockModal"
                cancelId="cancelStockModal"
                isSubmitting={isSubmitting}
                closeModal={() => {
                  resetForm();
                  setModalOpen(false);
                }}
              />
            </form>
          </Box>
        </Modal>
      )}
    </Formik>
  );
};

StockModal.propTypes = {
  data: types.stockDataShape,
  setModalOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default StockModal;
