import { Box, IconButton, makeStyles, Modal, Typography } from "@material-ui/core";
import { strings } from "common";
import { FormEvent, useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router";
import { useDeleteUser } from "../../utils/users";
import { LabelValue } from "../Common";
import ModalButtons from "../Common/ModalButtons";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    width: "600px",
    background: "white",
    outline: "none",
    padding: theme.spacing(12, 12, 12, 12),
  },
  modalHeader: {
    width: "100%",
    paddingBottom: theme.spacing(7),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  labels: {
    marginTop: theme.spacing(12),
  },
}));

interface UserDeleteModalProps {
  name: string;
  role: string;
  id: number;
  isOpen: boolean;
  closeModal: () => void;
}

const UserDeleteModal = ({ name, role, id, isOpen, closeModal }: UserDeleteModalProps) => {
  const { mutateAsync: deleteUser, isLoading: isLoadingDeleteUser } = useDeleteUser();
  const [error, setError] = useState(false);
  const history = useHistory();

  const classes = useStyles();

  const handleDeleteUser = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(false);

    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await deleteUser(id);
      closeModal();
      history.push("/users");
    } catch (error) {
      setError(true);
    }
  };

  const handleClose = () => {
    setError(false);
    closeModal();
  };

  return (
    <Modal
      id="userDeleteModal"
      open={isOpen}
      className={classes.modal}
      data-testid="delete-user-modal"
      aria-labelledby="delete user"
      aria-describedby="delete user modal"
    >
      <Box className={classes.modalContent}>
        <Box className={classes.modalHeader}>
          <Typography variant="h3">{strings.delete_user}</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon id="closeUserDeleteModal" />
          </IconButton>
        </Box>

        <Typography variant="body1">{strings.delete_user_message}</Typography>

        <Box className={classes.labels}>
          <LabelValue spacing={6} direction="row" label={strings.name} value={name} isTransaction={false} />
          <LabelValue spacing={10} direction="row" label={strings.role} value={role} isTransaction={false} />
        </Box>

        <form onSubmit={handleDeleteUser}>
          <ModalButtons
            submitId="submitUserDeleteModal"
            cancelId="cancelUserDeleteModal"
            actionText={strings.delete}
            isSubmitting={isLoadingDeleteUser}
            closeModal={handleClose}
          />
        </form>
        {error && (
          <Box marginTop={4}>
            <Typography color={"error"}>{strings.wrong}</Typography>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

UserDeleteModal.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  id: PropTypes.number,
  setOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default UserDeleteModal;
