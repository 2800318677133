import { menuOptions } from "../constants";

function getCurrentRoute() {
  const url = window.location.href;
  let route = menuOptions.Stock.route;

  for (const option of Object.keys(menuOptions)) {
    if (url.includes(option.toLowerCase())) {
      route = menuOptions[option as keyof typeof menuOptions].route;
    }
  }

  return route;
}

export { getCurrentRoute };
