import { useState } from "react";
import debounce from "lodash.debounce";
import { InitQuerySchema, TableCellSchema } from "../interfaces/table.interface";

const useFields = (cells: Array<TableCellSchema>, initSearch?: InitQuerySchema | undefined) => {
  const initialState = cells.reduce((accum, { key }: { key: string }) => {
    if (initSearch && Object.keys(initSearch).includes(key)) {
      return {
        ...accum,
        [key]: initSearch["status"],
      };
    }

    return {
      ...accum,
      [key]: "",
    };
  }, {});

  const [fields, setFieldDs] = useState(initialState);

  const setFieldD = debounce((key: string, value: string) => {
    setFieldDs({
      ...fields,
      [key]: value,
    });
  }, 500);

  const setField = (key: string, value: string) => {
    setFieldDs({
      ...fields,
      [key]: value,
    });
  };

  const clearFields = () => setFieldDs(initialState);

  return [fields, setFieldD, setField, clearFields];
};

export default useFields;
