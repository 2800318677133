import PropTypes from "prop-types";
import { AssignmentCell, Cell, SmallCell } from "../Common";
import { makeStyles, Typography } from "@material-ui/core";
import { datetimeFormat, filtersToParams } from "../../constants";
import { DateTime } from "luxon";
import { isValidDate } from "../../utils/date";

const useStyles = makeStyles(() => ({
  cell: {
    overflowWrap: "anywhere",
  },
  text: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  assignment_text: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

interface FormattedCellProps {
  label: string;
  value: string;
  disabled: boolean;
  dense: boolean;
  isAssignment: boolean;
  isDeal: boolean;
}

const FormattedCell = ({ label, value, disabled, dense, isAssignment, isDeal }: FormattedCellProps) => {
  const classes = useStyles();

  const CellToRender = isAssignment ? AssignmentCell : dense ? SmallCell : Cell;

  if (label === "status" || (label === "type" && !isDeal)) {
    return (
      <CellToRender key={label}>
        <Typography variant="body1" color={!disabled ? "textPrimary" : "textSecondary"}>
          {filtersToParams[value as keyof typeof filtersToParams]}
        </Typography>
      </CellToRender>
    );
  }

  if (isValidDate(value)) {
    return (
      <CellToRender key={label}>
        <Typography variant="body1" color={!disabled ? "textPrimary" : "textSecondary"}>{`${DateTime.fromISO(
          value
        ).toFormat(datetimeFormat)}`}</Typography>
      </CellToRender>
    );
  }

  return (
    <CellToRender key={label} className={classes.cell}>
      <Typography
        className={isAssignment ? `${classes.assignment_text}` : `${classes.text}`}
        variant="body1"
        color={!disabled ? "textPrimary" : "textSecondary"}
      >{`${value || "-"}`}</Typography>
    </CellToRender>
  );
};

FormattedCell.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  dense: PropTypes.bool,
  isAssignment: PropTypes.bool,
  isDeal: PropTypes.bool,
};

FormattedCell.defaultProps = {
  dense: false,
  isAssignment: false,
  isDeal: false,
};

export default FormattedCell;
