import { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { ChevronRight, KeyboardArrowDown } from "@material-ui/icons";
import { cells } from "./historyCells";
import { Cell, ClickableIcon, DarkGreyRow, LightGreyRow } from "../Common/";
import { types, strings } from "common";
import { FormattedCell } from "../Table";
import { entityTypesList, filtersToParams } from "../../constants";
import { HistoryDataSchema } from "../../interfaces/case.interface";

const useStyles = makeStyles((theme) => ({
  border: {
    "& > *": {
      borderColor: theme.palette.grey[300], // does not look right
    },
  },
  chevron: {
    color: theme.palette.text.secondary,
  },
}));

const formatCell = (key: keyof HistoryDataSchema, data: HistoryDataSchema) => {
  let value = data[key];

  if (key === "entity" || key === "action") {
    value = filtersToParams[data[key] as keyof typeof filtersToParams];

    if (data[key] === entityTypesList.ISSUES || data[key] === entityTypesList.ISSUE_COMMENTS) {
      value = `${strings.ticket} (#${data.auxiliarEntityId})`;
    }
  }

  return <FormattedCell key={key} label={key} value={value as string} disabled={false} />;
};

const DataRow = ({ data }: { data: HistoryDataSchema }) => {
  const classes = useStyles();

  const [collapsed, setCollapsed] = useState(false);

  const renderCells = () => cells.map(({ key }) => formatCell(key as keyof HistoryDataSchema, data));

  return (
    <>
      <LightGreyRow data-testid="datarow">
        <Cell>
          {data.grouped && (
            <ClickableIcon aria-label="expandRow" onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? (
                <ChevronRight className={classes.chevron} />
              ) : (
                <KeyboardArrowDown className={classes.chevron} />
              )}
            </ClickableIcon>
          )}
        </Cell>

        {renderCells()}
        <Cell />
      </LightGreyRow>

      {collapsed &&
        data.grouped &&
        data.grouped.map((changeRow) => (
          <DarkGreyRow key={changeRow.change} className={classes.border}>
            <Cell colSpan={3}></Cell>

            <FormattedCell label={"change"} value={changeRow.change} disabled={false} />
            <FormattedCell label={"content"} value={changeRow.content} disabled={false} />
            <Cell colSpan={2}></Cell>
          </DarkGreyRow>
        ))}
    </>
  );
};

DataRow.propTypes = {
  data: types.stockDataShape,
};

export default DataRow;
