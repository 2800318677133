import React from "react";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import PowerIcon from "@material-ui/icons/PowerSettingsNew";
import NotificationsIcon from "@material-ui/icons/Notifications";
import logo from "../../assets/Logo.svg";
import Drawer, { drawerWidth, drawerWidthClosed } from "./Drawer";
import PropTypes from "prop-types";
import { Box, Grid, makeStyles } from "@material-ui/core";
import NotificationOverlay from "../Notifications/NotificationOverlay";
import { useQueryClient } from "react-query";
import { useNotificationsSearch } from "../../utils/notifications";
import { useLogout } from "../../utils/users";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(12),
    marginLeft: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
    borderBottom: `${theme.spacing(1)}px solid ${theme.palette.grey.A200}`,
    padding: 0,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  gutters: {
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
  },
  content: {
    marginLeft: drawerWidthClosed,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    height: "100%",
  },
  container: {
    paddingRight: theme.spacing(2),
    display: "flex",
    flexFlow: "column",
    background: "white",
    height: "100vh", // FIXME: this should take into account the header
  },
  shiftContent: {
    marginLeft: drawerWidth,
  },
  notificationIcon: {
    marginRight: theme.spacing(12),
    position: "relative",
  },
  redBadge: {
    position: "absolute",
    backgroundColor: "red",
    height: 10,
    width: 10,
    right: 14,
    top: 11,
    borderRadius: "50%",
  },
}));

interface LayoutProps {
  children?: Array<React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>>;
  selectedOption: string;
  setOption: (val: string) => void;
}

const Layout = ({ children, selectedOption, setOption }: LayoutProps) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const queryClient = useQueryClient();
  const { mutateAsync: logout } = useLogout();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    queryClient.invalidateQueries("myUser");
    await logout();
    window.location.reload();
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  const { data } = useNotificationsSearch();

  // TODO: how do we deal with pagination
  const hasUnseenNotifications = data.data.some((item) => !item.acknowledged);

  return (
    <Box className={classes.container}>
      <AppBar color="inherit" position="sticky" className={clsx(classes.appBar, { [classes.appBarShift]: open })}>
        <Toolbar classes={{ gutters: classes.gutters }}>
          <IconButton
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
            color="primary"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>

          <Grid justifyContent="space-between" container>
            <Grid container justifyContent="flex-start" item xs={6}>
              <img src={logo} alt="logo" />
            </Grid>

            <Grid container justifyContent="flex-end" alignItems="center" item xs={6}>
              <IconButton aria-label="notifications" onClick={handleClick} className={classes.notificationIcon}>
                <NotificationsIcon color="primary" />
                {hasUnseenNotifications && <Box className={classes.redBadge}></Box>}
              </IconButton>

              <IconButton onClick={handleLogout}>
                <PowerIcon color="error" />
              </IconButton>
            </Grid>
          </Grid>

          <NotificationOverlay anchorEl={anchorEl} openPopover={openPopover} handleClose={handleClose} id={id} />
        </Toolbar>
      </AppBar>

      <Drawer open={open} handleDrawerClose={handleDrawerClose} selectedOption={selectedOption} setOption={setOption} />
      <main
        className={clsx(classes.content, {
          [classes.shiftContent]: open,
        })}
      >
        {children}
      </main>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  selectedOption: PropTypes.string,
  setOption: PropTypes.func.isRequired,
};

export default Layout;
