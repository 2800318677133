import { strings } from "common";
import { DashboardCellSchema } from "../../interfaces/dashboard.interface";

const warehouseCell: DashboardCellSchema = {
  width: "20%",
  text: strings.warehouse,
  key: "warehouseShortCode",
  isCurrency: false,
};

const supplierCell: DashboardCellSchema = {
  width: "35%",
  text: strings.supplier,
  key: "supplierName",
  isCurrency: false,
};

const stockIdCell: DashboardCellSchema = {
  width: "20%",
  text: strings.stock_id,
  key: "stockId",
  isCurrency: false,
};

const bookDateCell: DashboardCellSchema = {
  width: "20%",
  text: strings.booked_date,
  key: "bookedDate",
  isCurrency: false,
};

const dealNumberCell: DashboardCellSchema = {
  width: "15%",
  text: strings.short_deal_number,
  key: "dealNumber",
  isCurrency: false,
};

const clientNameCell: DashboardCellSchema = {
  width: "35%",
  text: strings.client,
  key: "clientName",
  isCurrency: false,
};

const typeCell: DashboardCellSchema = {
  width: "20%",
  text: strings.deal_transaction_type,
  key: "type",
  isCurrency: false,
};

const portfolioManagerNameCell: DashboardCellSchema = {
  width: "25%",
  text: strings.pm,
  key: "portfolioManagerName",
  isCurrency: false,
};

const idCell: DashboardCellSchema = {
  width: "10%",
  text: strings.id,
  key: "id",
  isCurrency: false,
};

const rotationNumberCell: DashboardCellSchema = {
  width: "20%",
  text: strings.rotation_uid,
  key: "rotationNumber",
  isCurrency: false,
};

const authorNameCell: DashboardCellSchema = {
  width: "30%",
  text: strings.author,
  key: "authorName",
  isCurrency: false,
};

const casesCells = [warehouseCell, supplierCell, stockIdCell, bookDateCell];

const dealsCells = [dealNumberCell, clientNameCell, typeCell, portfolioManagerNameCell];

const ticketsCells = [idCell, typeCell, rotationNumberCell, authorNameCell];

const emptyLastCell = {
  lastCell: {
    key: "",
    width: "5%",
  },
};

const emptyFirstCell = {
  firstCell: {
    key: "warehouseShortCode",
    width: "5%",
  },
};

const stockStatusGroupCells = [
  {
    colSpan: 1,
    text: strings.warehouse,
  },
  {
    colSpan: 3,
    text: strings.cost_in_pounds,
  },
  {
    colSpan: 3,
    text: strings.no_units,
  },
];

const costCurrentCell: DashboardCellSchema = {
  text: strings.current,
  key: "cost.current",
  width: "20%",
  isCurrency: true,
};
const costDueCell: DashboardCellSchema = {
  text: strings.due,
  key: "cost.due",
  width: "20%",
  isCurrency: true,
};
const costTotalCell: DashboardCellSchema = {
  text: strings.total,
  key: "cost.total",
  width: "20%",
  isCurrency: true,
};
const unitCurrentCell: DashboardCellSchema = {
  text: strings.current,
  key: "units.current",
  width: "20%",
  isCurrency: false,
};
const unitDueCell: DashboardCellSchema = {
  text: strings.due,
  key: "units.due",
  width: "20%",
  isCurrency: false,
};
const unitTotalCell: DashboardCellSchema = {
  text: strings.total,
  key: "units.total",
  width: "20%",
  isCurrency: false,
};

const stockStatusCells = [costCurrentCell, costDueCell, costTotalCell, unitCurrentCell, unitDueCell, unitTotalCell];

export { casesCells, dealsCells, ticketsCells, emptyLastCell, emptyFirstCell, stockStatusCells, stockStatusGroupCells };
