import { Box, CircularProgress, Grid, makeStyles, Typography } from "@material-ui/core";
import { strings } from "common";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { DashboardSummaryTable } from "../components/Dashboard";
import {
  casesCells,
  dealsCells,
  emptyFirstCell,
  emptyLastCell,
  stockStatusCells,
  stockStatusGroupCells,
  ticketsCells,
} from "../components/Dashboard/cells";
import { casesStatusList, dealsStatusList, issueStatusList } from "../constants";
import { useDealsForDashboard } from "../utils/deals";

const useStyles = makeStyles((theme) => ({
  gridRowSpacing: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  gridLeftBox: {
    paddingRight: theme.spacing(4),
    height: "100%",
  },
  gridRightBox: {
    paddingLeft: theme.spacing(4),
    height: "100%",
  },
  spinner: {
    display: "flex",
    marginLeft: theme.spacing(4),
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const switchScreen = (action: string, id?: number) => {
    switch (action) {
      case strings.inbound_title:
        id
          ? history.push(`/stock/${id}`)
          : history.push({
              pathname: "/stock/",
              state: { status: casesStatusList.IN_TRANSIT },
            });
        break;
      case strings.ticket_title:
        id
          ? history.push(`/tickets/${id}`)
          : history.push({
              pathname: "/tickets/",
              state: { status: issueStatusList.OPEN },
            });
        break;
      case strings.deal_title:
        id
          ? history.push(`/deals/${id}`)
          : history.push({
              pathname: "/deals/",
              state: { status: dealsStatusList.IN_PROGRESS },
            });
        break;
      case strings.ouverdue_title:
        id
          ? history.push(`/stock/${id}`)
          : history.push({
              pathname: "/stock/",
              state: { overdue: true },
            });
        break;
      default:
        break;
    }
  };

  // const { data: inboundCases } = useCasesForDashboard("inbound");
  const { data: openDeals, isLoading: openDealsLoading } = useDealsForDashboard(dealsStatusList.IN_PROGRESS);

  // const { data: openTickets, isLoading: openTicketsLoading } = useIssuesForDashboard(issueStatusList.OPEN);

  // const { data: stockStatus, isLoading: stockStatusLoading } = useCasesStatus();

  // const { data: overdueCases } = useCasesForDashboard("overdue");

  // const currentData = stockStatus.map(({ cost }) => String(cost.current));
  // const dueData = stockStatus.map(({ cost }) => String(cost.due));
  const stockStatus = [] as any;
  const inboundCases = [] as any;
  const openTickets = [] as any;
  // const currentData = [] as any;
  // const dueData = [] as any;
  const overdueCases = [] as any;
  const openTicketsLoading = false;
  const stockStatusLoading = false;
  // const datasets = [
  //   {
  //     label: strings.current,
  //     data: currentData,
  //     backgroundColor: theme.palette.primary.dark,
  //     stack: "1",
  //   },
  //   {
  //     label: strings.due,
  //     data: dueData,
  //     backgroundColor: theme.palette.primary.light,
  //     stack: "1",
  //   },
  // ];

  const isLoading = openDealsLoading || openTicketsLoading || stockStatusLoading;

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4">{strings.dashboard}</Typography>

        {isLoading && (
          <Box data-testid="loading" className={classes.spinner}>
            <CircularProgress size={20} />
          </Box>
        )}
      </Box>

      {!isLoading && (
        <Grid container>
          <Grid container item xs={12} className={classes.gridRowSpacing}>
            <Grid item xs={8} className={classes.gridLeftBox}>
              <DashboardSummaryTable
                title={strings.stock_status_title}
                data={stockStatus}
                cells={stockStatusCells}
                emptyCells={emptyFirstCell}
                groupCells={stockStatusGroupCells}
                hasNestedValues={true}
                hasScroll={true}
                hasSeeAllButton={false}
              ></DashboardSummaryTable>
            </Grid>
            <Grid item xs={4} className={classes.gridRightBox} style={{ minHeight: "350px", maxHeight: "400px" }}>
              {/* <StackedBar
                title={strings.chart_title}
                labels={stockStatus.map(({ warehouseShortCode }) => warehouseShortCode)}
                datasets={datasets}
              ></StackedBar> */}
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.gridRowSpacing}>
            <Grid item xs={6} className={classes.gridLeftBox}>
              <DashboardSummaryTable
                title={strings.inbound_title}
                data={inboundCases}
                cells={casesCells}
                emptyCells={emptyLastCell}
                hasSeeAllButton={true}
                hasScroll={false}
                onClickParent={switchScreen}
                hasNestedValues={false}
              ></DashboardSummaryTable>
            </Grid>
            <Grid item xs={6} className={classes.gridRightBox}>
              <DashboardSummaryTable
                title={strings.ouverdue_title}
                data={overdueCases}
                cells={casesCells}
                emptyCells={emptyLastCell}
                hasSeeAllButton={true}
                hasScroll={false}
                onClickParent={switchScreen}
                hasNestedValues={false}
              ></DashboardSummaryTable>
            </Grid>
          </Grid>
          <Grid container item xs={12} className={classes.gridRowSpacing}>
            <Grid item xs={6} className={classes.gridLeftBox}>
              <DashboardSummaryTable
                title={strings.deal_title}
                data={openDeals}
                cells={dealsCells}
                emptyCells={emptyLastCell}
                onClickParent={switchScreen}
                hasSeeAllButton={true}
                hasScroll={false}
                hasNestedValues={false}
              ></DashboardSummaryTable>
            </Grid>
            <Grid item xs={6} className={classes.gridRightBox}>
              <DashboardSummaryTable
                title={strings.ticket_title}
                data={openTickets}
                cells={ticketsCells}
                emptyCells={emptyLastCell}
                onClickParent={switchScreen}
                hasSeeAllButton={true}
                hasScroll={false}
                hasNestedValues={false}
              ></DashboardSummaryTable>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Dashboard;
