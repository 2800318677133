import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { LabelValue, MainButton } from "../Common";
import { types, strings } from "common";
import { IssueDetailsRelatedCaseSchema } from "../../interfaces/ticket.interface";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.grey.A100,
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
  },
  vintage: {
    paddingLeft: theme.spacing(2),
  },
  text: {
    marginTop: theme.spacing(4),
  },
  button: {
    paddingTop: 0,
    paddingBottom: 0,
    height: theme.spacing(10),
  },
}));

interface RelatedCaseProps {
  data: IssueDetailsRelatedCaseSchema;
  goToCase: (val: number) => void;
}

const RelatedCase = ({ data, goToCase }: RelatedCaseProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          <Typography variant="body2">{data.wineName}</Typography>
          <Typography variant="body1" className={classes.vintage}>
            {data.vintage}
          </Typography>
        </Box>

        <MainButton data-testid="goToCase" onClick={() => goToCase(data.id)} className={classes.button}>
          {strings.go_case}
        </MainButton>
      </Box>

      <Grid container direction="row">
        <Grid item>
          <Box display="flex" flexDirection="column">
            <Typography variant="body1" className={classes.text}>
              {data.hash}
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <LabelValue direction="row" label={strings.supplier} value={data.supplierName || "-"} />
              </Grid>
              <Grid item xs={6}>
                <LabelValue direction="row" label={strings.size} value={data.size || "-"} />
              </Grid>
              <Grid item xs={6}>
                <LabelValue label={strings.lwin} value={data.lwin} direction="row" />
              </Grid>
              <Grid item xs={6}>
                <LabelValue direction="row" label={strings.stock_id} value={data.stockId || "-"} />
              </Grid>
              <Grid item xs={6}>
                <LabelValue direction="row" label={strings.rotation} value={data.rotationNumber || "-"} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

RelatedCase.propTypes = {
  data: types.ticketDataShape,
  goToCase: PropTypes.func.isRequired,
};

export default RelatedCase;
