import { useEffect, useState } from "react";
import createActivityDetector from "activity-detector";
import { useRefresh } from "../../utils/users";

// calls refresh endpoint every REFRESH_INTERVAL minutes
// TODO: should we set this (200 minus some delta)?
const REFRESH_INTERVAL = 30;

const RefreshToken = () => {
  const [wasActive, setWasActive] = useState(false);
  const [timer, setTimer] = useState(0);
  const { mutateAsync: refresh } = useRefresh();
  const activityDetector = createActivityDetector();

  useEffect(() => {
    refresh();

    const interval = setInterval(() => {
      setTimer((t) => t + 1);
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    activityDetector.on("active", () => {
      setWasActive(true);
    });

    return () => activityDetector.stop();
  }, []);

  useEffect(() => {
    if (timer >= REFRESH_INTERVAL) {
      activityDetector.stop();
      if (wasActive) {
        refresh();
      }
      setWasActive(false);
      setTimer(0);
    }
  }, [timer]);

  return <></>;
};

export default RefreshToken;
