import { useState } from "react";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps, Color } from "@material-ui/lab/Alert";
import { strings } from "common";

function Alert(props: JSX.IntrinsicAttributes & AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface SnackAlertProps {
  message: string;
  severity: Color | undefined;
}

const SnackAlert = ({ message, severity }: SnackAlertProps) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

SnackAlert.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.string,
};

SnackAlert.defaultProps = {
  message: strings.success,
  severity: "success",
};

export default SnackAlert;
