import strings from './strings';

export const orderDateFormat = 'dd/MM/yyyy';
export const dateFormat = 'dd-LL-yyyy';
export const joiDateFormat = 'yyyy-LL-dd';
export const timeFormat = 'HH:mm';
export const datetimeFormat = `${dateFormat}, HH:mm`;

export const rowsPerPageOptions = [10, 20, 30, 50, 100];

export const casesStatusList = {
  IN_TRANSIT: 'IN_TRANSIT',
  IN_STOCK: 'IN_STOCK',
  CREATED: 'CREATED',
  PENDING_TRANSPORT: 'PENDING_TRANSPORT',
  PENDING_RELEASE: 'PENDING_RELEASE',
  RELEASE_PROCESSED: 'RELEASE_PROCESSED',
  DEPARTED: 'DEPARTED',
  TICKETED: 'TICKETED',
  INTERIM: 'INTERIM',
};

export const issueStatusList = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  PENDING: 'PENDING',
};

export const dealTypeList = {
  RELEASE: 'RELEASE',
  TRADE: 'TRADE',
};

export const dealsStatusList = {
  IN_PROGRESS: 'IN_PROGRESS',
  CLOSED: 'CLOSED',
};

export const preadviceStatusList = {
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESSFUL: 'SUCCESSFUL',
  UNSUCCESSFUL: 'UNSUCCESSFUL',
  CANCELLED: 'CANCELLED',
};

export const issueStatus = {
  [issueStatusList.OPEN]: strings.open,
  [issueStatusList.CLOSED]: strings.closed,
  [issueStatusList.PENDING]: strings.pending,
};

export const dealsStatus = {
  IN_PROGRESS: strings.in_progress,
  CLOSED: strings.closed,
};

export const caseStatus = {
  [casesStatusList.IN_TRANSIT]: strings.in_transit,
  [casesStatusList.IN_STOCK]: strings.in_stock,
  [casesStatusList.CREATED]: strings.create,
  [casesStatusList.PENDING_RELEASE]: strings.pending_release,
  [casesStatusList.RELEASE_PROCESSED]: strings.release_processed,
  [casesStatusList.DEPARTED]: strings.departed,
  [casesStatusList.INTERIM]: strings.interim,
};

export const preadviceStatuses = {
  [preadviceStatusList.IN_PROGRESS]: strings.in_progress,
  [preadviceStatusList.SUCCESSFUL]: strings.successful,
  [preadviceStatusList.UNSUCCESSFUL]: strings.unsuccessful,
};

export const issueType = {
  QUERY: strings.query,
  DISCREPANCY: strings.discrepancy,
};

export const salesType = {
  [dealTypeList.RELEASE]: strings.release,
  [dealTypeList.TRADE]: strings.trade,
};

export const entityTypesList = {
  CASES: 'CASES',
  ISSUES: 'ISSUES',
  ISSUE_COMMENTS: 'ISSUE_COMMENTS',
  DEALS: 'DEALS',
  SALES_ORDER: 'SALES_ORDER',
  PREADVICES: 'PREADVICES',
};

export const actionTypesList = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
};

export const actionTypes = {
  [actionTypesList.CREATE]: strings.create,
  [actionTypesList.UPDATE]: strings.update,
  [actionTypesList.DELETE]: strings.deleted,
};

export const entityTypes = {
  [entityTypesList.CASES]: strings.cases,
  [entityTypesList.ISSUES]: strings.issues,
  [entityTypesList.ISSUE_COMMENTS]: strings.issue_comments,
  [entityTypesList.DEALS]: strings.deals,
  [entityTypesList.PREADVICES]: strings.pre_advice,
  [entityTypesList.SALES_ORDER]: strings.sales_order,
};

export const filtersToParams = {
  ...issueType,
  ...issueStatus,
  ...caseStatus,
  ...dealsStatus,
  ...preadviceStatuses,
  ...salesType,
  ...entityTypes,
  ...actionTypes,
};

export const boolToString = {
  true: strings.yes,
  false: strings.no,
};

export const boolToStringForRelation = {
  true: strings.linked,
  false: strings.orphanedAssetRecord,
};

export const numberOfListRows = 10;

export const paramsToStrings = {
  wineName: strings.wine,
  vintage: strings.vintage,
  supplierName: strings.supplier,
  authorName: strings.author,
  rotationNumber: strings.rotation,
  lwin: strings.lwin,
  stockId: strings.stock_id,
  status: strings.status,
  type: strings.type,
};

export const roles = {
  OPERATIONS: strings.Operations,
  WAREHOUSING: strings.warehousing,
  ADMIN_WAREHOUSING: strings.admin_warehousing,
  ADMIN: strings.admin,
};

export const rolesList = {
  OPERATIONS: 'OPERATIONS',
  WAREHOUSING: 'WAREHOUSING',
  ADMIN_WAREHOUSING: 'ADMIN_WAREHOUSING',
  ADMIN: 'ADMIN',
};

export const httpCodes = {
  OK: 200,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  INTERNAL_SERVER: 500,
};

export const transactionTypesList = {
  CRATE_CREATION: 'CRATE_CREATION',
  PROOF_OF_OWNERSHIP: 'PROOF_OF_OWNERSHIP',
  CRATE_LOCATION_CHANGE: 'CRATE_LOCATION_CHANGE',
  OPT_IN_TO_CRATE: 'OPT_IN_TO_CRATE',
  CRATE_OWNERSHIP_TRANSFER: 'CRATE_OWNERSHIP_TRANSFER',
};

export const transactionTypes = {
  [transactionTypesList.CRATE_CREATION]: strings.crate_creation,
  [transactionTypesList.PROOF_OF_OWNERSHIP]: strings.proof_ownership,
  [transactionTypesList.CRATE_LOCATION_CHANGE]: strings.crate_location_change,
  [transactionTypesList.OPT_IN_TO_CRATE]: strings.opt_in_crate,
  [transactionTypesList.CRATE_OWNERSHIP_TRANSFER]: strings.crate_ownership_transfer,
};

export const ALLOW_SCAN_STATUS = [
  casesStatusList.IN_STOCK,
  casesStatusList.PENDING_TRANSPORT,
  casesStatusList.PENDING_RELEASE,
  casesStatusList.RELEASE_PROCESSED,
  casesStatusList.DEPARTED,
  casesStatusList.TICKETED,
  casesStatusList.INTERIM,
];

export const visionEdiLimits = {
  shortInputLength: 15,
  shortMediumInputLength: 30,
  mediumInputLength: 50,
  maxInputLength: 120,
};
