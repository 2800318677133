import { useAuditSearch } from "../../utils/audit";
import { cells, emptyCells } from "./historyCells";
import DataRow from "./historyDataRow";
import { useParams } from "react-router";
import TableSkeleton from "../Table/Skeleton";

const HistoryTable = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <TableSkeleton
      query={useAuditSearch}
      filterValues={[]}
      queryParams={{ entityId: id }}
      cells={cells}
      emptyCells={emptyCells}
      filterParam={"status"}
      firstColspan={1}
      DataRow={DataRow}
    ></TableSkeleton>
  );
};

export default HistoryTable;
