import { strings } from "common";
import { TableCellSchema } from "../../interfaces/table.interface";

export const cellsWithDealNumber: TableCellSchema[] = [
  {
    width: "8%",
    text: strings.deal_number,
    key: "dealNumber",
  },
  {
    width: "10%",
    text: strings.warehouse,
    key: "warehouseShortCode",
  },
  {
    width: "8%",
    text: strings.lwin,
    key: "lwin",
  },
  {
    width: "13%",
    text: strings.wine,
    key: "wineName",
  },
  {
    width: "8%",
    text: strings.vintage,
    key: "vintage",
  },
  {
    width: "8%",
    text: strings.size,
    key: "unitSize",
  },
  {
    width: "8%",
    text: strings.stock_id,
    key: "stockId",
  },
  {
    width: "8%",
    text: strings.rotation,
    key: "rotationNumber",
  },
  {
    width: "8%",
    text: strings.prch_price,
    key: "purchasePrice",
  },
  {
    width: "8%",
    text: strings.sale_price,
    key: "paidDate",
  },
  {
    width: "8%",
    text: strings.departed_date,
    key: "departedDate",
    datePicker: true,
  },
  {
    width: "10%",
    text: strings.status,
    key: "status",
  },
  {
    width: "8%",
    text: strings.asset_record,
    key: "assetUnitId",
  },
  {
    width: "8%",
    text: strings.physical_record,
    key: "physicalRecord",
  },
  {
    width: "8%",
    text: strings.relationship,
    key: "linkedToPhysical",
    selectOptions: [
      { key: strings.linked, value: true },
      { key: strings.orphanedAssetRecord, value: false },
    ],
  },
  {
    width: "8%",
    text: strings.tradeable,
    key: "tradeable",
    selectOptions: [
      { key: strings.yes, value: true },
      { key: strings.no, value: false },
    ],
  },
];

export const emptyCells = {
  firstCell: {
    width: "3%",
  },
  lastCell: {
    width: "3%",
  },
};
