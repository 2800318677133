import { Box, makeStyles, Typography } from "@material-ui/core";
import { DateTime } from "luxon";
import { types } from "common";
import { datetimeFormat } from "../../constants";

const useStyles = makeStyles((theme) => ({
  text: {
    marginTop: theme.spacing(6),
  },
  commentList: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(12),
  },
  date: {
    paddingLeft: theme.spacing(8),
  },
}));

interface CommentListSchema {
  createdAt: string;
  authorName: string;
  comment: string;
}

const CommentList = ({ comment }: { comment: CommentListSchema }) => {
  const classes = useStyles();

  const commentDate = DateTime.fromISO(comment.createdAt).toFormat(datetimeFormat);

  return (
    <Box className={classes.commentList}>
      <Box display="flex">
        <Typography variant="subtitle2">{comment.authorName}</Typography>
        <Typography className={classes.date} variant="subtitle1">
          {commentDate}
        </Typography>
      </Box>

      <Typography className={classes.text}>{comment.comment}</Typography>
    </Box>
  );
};

CommentList.propTypes = {
  comment: types.ticketCommentShape,
};

export default CommentList;
