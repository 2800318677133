import { Box, InputAdornment, TextField, Typography } from "@material-ui/core";
import { types, strings } from "common";
import { Cell } from "../Common";
import PropTypes from "prop-types";
import { formatCurrency } from "../../utils/currency";
import { CaseDataSchema } from "../../interfaces/case.interface";

interface SalesPricesDataRowProps {
  editablePrice?: boolean;
  data: CaseDataSchema;
  handleChange?: () => void;
  salePrice?: string;
  error?: boolean;
  showSalePrice?: boolean;
}

const SalesPricesDataRow = ({
  editablePrice,
  data,
  handleChange,
  salePrice,
  error,
  showSalePrice,
}: SalesPricesDataRowProps) => {
  return (
    <>
      <Cell>
        <Box display="flex">
          <Typography variant="body1">{data.lwin}</Typography>
        </Box>
      </Cell>

      <Cell>
        <Box display="flex">
          <Typography variant="body1">
            {formatCurrency(data.cwPurchasePrice ? data.cwPurchasePrice.toString() : "")}
          </Typography>
        </Box>
      </Cell>

      <Cell>
        <Box display="flex">
          <Typography variant="body1">{data.wineName}</Typography>
        </Box>
      </Cell>

      <Cell>
        <Box display="flex">
          <Typography variant="body1">1</Typography>
        </Box>
      </Cell>

      <Cell>
        <Box display="flex">
          <Typography variant="body1">{data.vintage}</Typography>
        </Box>
      </Cell>

      <Cell>
        <Box display="flex">
          <Typography variant="body1">{data.stockId}</Typography>
        </Box>
      </Cell>

      <Cell>
        <Box display="flex">
          <Typography variant="body1">{data.rotationNumber}</Typography>
        </Box>
      </Cell>

      {!showSalePrice ? null : editablePrice ? (
        <Cell>
          <Box display="flex">
            <TextField
              name="salePrice"
              error={Boolean(error)}
              helperText={error && strings.invalid_price}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography color="textSecondary" variant="caption">
                      £
                    </Typography>
                  </InputAdornment>
                ),
              }}
              inputProps={{ "aria-label": "salePrice" }}
              onChange={handleChange}
              fullWidth
              value={salePrice}
            />
          </Box>
        </Cell>
      ) : (
        <Cell>
          <Typography variant="body1">{formatCurrency(salePrice ? salePrice : "")}</Typography>
        </Cell>
      )}
    </>
  );
};

SalesPricesDataRow.propTypes = {
  data: types.stockDataShape,
  editablePrice: PropTypes.bool,
  handleChange: PropTypes.func,
  salePrice: PropTypes.string,
  error: PropTypes.string,
  showSalePrice: PropTypes.bool,
};

export default SalesPricesDataRow;
