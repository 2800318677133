import { FormEvent, useEffect, useState } from "react";
import { Box, Grid, makeStyles, TextareaAutosize, Typography, withStyles } from "@material-ui/core";
import { MainButton } from "../components/Common";
import { types, strings } from "common";
import { CommentList } from "../components/TicketsDetail";
import TicketInformation from "../components/TicketsDetail/TicketInformation";
import { useParams } from "react-router";
import { useIssueDetails, usePostComment } from "../utils/issuesDetail";
import { BreadCrumb } from "../components/Table";

const ButtonRow = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}))(Box);

const useStyles = makeStyles((theme) => ({
  headerTitle: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  frame: {
    backgroundColor: theme.palette.grey[50],
    display: "flex",
    padding: theme.spacing(8, 8, 8, 8),
    flexDirection: "column",
    height: "100%",
  },
  commentInput: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(8),
    border: `1px solid ${theme.palette.grey[400]}`,
    fontFamily: "Source Sans Pro, Arial, sans-serid",
    textIndent: theme.spacing(1),
    width: "100%",
    resize: "none",
  },
  canceledButton: {
    marginLeft: theme.spacing(4),
  },
  relatedText: {
    marginBottom: theme.spacing(12),
  },
}));

interface IssueDetailsCommentsSchema {
  id: number;
  comment: string;
  createdAt: string;
  authorName: string;
}

const TicketsDetail = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const { id } = useParams<{ id: string }>();

  const { data, isLoading } = useIssueDetails({ id });
  const { mutateAsync: postComment, isLoading: postCommentLoading } = usePostComment();

  const [comment, setComment] = useState("");

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    if (postCommentLoading) {
      return;
    }

    event.preventDefault();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    await postComment({ issueId: data.id, comment });
    setComment("");
  }

  return (
    <Box>
      <BreadCrumb path={strings.tickets} title={`${strings.ticket} ${data?.id || ""}`} isLoading={isLoading} />

      {!isLoading && data && (
        <>
          <Box className={classes.headerTitle}>
            <Typography variant="h1">{data.description}</Typography>
          </Box>

          <Grid spacing={10} container>
            <Grid item xs={7}>
              <Box className={classes.frame}>
                <Typography variant="h5">{strings.comments}</Typography>

                <form onSubmit={handleSubmit}>
                  <TextareaAutosize
                    minRows={5}
                    aria-label="comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value.trim())}
                    className={classes.commentInput}
                  />

                  <ButtonRow>
                    <MainButton disabled={postCommentLoading} type="submit">
                      {strings.post}
                    </MainButton>
                  </ButtonRow>
                </form>

                {data.comments.map((comment: IssueDetailsCommentsSchema) => (
                  <CommentList key={comment.id} comment={comment} />
                ))}
              </Box>
            </Grid>

            <Grid item xs={5}>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <Box className={`${classes.frame}`}>
                    <TicketInformation data={data} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

TicketsDetail.propTypes = {
  data: types.ticketDataShape,
};

export default TicketsDetail;
