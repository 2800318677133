import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  withStyles,
  Typography,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";

import PropTypes from "prop-types";
import PaginationRow from "./PaginationRow";
import useFilters from "../../hooks/useFilters";
import { FilterRow, InputRow } from ".";
import usePagination from "../../hooks/usePagination";
import useSelect from "../../hooks/useSelect";
import useSearch from "../../hooks/useSearch";
import LabelRow from "./LabelRow";
import { strings } from "common";
import {
  InitQuerySchema,
  SetFilterSchema,
  TableCellSchema,
  TableEmptyCellSchema,
  TableSearchSchema,
} from "../../interfaces/table.interface";
import { CaseDataSchema, SelectDataSchema } from "../../interfaces/case.interface";

const Container = withStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "column",
    flex: 1,
    marginTop: theme.spacing(8),
  },
}))(Box);

const Feedback = withStyles(() => ({
  root: {
    height: "150px",
    fontSize: "16px",
    lineHeight: "21px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))(Typography);

const TableContainerFixedHeight = withStyles(() => ({
  root: {
    maxHeight: "calc(100vh - 288px)",
  },
}))(TableContainer);

const useStyles = makeStyles((theme) => ({
  summaryContainer: {
    backgroundColor: theme.palette.grey.A100,
    paddingLeft: theme.spacing(16),
    paddingTop: theme.spacing(4),
    gap: theme.spacing(8),
  },
}));

interface QueryParamSchema {
  dealId?: string | string[];
  currentDate?: number;
  batchNumber?: number;
  entityId?: string;
  overdue?: boolean;
  discrepancy?: boolean;
}

interface TableSkeletonProps {
  cells: Array<TableCellSchema>;
  emptyCells: TableEmptyCellSchema;
  filterParam?: string;
  query: (params: TableSearchSchema) => any;
  queryParams: QueryParamSchema;
  filterValues: Array<string>;
  firstColspan?: number;
  ExportComponent?: React.ElementType;
  DataRow: React.ElementType;
  initFilter?: string;
  initQuery?: InitQuerySchema;
  filterRowCondition?: ({ status }: { status: string }) => boolean;
  keyExtractor: (val: CaseDataSchema) => string;
  dense: boolean;
  withInputs: boolean;
  name: string;
  from: string;
  caseId?: number;
  setStatusFilter?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const TableContainerFixedHeightProps = {
  component: Paper,
};

const TableSkeleton = ({
  cells,
  emptyCells,
  filterParam,
  query,
  queryParams,
  filterValues,
  firstColspan,
  ExportComponent,
  DataRow,
  initFilter,
  initQuery,
  filterRowCondition,
  keyExtractor,
  dense,
  withInputs,
  name,
  from,
  caseId,
  setStatusFilter,
}: TableSkeletonProps) => {
  const classes = useStyles();
  const [count, setCount] = React.useState(0);
  const [filters, setFilter, filterQuery] = useFilters(filterValues, filterParam ? filterParam : "", initFilter, name);

  const newSetFilter = setFilter as SetFilterSchema;
  const [fields, setFieldD, setField, clearFields] = useSearch(cells, initQuery);

  const { page, onPageChange, nextPage, previousPage, goToLastPage, goToFirstPage, pageSize, onPageChangeSize } =
    usePagination(count);

  const { data, isLoading } = query({
    ...fields,
    page,
    pageSize,
    ...filterQuery,
    ...queryParams,
  });

  React.useEffect(() => {
    if (data?.count !== undefined) {
      setCount(data?.count);
    }
  }, [isLoading, data?.count]);

  const rowData = data?.data as SelectDataSchema[];
  const selectData = filterRowCondition ? rowData?.filter(filterRowCondition) : rowData;
  const { hasSelections, selectRow, selectedCases, selectAll, deSelectAll, isAllSelected, selectedData } =
    useSelect(selectData);

  const getSelectedRows = () => {
    if (!rowData) {
      return [];
    }
    // todo: check this when doing the shipping integration
    const selectedIds = Object.entries(selectedCases).reduce((accum: any[], val) => {
      if (val[1] !== null) accum.push(val[0]);
      return accum;
    }, []);

    return from === "Discrepancy" ? selectedData.filter((row) => selectedIds.includes(`${row.issueId}`)) : selectedData;
  };

  const totalClientPurchasePrice = getSelectedRows()
    .reduce((prev, curr) => {
      return prev + (curr?.clientPurchasePrice || 0);
    }, 0)
    .toFixed(2);

  const totalCWPurchasePrice = getSelectedRows()
    .reduce((prev, curr) => {
      return prev + (curr?.cwPurchasePrice || 0);
    }, 0)
    .toFixed(2);

  React.useEffect(() => {
    //Deselect all rows when swtiching between stock screen tabs
    if (getSelectedRows().length) {
      deSelectAll();
    }
  }, [name]);

  return (
    <Container>
      {filterValues.length !== 0 && filters && (
        <FilterRow
          isLoading={Boolean(isLoading)}
          setFilter={(key: string, value: boolean) => {
            newSetFilter.set(key, value);
            goToFirstPage();
          }}
          filters={filters}
          filterValues={filterValues}
        />
      )}

      <TableContainerFixedHeight {...TableContainerFixedHeightProps}>
        <Box>
          <Table stickyHeader aria-label="table">
            <LabelRow
              needCheckBox={from !== strings.discrepancy}
              dense={dense}
              cells={cells}
              isAllSelected={isAllSelected}
              hasSelections={hasSelections}
              emptyCells={emptyCells}
              selectAll={selectAll}
              deSelectAll={deSelectAll}
            />

            <TableBody>
              {withInputs && (
                <InputRow
                  dense={dense}
                  searchFields={fields}
                  setFieldD={(key, value) => {
                    setFieldD(key, value);
                    goToFirstPage();
                  }}
                  setField={(key, value) => {
                    setField(key, value);
                    goToFirstPage();
                    if (setStatusFilter && key === "status") {
                      setStatusFilter(value);
                    }
                  }}
                  cells={cells}
                  firstColspan={firstColspan ? firstColspan : 0}
                  clearFields={() => {
                    clearFields();
                  }}
                />
              )}

              {isLoading ? (
                <td colSpan={cells.length + Object.keys(emptyCells).length}>
                  <Feedback>
                    <CircularProgress />
                  </Feedback>
                </td>
              ) : rowData?.length > 0 ? (
                rowData.map((row) => {
                  return (
                    <DataRow
                      selectRow={() => selectRow(row, from)}
                      selected={
                        from === "Discrepancy"
                          ? selectedCases[row.issueId as keyof typeof selectedCases]
                          : selectedData?.some((e) => e.id === row.id)
                      }
                      data={row}
                      key={keyExtractor ? keyExtractor(row) : row.id}
                      cells={cells}
                    />
                  );
                })
              ) : (
                <td colSpan={cells.length + Object.keys(emptyCells).length}>
                  <Feedback>{strings.no_results_found_matching_your_filters}</Feedback>
                </td>
              )}
            </TableBody>
          </Table>
        </Box>
      </TableContainerFixedHeight>

      {name === "stock" && (
        <Box display="flex" className={classes.summaryContainer}>
          <Typography variant="body2">
            {strings.sumSelected} {getSelectedRows().length}
          </Typography>
          <Typography variant="body2">
            {strings.ClientPurchasePrice} £{totalClientPurchasePrice}
          </Typography>
          <Typography variant="body2">
            {strings.CWPurchasePrice} £{totalCWPurchasePrice}
          </Typography>
        </Box>
      )}

      <PaginationRow
        page={page}
        count={count}
        pageSize={pageSize}
        onPageChange={onPageChange}
        onPageChangeSize={onPageChangeSize}
        nextPage={nextPage}
        previousPage={previousPage}
        goToLastPage={goToLastPage}
        goToFirstPage={goToFirstPage}
        {...(ExportComponent && {
          ExportComponent: (
            <ExportComponent
              totalCWPurchasePrice={totalCWPurchasePrice}
              caseId={caseId}
              selectedrows={getSelectedRows()}
              disabled={!hasSelections()}
              deSelectAll={deSelectAll}
              name={name}
            />
          ),
        })}
      />
    </Container>
  );
};

TableSkeleton.propTypes = {
  cells: PropTypes.arrayOf(PropTypes.shape({})),
  emptyCells: PropTypes.shape({}).isRequired,
  filterValues: PropTypes.arrayOf(PropTypes.string),
  firstColspan: PropTypes.number,
  ExportComponent: PropTypes.any,
  LabelRow: PropTypes.elementType,
  DataRow: PropTypes.elementType,
  data: PropTypes.any,
  filterParam: PropTypes.string,
  query: PropTypes.func,
  initFilter: PropTypes.string,
  initQuery: PropTypes.shape({}),
  queryParams: PropTypes.shape({}),
  filterRowCondition: PropTypes.func,
  keyExtractor: PropTypes.func,
  dense: PropTypes.bool,
  withInputs: PropTypes.bool,
  name: PropTypes.string,
  from: PropTypes.string,
  caseId: PropTypes.number,
};

TableSkeleton.defaultProps = {
  queryParams: {},
  keyExtractor: null,
  dense: false,
  withInputs: true,
  name: "",
  from: "",
};

export default TableSkeleton;
