import { Box, makeStyles, Modal, Typography } from "@material-ui/core";
import { strings } from "common";
import PropTypes from "prop-types";
import logo from "../../assets/Logo.svg";
import questionMark from "../../assets/question_mark.svg";

import { MainButton } from "../Common";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    width: "480px",
    background: "white",
    alignItems: "center",
    justifyContent: "center",
    outline: "none",
    padding: theme.spacing(12, 12, 12, 12),
  },
  center: {
    width: "100%",
    paddingBottom: theme.spacing(12),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    width: "80%",
    borderRadius: theme.spacing(12),
  },
  labels: {
    marginTop: theme.spacing(12),
  },
  errorMsg: {
    color: "#FF0000",
  },
}));

interface ResendModalProps {
  modalId: string;
  isOpen: boolean;
  handleEvent: () => void;
  closeModal: () => void;
  errorMsg: string;
}

const ResendModal = ({ modalId, isOpen, handleEvent, closeModal, errorMsg }: ResendModalProps) => {
  const classes = useStyles();

  const handleConfirm = () => {
    handleEvent();
  };

  const handleClose = () => {
    closeModal();
  };

  return (
    <Modal
      id={modalId}
      open={isOpen}
      className={classes.modal}
      data-testid="resend-pa-modal"
      aria-labelledby="resend pa"
      aria-describedby="resend pa modal"
    >
      <Box className={classes.modalContent}>
        <Box className={classes.center}>
          <img src={logo} alt="logo" />
        </Box>
        <div className={classes.center}>
          <img src={questionMark} alt="questionMark" width="120" height="120" />
        </div>
        <div className={classes.center}>
          <Typography variant="h4">{strings.resend_PA_message}</Typography>
        </div>

        <div className={classes.center}>
          <MainButton className={classes.button} id="resend_confirm" onClick={handleConfirm}>
            {strings.resend_confirm}
          </MainButton>
        </div>

        <div className={classes.center}>
          <MainButton className={classes.button} id="resend_cancel" onClick={handleClose}>
            {strings.resend_cancel}
          </MainButton>
        </div>

        <div className={classes.center}>
          <Typography className={classes.errorMsg}>{errorMsg}</Typography>
        </div>
      </Box>
    </Modal>
  );
};

ResendModal.propTypes = {
  modalId: PropTypes.string,
  ids: PropTypes.array,
  isOpen: PropTypes.bool,
  handleEvent: PropTypes.func,
  closeModal: PropTypes.func,
  errorMsg: PropTypes.string,
};

export default ResendModal;
