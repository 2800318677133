import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query";
import { useClient } from "../providers/auth-context";
import { paramsToQueryString } from "./api-client";
import readStream from "./readStream";
import { CreateTicketSchema } from "../interfaces/ticket.interface";
import { ClientType } from "../interfaces/apiClient.interface";
import { TableSearchSchema, TicketResponseData } from "../interfaces/table.interface";
import { DealDashboardResponseSchema } from "../interfaces/deal.interface";
import { DashboardDataSchema } from "../interfaces/dashboard.interface";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const apiURL = window.env.REACT_APP_API_URL;

const getIssueSearchConfig = (client: ClientType<TicketResponseData>, params: TableSearchSchema) => ({
  queryKey: ["issuesSearch", { params }],
  queryFn: () =>
    client(`issues?${paramsToQueryString(params)}`)
      .then((data) => data)
      .catch((err) => console.log(err)),
});

const getIssuesForDashboardConfig = (client: ClientType<DealDashboardResponseSchema>, statusVal: string) => ({
  queryKey: ["issuesDashboard", { statusVal }],
  queryFn: () => client(`issues/dashboard?statusVal=${statusVal}`).then((data) => data),
});

const useTicketsExport = (params: { ticketIds: number[] }) => {
  const token = "";
  const url = `${apiURL}/issues/export?issueIds=${params.ticketIds}`;
  const filename = "exported_tickets.xlsx";

  return () => readStream(token, filename, url);
};

function useIssuesSearch(params: TableSearchSchema) {
  const client = useClient<TicketResponseData>();
  const result = useQuery(getIssueSearchConfig(client, params));

  return { ...result, data: result.data };
}

function useIssuesForDashboard(statusVal: string) {
  const client = useClient<DealDashboardResponseSchema>();
  const result = useQuery(getIssuesForDashboardConfig(client, statusVal));

  const defaultResult: DashboardDataSchema[] = [];

  return { isLoading: result.isLoading, data: result.data?.data ?? defaultResult };
}

const defaultMutationOptions = (queryClient: QueryClient) => ({
  onSettled: () => queryClient.invalidateQueries("issuesSearch"),
});

function useCreateTicket() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    (params: CreateTicketSchema) =>
      client("issues", {
        data: {
          ...params,
        },
      }),
    {
      ...defaultMutationOptions(queryClient),
    }
  );
}

function useBulkUpdateStatus() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    (issues: { id: number; status: string }[]) =>
      client(`issues/`, {
        method: "PATCH",
        body: JSON.stringify({ issues }),
      }),
    {
      ...defaultMutationOptions(queryClient),
    }
  );
}

export { useIssuesSearch, useIssuesForDashboard, useTicketsExport, useCreateTicket, useBulkUpdateStatus };
