import { MouseEventHandler } from "react";
import PropTypes from "prop-types";
import { Box, makeStyles, Table, TableBody, Typography } from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { LightGreyRow } from "../Common";
import SalesPricesDataRow from "./SalesPricesDataRow";
import SalePricesLabelRow from "./SalePricesLabelRow";
import { types } from "common";
import { CaseDataSchema } from "../../interfaces/case.interface";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    width: "100%",
    paddingBottom: theme.spacing(7),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

interface ValuesSchema {
  salePrice: object;
  clientName: string;
  address: string;
  postCode: string;
  city: string;
  country: string;
}

interface ModalContentProps {
  closeId: string;
  title: string;
  closeModal: MouseEventHandler;
  editablePrice?: boolean | undefined;
  casesToShip: Array<CaseDataSchema>;
  setSalePrice: any;
  values: ValuesSchema;
  errors: any;
}

const ModalContent = ({
  closeId,
  title,
  closeModal,
  editablePrice,
  casesToShip,
  setSalePrice,
  values,
  errors,
}: ModalContentProps) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.modalHeader}>
        <Typography variant="h3">{title}</Typography>
        <CloseIcon id={closeId} onClick={closeModal} />
      </Box>

      <Box>
        <Table>
          <SalePricesLabelRow showSalePrice />

          <TableBody>
            {casesToShip.map((row) => (
              <LightGreyRow key={row.id}>
                <SalesPricesDataRow
                  error={errors?.salePrice?.[row.id]}
                  handleChange={setSalePrice(row.id)}
                  salePrice={values.salePrice[row.id as keyof typeof values.salePrice] || ""}
                  editablePrice={editablePrice}
                  data={row}
                  showSalePrice
                />
              </LightGreyRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

ModalContent.propTypes = {
  closeId: PropTypes.string,
  casesToShip: PropTypes.arrayOf(types.stockDataShape),
  title: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  ShippingReview: PropTypes.elementType,
  editablePrice: PropTypes.bool,
  setSalePrice: PropTypes.func,
  values: PropTypes.shape({
    salePrice: PropTypes.shape({}),
    clientName: PropTypes.string,
    address: PropTypes.string,
    postCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
  errors: PropTypes.any,
};

export default ModalContent;
