import streamSaver from "streamsaver";

const readStream = async (_token: string, filename: string, url: string) => {
  const res: any = await fetch(url, {
    credentials: "include",
  });

  const fileStream = streamSaver.createWriteStream(`${filename}`);
  const writer = fileStream.getWriter();

  const reader = res.body.getReader();

  const pump = () =>
    reader.read().then(({ value, done }: { value: any; done: any }) => {
      if (done) {
        writer.close();
        return null;
      } else {
        writer.write(value);
        return writer.ready.then(pump);
      }
    });

  return await pump().catch((err: any) => console.log(err));
};

export default readStream;
