const formatCurrency = (value: string, fraction = 2) => {
  if (!value) {
    return null;
  }

  const formatter = new Intl.NumberFormat("en-EN", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: fraction,
  });

  return formatter.format(parseFloat(value));
};

const pow = Math.pow;
const floor = Math.floor;
const abs = Math.abs;
const log = Math.log;
const abbrev = "KMBT";

const round = (number: number, precision: number) => {
  const prec = Math.pow(10, precision);
  return Math.round(number * prec) / prec;
};

const numberToNearThousand = (number: number) => {
  let base = floor(log(abs(number)) / log(1000));
  const suffix = abbrev[Math.min(2, base - 1)];
  if (suffix) {
    base = abbrev.indexOf(suffix) + 1;
    return round(number / pow(1000, base), 2) + suffix;
  } else {
    return "" + number;
  }
};

export { formatCurrency, numberToNearThousand };
