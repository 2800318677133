import { Box, TableHead, TableRow } from "@material-ui/core";
import { strings } from "common";
import { GreyCell, Label } from "../Common";
import PropTypes from "prop-types";

const CasesLabelRow = () => {
  return (
    <TableHead>
      <TableRow>
        <GreyCell style={{ width: 160 }}>
          <Box display="flex">
            <Label variant="caption">{strings.lwin}</Label>
          </Box>
        </GreyCell>

        <GreyCell style={{ width: 245 }}>
          <Box display="flex">
            <Label variant="caption">{strings.wine}</Label>
          </Box>
        </GreyCell>

        <GreyCell style={{ width: 40 }}>
          <Box display="flex">
            <Label variant="caption">{strings.vintage}</Label>
          </Box>
        </GreyCell>

        <GreyCell style={{ width: 100 }}>
          <Box display="flex">
            <Label variant="caption">{strings.size}</Label>
          </Box>
        </GreyCell>

        <GreyCell style={{ width: 140 }}>
          <Box display="flex">
            <Label variant="caption">{strings.release_price}</Label>
          </Box>
        </GreyCell>
      </TableRow>
    </TableHead>
  );
};

CasesLabelRow.propTypes = {
  showSalePrice: PropTypes.bool,
};
export default CasesLabelRow;
