import { Box, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { strings } from "common";
import { useParams } from "react-router";
import { BreadCrumb } from "../components/Table";
import { useDealsDetail } from "../utils/dealsDetail";
import { Information, DealsCaseListTable } from "../components/DealsDetail";
import { useCasesSearch } from "../utils/cases";
import ShipButton from "../components/DealsDetail/ShipButton";

const useStyles = makeStyles((theme) => ({
  caseList: {
    background: theme.palette.grey[50],
  },
  caseListHeader: {
    padding: theme.spacing(8, 8, 8, 8),
    flexDirection: "row",
    display: "flex",
    justifyContent: "space-between",
  },
  spinner: {},
}));

const DealsDetail = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useDealsDetail(id);

  // get shipcounts
  const { data: casesData, isLoading: isLoadingCases } = useCasesSearch({ dealId: id, shippingCounts: true });

  return data ? (
    <Box>
      <BreadCrumb
        path="Deals"
        title={`${strings.deal} ${data.dealNumber ?? "-"}`}
        isLoading={isLoading || isLoadingCases}
      />

      <>
        {!(isLoading || isLoadingCases) && casesData && (
          <Information data={{ countToShip: casesData.count ?? 0, ...data }} />
        )}

        <Box marginTop={8} className={classes.caseList}>
          <Box className={classes.caseListHeader}>
            <Typography variant="h4">{strings.case_list}</Typography>

            {isLoadingCases && (
              <Box data-testid="loading" className={classes.spinner}>
                <CircularProgress size={20} />
              </Box>
            )}
          </Box>

          <DealsCaseListTable
            dealId={id}
            queryParam={{ dealId: id }}
            ExportComponent={ShipButton}
            showDealNumber={false}
          />
        </Box>
      </>
    </Box>
  ) : (
    <Typography>{strings.no_data}</Typography>
  );
};

export default DealsDetail;
