import { DataRow, ShipButton } from "../components/Deals";
import { strings } from "common";
import { cells, emptyCells } from "../components/Deals/cells";
import TableSkeleton from "../components/Table/Skeleton";
import { dealsStatus } from "../constants";
import { useLocation } from "react-router";
import { Typography } from "@material-ui/core";
import { useDealsSearch } from "../utils/deals";
import { StateSchema } from "../interfaces/case.interface";

const filterValues = [...Object.keys(dealsStatus)];

const firstColspan = 1;

const Deals = () => {
  const location = useLocation();

  let initFilter;

  if (filterValues.includes((location?.state as StateSchema)?.status)) {
    initFilter = (location.state as StateSchema).status;
  }

  return (
    <>
      <Typography variant="h4">{strings.deals}</Typography>
      <TableSkeleton
        name="deals"
        query={useDealsSearch}
        cells={cells}
        emptyCells={emptyCells}
        filterParam={"status"}
        filterValues={filterValues}
        initFilter={initFilter}
        firstColspan={firstColspan}
        DataRow={DataRow}
        ExportComponent={ShipButton}
      ></TableSkeleton>
    </>
  );
};

export default Deals;
