import { Box, TableHead, TableRow } from "@material-ui/core";
import { strings } from "common";
import { GreyCell, Label } from "../Common";
import PropTypes from "prop-types";

const CasesLabelRow = ({ showSalePrice = false }: { showSalePrice?: boolean }) => {
  return (
    <TableHead>
      <TableRow>
        <GreyCell style={{ width: "10%" }}>
          <Box display="flex">
            <Label variant="caption">{strings.lwin}</Label>
          </Box>
        </GreyCell>

        <GreyCell style={{ width: "10%" }}>
          <Box display="flex">
            <Label variant="caption">{strings.prch_price}</Label>
          </Box>
        </GreyCell>

        <GreyCell style={{ width: "10%" }}>
          <Box display="flex">
            <Label variant="caption">{strings.wine}</Label>
          </Box>
        </GreyCell>

        <GreyCell style={{ width: "3%" }}>
          <Box display="flex">
            <Label variant="caption">{strings.quantity}</Label>
          </Box>
        </GreyCell>

        <GreyCell style={{ width: "10%" }}>
          <Box display="flex">
            <Label variant="caption">{strings.vintage}</Label>
          </Box>
        </GreyCell>

        <GreyCell style={{ width: "10%" }}>
          <Box display="flex">
            <Label variant="caption">{strings.stock_id}</Label>
          </Box>
        </GreyCell>

        <GreyCell style={{ width: "10%" }}>
          <Box display="flex">
            <Label variant="caption">{strings.rotation}</Label>
          </Box>
        </GreyCell>

        {showSalePrice && (
          <GreyCell style={{ width: "10%" }}>
            <Box display="flex">
              <Label variant="caption">{strings.release_price}</Label>
            </Box>
          </GreyCell>
        )}
      </TableRow>
    </TableHead>
  );
};

CasesLabelRow.propTypes = {
  showSalePrice: PropTypes.bool,
};
export default CasesLabelRow;
