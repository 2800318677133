import {
  Box,
  ButtonBase,
  CircularProgress,
  IconButton,
  ListItem,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import useInfiniteScroll from "react-infinite-scroll-hook";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import { entityTypesList } from "../../constants";
import TicketItem from "./TicketItem";
import CaseItem from "./CaseItem";
import { useNotificationsAck, useNotificationsAckAll, useNotificationsSearch } from "../../utils/notifications";
import { useQueryClient } from "react-query";
import { getRelativeTime } from "common/utils/date";
import { CancelButton } from "../Common";
import { strings } from "common";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 400,
  },
  fixedHeader: {
    display: "flex",
    position: "fixed",
    backgroundColor: "white",
    paddingLeft: theme.spacing(8),
    alignItems: "center",
    width: 400,
    zIndex: 2,
  },
  closeIcon: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    paddingRight: theme.spacing(12),
  },
  content: {
    paddingTop: 48,
    paddingBottom: theme.spacing(8),
  },
  item: {
    borderTop: `1px solid ${theme.palette.grey.A200}`,
    padding: theme.spacing(8, 8, 8, 8),
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
  },
  newNotification: {
    height: 10,
    width: 10,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    width: "100%",
  },
  allReadButton: {
    height: 30,
  },
}));

interface NotificationOverlayProps {
  id: string | undefined;
  openPopover: boolean;
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
  handleClose: () => void;
}

const NotificationOverlay = ({ id, openPopover, anchorEl, handleClose }: NotificationOverlayProps) => {
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { data, isLoading, fetchNextPage, hasNextPage, error } = useNotificationsSearch();

  const { mutate: ackNotification } = useNotificationsAck();
  const { mutate: ackAllNotifications } = useNotificationsAckAll();

  const queryClient = useQueryClient();

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: Boolean(hasNextPage),
    onLoadMore: () => {
      fetchNextPage();
    },
    disabled: !!error,
  });

  const onClose = () => {
    handleClose();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    queryClient.clear("notificationsSearch");
  };

  return (
    <Popover
      id={id}
      ref={rootRef}
      open={openPopover}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box className={classes.container}>
        <Box className={classes.fixedHeader}>
          <CancelButton className={classes.allReadButton} onClick={() => ackAllNotifications}>
            {strings.mark_all_read}
          </CancelButton>

          <Box className={classes.closeIcon}>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <Box className={classes.content}>
          {data.count > 0 ? (
            data.data.map((item) => (
              <Box key={item.id}>
                <Box key={item.id} className={classes.item}>
                  <Box className={classes.titleContainer}>
                    <Box className={classes.title}>
                      {!item.acknowledged && <Box className={classes.newNotification} />}

                      <Typography>{item.title}</Typography>
                    </Box>

                    <Typography color="textSecondary">{getRelativeTime(new Date(item.createdAt))}</Typography>
                  </Box>

                  {item.entity === entityTypesList.ISSUES && (
                    <ButtonBase className={classes.button} onClick={() => ackNotification(item.id)}>
                      <TicketItem data={item.payload} />
                    </ButtonBase>
                  )}

                  {item.entity === entityTypesList.CASES && (
                    <ButtonBase className={classes.button} onClick={() => ackNotification(item.id)}>
                      <CaseItem data={item.payload} />
                    </ButtonBase>
                  )}
                </Box>
              </Box>
            ))
          ) : (
            <Box className={classes.item}></Box>
          )}
        </Box>

        {(isLoading || hasNextPage) && (
          <ListItem ref={sentryRef}>
            <Box display="flex" justifyContent="flex-end" flex="1">
              <Box data-testid="loading">
                <CircularProgress size={20} />
              </Box>
            </Box>
          </ListItem>
        )}
      </Box>
    </Popover>
  );
};

NotificationOverlay.propTypes = {
  id: PropTypes.string,
  openPopover: PropTypes.bool,
  anchorEl: PropTypes.any,
  handleClose: PropTypes.func,
};

export default NotificationOverlay;
