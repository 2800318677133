import { MouseEventHandler } from "react";
import clsx from "clsx";
import { Box, Divider, Drawer, ListItem, makeStyles, SvgIconTypeMap, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { menuOptions } from "../../constants";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";

export const drawerWidthClosed = 56;
export const drawerWidth = 160;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerBorder: {
    borderRight: `${theme.spacing(1)}px solid ${theme.palette.grey.A200}`,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: drawerWidthClosed,
    [theme.breakpoints.up("sm")]: {
      width: drawerWidthClosed + 4,
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    // padding: theme.spacing(0, 1),
    marginTop: theme.spacing(1),
    borderBottom: `${theme.spacing(1)}px solid ${theme.palette.grey.A200}`,
  },

  listItem: {
    padding: theme.spacing(9),
  },
  selected: {
    backgroundColor: theme.palette.grey["A100"],
  },
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(12),
    paddingRight: theme.spacing(12),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  title: {
    paddingLeft: theme.spacing(12),
  },
}));

interface NavigationDrawerProps {
  selectedOption: string;
  setOption: (val: string) => void;
  open: boolean;
  handleDrawerClose: MouseEventHandler;
}

interface renderListItemProps {
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  route: string;
  title: string;
}

const NavigationDrawer = ({ selectedOption, setOption, open, handleDrawerClose }: NavigationDrawerProps) => {
  const classes = useStyles();
  const history = useHistory();

  function isSelected(option: string) {
    return selectedOption === option;
  }

  const renderListItem = (option: renderListItemProps) => {
    const { icon: Icon, route, title } = option;

    return (
      <ListItem
        button
        onClick={() => {
          history.push(route);
          setOption(route);
        }}
        className={`${classes.listItem} ${isSelected(route) && classes.selected}`}
      >
        <Box display="flex" flexDirection="row">
          <Icon fontSize="small" color="primary" />
          <Typography className={classes.title} variant="button" color="primary">
            {title}
          </Typography>
        </Box>
      </ListItem>
    );
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paperAnchorDockedLeft: classes.drawerBorder,
        paper: clsx(classes.drawerPaper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      anchor="left"
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleDrawerClose}>{<ChevronLeft />}</IconButton>
      </div>
      {Object.keys(menuOptions).map((option) => (
        <div key={option}>
          {renderListItem(menuOptions[option as keyof typeof menuOptions])}
          <Divider />
        </div>
      ))}
    </Drawer>
  );
};

NavigationDrawer.propTypes = {
  selectedOption: PropTypes.string.isRequired,
  setOption: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
};

export default NavigationDrawer;
