import PropTypes from "prop-types";

import { CheckBox, PaperFilterRow, Filter, FilterLabel } from "../Common";
import { strings } from "common";
import { Box, CircularProgress, makeStyles, useTheme } from "@material-ui/core";
import { filtersToParams } from "../../constants";
import { SetFilterSchema } from "../../interfaces/table.interface";

const useStyles = makeStyles((theme) => ({
  spinner: {
    display: "flex",
    flex: "1",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: theme.spacing(15),
  },
}));

interface FilterRowProps {
  filters: SetFilterSchema | { [p: string]: string[] | string | boolean };
  setFilter: (key: string, val: boolean) => void;
  filterValues: Array<string>;
  isLoading: boolean;
}

const FilterRow = ({ filters, setFilter, filterValues, isLoading }: FilterRowProps) => {
  const theme = useTheme();
  const filterAll = filters[strings.all];
  const classes = useStyles(filterAll);

  return (
    <PaperFilterRow>
      <Filter>
        <CheckBox
          indeterminate={false}
          style={{
            color: theme.palette.primary.main,
          }}
          onChange={() => setFilter(strings.all, !filterAll)}
          checked={Boolean(filterAll)}
          inputProps={{ "aria-label": "filterAllBox" }}
        />
        <FilterLabel>{strings.all}</FilterLabel>
      </Filter>

      {filterValues.map((filter) => {
        return (
          <Filter key={filter}>
            <CheckBox
              key={`${filter}-${filters[filter]}`}
              color="primary"
              onClick={() => setFilter(filter, !filters[filter])}
              checked={Boolean(filters[filter])}
              inputProps={{ "aria-label": filter }}
            />
            <FilterLabel>{filtersToParams[filter as keyof typeof filtersToParams]}</FilterLabel>
          </Filter>
        );
      })}
      {isLoading && (
        <Box data-testid="loading" className={classes.spinner}>
          <CircularProgress size={20} />
        </Box>
      )}
    </PaperFilterRow>
  );
};

FilterRow.propTypes = {
  filters: PropTypes.shape({}).isRequired,
  setFilter: PropTypes.func.isRequired,
  filterValues: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default FilterRow;
