import { DataRow, cells, emptyCells } from "../components/Users";
import { strings } from "common";
import TableSkeleton from "../components/Table/Skeleton";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useUsersSearch } from "../utils/users";
import { MainButton } from "../components/Common";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  row: {
    flexDirection: "row",
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  },
}));

const Users = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Box className={classes.row}>
        <Typography variant="h4">{strings.users}</Typography>
        <MainButton onClick={() => history.push("/users/create")}>{strings.add_user}</MainButton>
      </Box>

      <TableSkeleton
        filterValues={[]}
        query={useUsersSearch}
        cells={cells}
        emptyCells={emptyCells}
        firstColspan={0}
        DataRow={DataRow}
      ></TableSkeleton>
    </>
  );
};

export default Users;
