const strings = {
  //
  save: 'Save',
  cancel: 'Cancel',
  yes: 'Yes',
  no: 'No',
  next: 'Next',
  back: 'Back',
  confirm: 'Confirm',
  success: 'Success',
  successful: 'Successful',
  created: 'Created',
  sent: 'Sent',
  auid: 'AUid',
  pcid: 'PCid',

  // formik
  required: 'Required',
  required_with_coll: 'Required when collection delivery area is set',
  number_error: 'Must be a number',
  invalid_phone_number: 'Invalid phone number',
  provide_phone_number: 'Provide the phone number',
  invalid_country_code: 'Invalid country code',
  invalid_postcode: 'Invalid postcode',
  value_too_short: 'The value is too short',
  value_too_long: 'The value is too long',
  phone_required: 'Contact number is required',
  incorrect_awrs: 'Incorrect AWRS (correct format: 4 uppercase letters and 11 digits)',
  //errors
  wrong: 'Something went wrong',

  // stock
  stock: 'Stock',
  wine: 'Wine',
  subset: 'Subset',
  vintage: 'Vintage',
  size: 'Size',
  qty: 'Qty',
  stock_id: 'Stock Id',
  nfc: 'NFC',
  case_id: 'Id',
  prch_price: 'CW prch price',
  booked_date: 'Booked date',
  arrival_date: 'Arrival date',
  flag: 'Flag',
  note: 'Note',
  sumSelected: 'Sum of selected stocks:',
  ClientPurchasePrice: 'Total client purchase price:',
  CWPurchasePrice: 'Total CW purchase price:',

  in_transit: 'In Transit',
  in_stock: 'In Stock',
  pending_release: 'Pending Release',
  pending_transport: 'Pending Transport',
  release_processed: 'Release Processed',
  departed: 'Departed',
  ticketStatus: 'Ticket status',
  linked: 'Linked',
  interim: 'Interim',
  overdue: 'overdue',
  orphanedAssetRecord: 'Orphaned Asset Record',

  request_photo: 'Request Photo',
  batch_number: 'Batch number',
  create_pre_advice: 'Create pre advice',
  create_sales_order: 'Create sales order',
  photo_requested: 'Photo requested successfully',
  photo_request_failed: 'Photo request failed',
  expected_delivery_date: 'Expected delivery date',
  allocate: 'Allocate',

  // stock detail
  blockchain_hash: 'Blockchain hash',
  // -> grid
  information: 'Information',
  references: 'References',
  lot_information: 'Lot Information',
  record: 'Record',
  supplier: 'Supplier',
  warehouse: 'Warehouse',
  warehouseAC: 'W. account code',
  status: 'Status',
  bonded_status: 'Bonded status',
  rotation: 'Rotation',
  lwin: 'LWIN',
  po_number: 'Po number',
  delivery_id: 'Delivery ID',
  ticket_id: 'Ticket ID',
  rotation_uid: 'UID',
  value: 'Value',
  qtyCases: 'Qty cases',

  unit_size: 'Unit Size',
  quantity: 'Quantity',
  purchase_price: 'CW prch price',
  market_price: 'Market price',
  purchase_date: 'Purchase date',
  creation_date: 'Creation date',
  booked: 'Booked',
  landing_date: 'Landing Date',
  departed_date: 'Departed Date',
  current_owner: 'Current owner',
  source: 'Source',

  edit_case: 'Edit case',

  edit: 'Edit',
  export: 'Export',
  select_status: 'Select status',
  share: 'Share',
  download: 'Download',
  resolve: 'Resolve',

  tickets: 'Tickets',
  history: 'History',

  asset_record: 'Asset Record',
  asset_record_id: 'Asset Record ID',
  physical_record: 'Physical Record',
  physical_record_id: 'Physical Record ID',
  relationship: 'Relationship',
  tradeable: 'Tradeable',

  // -> history table
  entity: 'Entity',
  action: 'Action',
  change: 'Change',
  content: 'Content',
  by: 'By',
  timestamp: 'Timestamp',
  multiple_changes: 'Multiple changes',
  cases: 'Case',
  issues: 'Ticket',
  issue_comments: 'Ticket comment',
  create: 'Created',
  submittedAt: 'Submitted at',
  update: 'Updated',
  deleted: 'Deleted',
  new_comment: 'New comment',
  photo_url: 'Added photo',

  // tickets
  id: 'ID',
  type: 'Type',
  ticket: 'Ticket',

  // -> ticket table
  author: 'Author',
  update_date: 'Update date',
  comment: 'Comment',
  all: 'All',
  assetUnits: 'Asset units',
  orphanStock: 'Orphan Physical Stock',
  queries: 'Queries',
  discrepancies: 'Discrepancies',
  no_results_found_matching_your_filters: 'No results found matching your filters',
  success_bulk_update: 'Successfully set issues statuses to',

  discrepancy: 'Discrepancy',
  query: 'Query',
  migrationIssue: 'Migration issue',

  // Ticket detail
  comments: 'Comments',
  post: 'Post',
  updated_date: 'Updated date',
  open: 'Open',
  closed: 'Closed',
  pending: 'Pending',
  related_case: 'Related Case',
  related_cases: 'Related Cases',
  go_case: 'Go to case',
  ticket_status_changed: 'Ticket status changed successfully',

  // orders
  orders: 'Orders',
  pre_advice: 'Pre advice',
  sales_order: 'Sales Order',
  // expected_delivery_date: 'Expected delivery date',
  resend: 'Resend',
  download_as_xml: 'Download as XML',
  remove: 'Cancel',
  // successful: 'Successful',
  unsuccessful: 'Unsuccessful',
  cancelled: 'Cancelled',
  cancelled_pending: 'Cancelled Pending',
  pending_migration: 'Migration Pending',
  migrating: 'Migrating',
  externally_managed: 'Externally managed',
  resend_PA_message: 'Are you sure you want to resend this via the API?',
  resend_confirm: 'CONFIRM',
  resend_cancel: 'CANCEL',
  send_failed: 'Send failed',

  // deals
  deal: 'Deal',
  deals: 'Deals',
  in_progress: 'In progress',
  deal_number: 'Deal number',
  deal_type: 'Deal type',
  client: 'Client',
  accountHolderName: 'Account Holder',
  delivery_location: 'Delivery location',
  paid_date: 'Paid date',
  pm: 'PM',
  short_deal_number: 'Deal #',
  release: 'Release',
  trade: 'Trade',
  review_shipping: 'Review shipping',
  post_code: 'Postal code',
  invalid_price: 'invalid price',
  invalid_postal_code: 'Invalid postal code',
  comment_failed: 'Comment failed to update',
  select_cases_to_ship: 'Select cases to ship',

  // deals detail
  sales_type: 'Sales type',
  deal_transaction_type: 'Transaction type',
  delivery_address: 'Delivery address',
  case_list: 'Case list',
  sale_price: 'Sale price',
  client_prch_price: 'Client prch price',
  release_price: 'Release price',
  ship: 'Ship',
  adjust_record: 'Adjust Record',
  cases_ship: 'Cases to ship',
  sale_prices: 'Sale prices',
  address: 'Address',
  city: 'City',
  country: 'Country',
  releaseType: 'Release type',
  transporter: 'Transporter',
  last_trans_price: 'Transfer Price',
  ship_success_msg: 'Your sales order has been successfully submitted',
  ship_success_btn: 'Close',
  ship_error_msg: 'Your sales order has failed to be submitted. Please try again',
  ship_error_btn: 'Try again',

  // auth
  re_authenticate: 'Please re-authenticate',
  try_again: 'Please reload or try again later',
  reload_page: 'Reload Page',
  email: 'Email',
  password: 'Password',
  login: 'LOGIN',
  unauthorized: 'Unauthorized',
  logout: 'Logout',
  ack_all_notifications: 'Acknowledge All Notifications',

  // dashboard
  dashboard: 'Dashboard',
  cost_in_pounds: 'Cost in £',
  no_units: 'No of units',
  current: 'Current',
  due: 'Due',
  total: 'Total',
  inbound_title: 'Inbound cases',
  ouverdue_title: 'Overdue cases',
  deal_title: 'Open deals',
  ticket_title: 'Open tickets',
  stock_status_title: 'Stock status',
  chart_title: 'Stock status (cost in £)',
  see_all: 'See all',

  //users
  users: 'Users',
  warehouses: 'Warehouses',
  role: 'Role',
  add_user: 'Add user',
  name: 'Name',
  warehousing: 'Warehousing',
  Operations: 'Operations',
  admin_warehousing: 'Admin warehousing',
  admin: 'Is global admin',
  delete: 'Delete',
  delete_user: 'Delete user',
  delete_user_message: 'Are you sure you want to delete this user? This action is irreversible',
  // email: 'Email',

  //assignment
  assignments: 'Assignments',
  physical_record_title: 'Orphaned Physical Record',
  asset_record_title: 'Orphaned Asset Record',
  stock_ID: 'Stock ID',
  wine_name: 'Wine name',
  client_name: 'Client name',
  reserve_code: 'Cust ref/reserve code',
  assignment_transaction_type: 'Transaction type',
  reset: 'RESET',
  assignment_confirm: 'CONFIRM',
  assignment_error_title: 'Error',
  assignment_error_msg: 'An error has occurred  please try again',
  assignment_success_title: 'Success',
  assignment_success_msg: 'Your assingment is now complete',
  assignment_next: 'NEXT',
  lwin_18: 'LWIN18',

  //notifications
  notifications: 'Notifications',
  ticket_comment: 'New comment on ticket',
  ticket_created: 'New ticket',
  photo_taken: 'Photo taken',
  go_ticket: 'Go to Ticket',
  mark_all_read: 'Mark all as read',

  // mobile only
  filter: 'Filter',
  apply: 'Apply',
  clear: 'Clear',
  search: 'Search...',
  search_cases: 'Search by wine name or rotation...',
  search_tickets: 'Search by rotation...',
  empty_list: 'No results to show',
  bulk_action: 'Bulk Action',

  scan_nfc: 'Scan NFC',
  take_photo: 'TAKE PHOTO',
  acknowledgeAllNotifications: 'All notifications acknowledged',
  dismiss: 'Dismiss',
  case_not_found: 'Case not found',
  remove_all: 'Remove All',

  unsupported: 'Unsupported',
  same_lwin: 'You can only group cases containing the same lwin',
  discard_group: 'Discard the previous group',

  // create ticket
  create_ticket: 'Create ticket',
  description: 'Description',
  subject: 'Subject',
  create_button: 'CREATE',
  take_picture: 'TAKE PICTURE',
  enable_nfc: 'Enable NFC',
  post_comment: 'Post Comment',

  //stock cases
  stock_cases: 'Stock cases',
  number_cases: 'Number of cases',
  location: 'Location',

  //transactions
  transaction: 'Transaction',
  region: 'Region',
  verify: 'VERIFY',
  verify_ownership: 'Verify ownership',
  transaction_id: 'Transaction ID',
  transaction_type: 'Type',
  block: 'Block',
  age: 'Age',
  asset_id: 'Asset ID',
  from: 'From',
  to: 'To',
  view_in_algoexlorer: 'VIEW IN ALGOEXPLORER',
  transactions: 'Transactions',

  transaction_overview: 'Transaction overview',
  asset_overview: 'Asset overview',
  account_overview: 'Account overview',
  block_explorer: 'Block explorer',
  ownership_verification_success: 'This user is the owner of the case',
  ownership_verification_failed: 'verification failed',
  accounts: 'Accounts',
  addresses: 'Addresses',
  user_overview: 'User overview',
  location_history: 'Location history',
  see_location_history: 'See location history',

  crate_creation: 'Crate creation',
  crate_location_change: 'Crate location change',
  proof_ownership: 'Proof of ownership',
  opt_in_crate: 'Opt in to crate',
  crate_ownership_transfer: 'Crate ownership transfer',

  // create sales order
  general_information: 'General information',
  delivery: 'Delivery',
  deliveryId: 'Delivery Id',
  customs: 'Customs',
  customer: 'Customer',
  summary: 'Summary',
  fk_customer: 'FK customer',
  bond_type: 'Bond type',
  document_reference: 'Document reference',
  dp_account_reference: 'DP account reference',
  order_date: 'Order date',
  receiving_warehouse_or_shipper: 'Receiving warehouse or shipper',
  delivery_name: 'Delivery name',
  delivery_address_line_1: 'Delivery address line 1',
  delivery_address_line_2: 'Delivery address line 2',
  delivery_address_line_3: 'Delivery address line 3',
  delivery_town_county: 'Delivery town/county',
  delivery_postcode: 'Delivery postal code',
  delivery_country: 'Delivery country',
  contact_name: 'Contact name',
  contact_phone_number: 'Contact phone number',
  fk_sales_order_type: 'FK sales order type',
  site_code: 'Site code',
  delivery_instructions: 'Delivery instructions',
  pick_instructions: 'Pick instructions',
  customer_po_reference: 'Customer PO reference',
  po_reference: 'PO reference',
  booked_or_collection_date: 'Booked or collection date',
  collection_delivery_area: 'Collection delivery area',
  collection_by: 'Collection by',
  vehicle_registration: 'Vehicle registration',
  collection_time: 'Collection time',
  earliest_time: 'Earliest time',
  earliest_time_2: 'Earliest time 2',
  latest_time: 'Latest time',
  latest_time_2: 'Latest time 2',
  awrs: 'AWRS',
  consignee_name: 'Consignee name',
  consignee_address_1: 'Consignee address 1',
  consignee_address_2: 'Consignee address 2',
  consignee_address_3: 'Consignee address 3',
  consignee_town: 'Consignee town',
  consignee_postcode: 'Consignee postcode',
  consignee_country: 'Consignee country',
  payCustomDutyBondTypes: ['P', 'N', 'U', 'T'],
  payCustomDutyDisabledBondTypes: ['P', 'N', 'E', 'X'],
  duty_paid: 'Duty Paid',
  non_ec_export: 'Non EC Export',
  uk_underbond: 'UK Underbond',
  ec_export: 'EC Export',
  northern_ireland_duty_paid: 'Northern Ireland Duty Paid',
  transfer_order: 'Transfer Order',
  sales: 'Sales',
  personal: 'Personal',
  coll: 'COLL',
  collns: 'COLLNS',
  xcar: 'XCAR',
  xcarns: 'XCARNS',
  is_booking_required: 'Warehouse Delivery',
  is_booking_required_api_field: 'Booking required',
  pay_customs_duty: 'Pay customs duty',
  excise_deferment_number: 'Excise deferment number',
  customs_deferment_number: 'Customs deferment number',
  receiving_stock_holding_customers_name: 'Receiving stock holding customers name',
  receiving_customer_code: 'Receiving customer code',
  receiving_customers_site_code: 'Receiving customers site code',
  delivery_type: 'Delivery type',
  select_supplier: 'Select supplier',
  reserve_codes: 'Reserve codes',
  town: 'Town',
  postcode: 'Postcode',
  cus_ref: 'Cus ref/ Reserve Code',

  no_data: 'No data found',
};

export default strings;
