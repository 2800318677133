import { MouseEventHandler, useState } from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import Edit from "@material-ui/icons/Edit";
import { LabelValue, MainButton, SecondaryButton } from "../Common";
import { Email, GetApp, Announcement } from "@material-ui/icons";
import { types, strings } from "common";
import CreateTicketModal from "./CreateTicket";
import { caseStatus, datetimeFormat } from "../../constants";
import { DateTime } from "luxon";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import JSZipUtils from "jszip-utils/lib";
import { formatCurrency } from "../../utils/currency";
import { CaseDetailResponseSchema } from "../../interfaces/case.interface";

const useStyles = makeStyles((theme) => ({
  button: {
    width: theme.spacing(74),
  },
  mainButton: {
    marginTop: theme.spacing(4),
  },
  buttonColumn: {
    display: "flex",
    flex: 1,
    marginTop: theme.spacing(8),
    marginRight: theme.spacing(8),
    justifyContent: "flex-end",
  },
  wineBanner: {
    display: "flex",
    backgroundColor: theme.palette.grey[50],
    marginTop: theme.spacing(8),
  },
  gridContainer: {
    marginLeft: theme.spacing(8),
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
}));

const GridTitle = ({ children }: { children: string }) => (
  <Box paddingBottom={8}>
    <Typography variant="h5">{children}</Typography>
  </Box>
);

GridTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

const WineBanner = ({ data, openModal }: { data: CaseDetailResponseSchema; openModal: MouseEventHandler }) => {
  const classes = useStyles();
  const [openTicketModal, setOpenTicketModal] = useState(false);

  const downloadImages = () => {
    const zip = new JSZip();
    let count = 0;

    data.photos.forEach((photo) => {
      JSZipUtils.getBinaryContent(photo.photoUrl, function (err: Error, res: ArrayBuffer) {
        if (err) {
          throw err;
        }
        try {
          zip.file(count + ".jpg", res, { binary: true });
          count++;
          if (count === data.photos.length) {
            zip.generateAsync({ type: "blob" }).then(function (content) {
              saveAs(content, `stock_${data.id}_photos`);
            });
          }
        } catch (e) {
          console.error(e);
        }
      });
    });
  };

  const emailCreator = () =>
    (document.location.href = `mailto:?body=This is information about ${encodeURIComponent(
      `${data.wineName}:  ${strings.vintage}: ${data.vintage || "-"} 
    ${strings.supplier}: ${data.supplierName || "-"} 
    ${strings.warehouse}: ${data.warehouseShortCode || "-"}  
    ${strings.status}: ${caseStatus[data.status] || "-"}  
    ${strings.bonded_status}: ${data.bondedStatus || "-"} 
    ${strings.po_number}: ${data.purchaseOrderNumber || "-"} 
    ${strings.stock_id}: ${data.stockId || "-"} 
    ${strings.unit_size}: ${data.size || "-"} 
    ${strings.landing_date}: ${data.landingDate || "-"} 
    ${data.note ? `${strings.note}: ${data.note || "-"} ` : ""}
    ${data.photos.length > 0 ? `Photos: ${data.photos.map((p) => p.photoUrl).join(" ")}` : null})}`
    )}`);

  return (
    <Box className={classes.wineBanner}>
      <Grid>
        <ImageGallery items={data.photos as ReactImageGalleryItem[]} showPlayButton={false} />
      </Grid>

      <Grid container className={classes.gridContainer}>
        <Grid item xs={3}>
          <GridTitle>{strings.information}</GridTitle>

          <Grid container direction="column" spacing={8}>
            <Grid item>
              <LabelValue label={strings.supplier} value={data.supplierName} />
            </Grid>
            <Grid item>
              <LabelValue label={strings.warehouse} value={data.warehouseShortCode} />
            </Grid>
            <Grid item>
              <LabelValue label={strings.status} value={caseStatus[data.status]} />
            </Grid>
            <Grid item>
              <LabelValue label={strings.bonded_status} value={data.bondedStatus} />
            </Grid>

            <Grid item>
              <LabelValue label={strings.note} value={data.note || "-"} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <GridTitle>{strings.references}</GridTitle>

          <Grid container direction="column" spacing={8}>
            <Grid item>
              <LabelValue label={strings.rotation} value={data.rotationNumber} />
            </Grid>
            <Grid item>
              <LabelValue label={strings.lwin} value={data.lwin} />
            </Grid>
            <Grid item>
              <LabelValue label={strings.po_number} value={data.purchaseOrderNumber} />
            </Grid>
            <Grid item>
              <LabelValue label={strings.stock_id} value={data.stockId} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <GridTitle>{strings.lot_information}</GridTitle>

          <Grid container direction="column" spacing={8}>
            <Grid item>
              <LabelValue label={strings.unit_size} value={data.size} />
            </Grid>

            <Grid item>
              <LabelValue label={strings.prch_price} value={formatCurrency(data.cwPurchasePrice.toString())} />
            </Grid>
            <Grid item>
              <LabelValue label={strings.market_price} value={data.marketPrice || "-"} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={3}>
          <GridTitle>{strings.record}</GridTitle>

          <Grid container direction="column" spacing={8}>
            <Grid item>
              <LabelValue
                label={strings.creation_date}
                value={DateTime.fromISO(data.createdAt as string).toFormat(datetimeFormat)}
              />
            </Grid>
            <Grid item>
              <LabelValue label={strings.booked} value={data.bookedDate} />
            </Grid>
            <Grid item>
              <LabelValue
                label={strings.landing_date}
                value={data.landingDate ? DateTime.fromISO(data.landingDate).toFormat(datetimeFormat) : "-"}
              />
            </Grid>

            <Grid item>
              <LabelValue
                label={strings.departed_date}
                value={data.departedDate ? DateTime.fromISO(data.departedDate).toFormat(datetimeFormat) : "-"}
              />
            </Grid>

            <Grid item>
              <LabelValue label={strings.current_owner} value={data.clientName || "-"} />
            </Grid>

            <Grid item>
              <LabelValue label={strings.source} value={data.source || "-"} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box className={classes.buttonColumn}>
        <Box>
          <SecondaryButton onClick={openModal} className={classes.button} startIcon={<Edit />}>
            {strings.edit}
          </SecondaryButton>

          <MainButton
            onClick={emailCreator}
            startIcon={<Email />}
            className={`${classes.button} ${classes.mainButton}`}
          >
            {strings.share}
          </MainButton>

          <MainButton
            onClick={downloadImages}
            startIcon={<GetApp />}
            className={`${classes.button} ${classes.mainButton}`}
          >
            {strings.download}
          </MainButton>

          <MainButton
            onClick={() => setOpenTicketModal(true)}
            startIcon={<Announcement />}
            className={`${classes.button} ${classes.mainButton}`}
          >
            {strings.create_ticket}
          </MainButton>
        </Box>
      </Box>
      {openTicketModal && (
        <CreateTicketModal modalVisible={openTicketModal} setModalVisible={setOpenTicketModal} caseIds={[data.id]} />
      )}
    </Box>
  );
};

WineBanner.propTypes = {
  data: types.stockDataShape,
  openModal: PropTypes.func.isRequired,
};

export default WineBanner;
