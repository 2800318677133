import { Box, makeStyles, withStyles } from "@material-ui/core";
import { Publish, Send } from "@material-ui/icons";
import PropTypes from "prop-types";
import { useState } from "react";
import { types, strings } from "common";
import { fetchFile, useResendPreAdvice } from "../../utils/cases";
import { MainButton } from "../Common";
import { ResendModal } from "./index";
import { PreadviceDataSchema } from "../../interfaces/order.interface";

const ButtonRow = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    marginLeft: theme.spacing(8),
    paddingBottom: theme.spacing(3),
  },
}))(Box);

const useStyles = makeStyles((theme) => ({
  rightButton: { marginLeft: theme.spacing(4) },
}));

interface ExportButtonsProps {
  selectedrows: Array<PreadviceDataSchema>;
}

const ExportButtons = ({ selectedrows, ...props }: ExportButtonsProps) => {
  const classes = useStyles();
  const caseIds = selectedrows.map((row) => row.id);

  const [isReSend, setIsReSend] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const { mutateAsync: resendPreAdvice } = useResendPreAdvice();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const apiURL = window.env.REACT_APP_API_URL;
  const url = `${apiURL}/preadvices/export`;

  const handleExportPA = () => {
    fetchFile(url, "PA", caseIds);
  };

  const handleResendPA = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resendPreAdvice({ ids: caseIds })
      .then(() => {
        closeResendPAModal();
      })
      .catch((error) => {
        setErrorMsg(error.message);
      });
  };

  const showResendPAModal = () => {
    setIsReSend(true);
  };

  const closeResendPAModal = () => {
    setIsReSend(false);
    setErrorMsg("");
  };

  return (
    <ButtonRow>
      <MainButton id="order_pa_export" {...props} startIcon={<Publish />} onClick={handleExportPA}>
        {strings.export}
      </MainButton>

      <MainButton
        className={`${classes.rightButton} `}
        id="order_resent"
        {...props}
        startIcon={<Send />}
        onClick={showResendPAModal}
      >
        {strings.resend}
      </MainButton>

      {isReSend && (
        <ResendModal
          modalId="resendPAModal"
          isOpen={isReSend}
          handleEvent={handleResendPA}
          closeModal={closeResendPAModal}
          errorMsg={errorMsg}
        />
      )}
    </ButtonRow>
  );
};

ExportButtons.propTypes = {
  selectedrows: PropTypes.arrayOf(types.stockDataShape).isRequired,
};

export default ExportButtons;
