import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query";
import { useClient } from "../providers/auth-context";
import { paramsToQueryString } from "./api-client";
import { TableSearchSchema, UserResponseData } from "../interfaces/table.interface";
import { ClientType } from "../interfaces/apiClient.interface";
import { UserDetailSchema } from "../interfaces/user.interface";

interface SetUserWarehouseSchema {
  id: string;
  warehouseIds: string[];
}

const getUsersSearch = (client: ClientType<UserResponseData>, params: TableSearchSchema) => ({
  queryKey: ["usersSearch", { params }],
  queryFn: () => client(`users?${paramsToQueryString(params)}`).then((data) => data),
});

function useUsersSearch(params: TableSearchSchema) {
  const client = useClient<UserResponseData>();
  const result = useQuery(getUsersSearch(client, params));

  return { isLoading: result.isLoading, data: result.data };
}

const defaultMutationOptions = (queryClient: QueryClient) => ({
  onSettled: () => queryClient.invalidateQueries("usersSearch"),
});

function useDeleteUser() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation((id) => client(`users/${id}`, { method: "DELETE" }), {
    ...defaultMutationOptions(queryClient),
  });
}

function useSetUserWarehouse() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, warehouseIds }: SetUserWarehouseSchema) =>
      client(`users/${id}/warehouses`, { method: "PUT", body: JSON.stringify({ warehouseIds }) }),
    {
      ...defaultMutationOptions(queryClient),
    }
  );
}

function useUpdateUser() {
  const client = useClient();
  return useMutation(({ id, ...params }: { id: number }) =>
    client(`users/${id}`, { method: "PATCH", body: JSON.stringify(params) })
  );
}

function useCreateUser() {
  const client = useClient();
  const queryClient = useQueryClient();

  return useMutation((params) => client(`users/`, { method: "POST", body: JSON.stringify(params) }), {
    ...defaultMutationOptions(queryClient),
    onSettled: () => {
      queryClient.invalidateQueries("usersSearch");
    },
  });
}

function useLogin() {
  const client = useClient();

  return useMutation((params) => client(`login`, { method: "POST", body: JSON.stringify(params) }));
}

function useLogout() {
  const client = useClient();
  return useMutation(() => client(`logout`, { method: "POST" }));
}

function useRefresh() {
  const client = useClient();
  return useMutation(() => client(`refresh`, { method: "POST" }));
}

function useMyUser(options?: { enabled?: boolean }) {
  const client = useClient<UserDetailSchema>();

  const result = useQuery({
    queryKey: "myUser",
    queryFn: () => client(`users/my-user`, {}).then((data) => data),
    retry: 0,
    ...options,
  });

  return { ...result, data: result.data };
}

export {
  useUsersSearch,
  useDeleteUser,
  useSetUserWarehouse,
  useUpdateUser,
  useCreateUser,
  useLogin,
  useMyUser,
  useLogout,
  useRefresh,
};
