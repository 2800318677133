import { useQuery } from "react-query";
import { useClient } from "../providers/auth-context";
import { paramsToQueryString } from "./api-client";
import { PreadviceResponseData, TableSearchSchema } from "../interfaces/table.interface";
import { ClientType } from "../interfaces/apiClient.interface";

const getPreadvicesSearchConfig = (client: ClientType<PreadviceResponseData>, params: TableSearchSchema) => ({
  queryKey: ["preadvices", { params }],
  queryFn: () => client(`preadvices?${paramsToQueryString(params)}`).then((data) => data),
});

function usePreadvices(params: TableSearchSchema) {
  const client = useClient<PreadviceResponseData>();
  const result = useQuery(getPreadvicesSearchConfig(client, params));

  return { isLoading: result.isLoading, data: result.data };
}

export { usePreadvices };
