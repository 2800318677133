import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { strings, types } from "common";
import { useHistory } from "react-router";
import { LabelValue, MainButton } from "../Common";
import { NotificationPayloadSchema } from "../../interfaces/notification.interface";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.grey.A100,
    padding: theme.spacing(4, 4, 4, 4),
    width: 400,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  text: {
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  button: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    maxHeight: 25,
    minWidth: 40,
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  vintage: {
    marginLeft: theme.spacing(2),
  },
}));

const TicketItem = ({ data }: { data: NotificationPayloadSchema }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleNavigation = () => {
    history.push(`/stock/${data.caseId}`);
  };

  return (
    <Box className={classes.card}>
      <Box className={classes.header} textOverflow="ellipsis">
        <Box className={classes.text}>
          <Box className={classes.row}>
            <Typography variant="body2">{data.wineName}</Typography>
            <Box className={classes.vintage}>
              <Typography>{data.vintage}</Typography>
            </Box>
          </Box>
          {data.caseId && (
            <MainButton className={classes.button} onClick={handleNavigation}>
              {strings.go_case}
            </MainButton>
          )}
        </Box>
      </Box>

      <Box>
        <Grid container>
          <Grid item xs={6}>
            <LabelValue direction="row" label={strings.rotation} value={data.rotationNumber} />
            <LabelValue direction="row" label={strings.lwin} value={data.lwin} />
          </Grid>
          <Grid item xs={6}>
            <LabelValue direction="row" label={strings.size} value={data.size} />
            <LabelValue direction="row" label={strings.stock_id} value={data.stockId} />
          </Grid>
        </Grid>
        <LabelValue direction="row" label={strings.supplier} value={data.supplierName} />
      </Box>
    </Box>
  );
};

TicketItem.propTypes = {
  data: types.stockNotification,
};

export default TicketItem;
