const underbondAddresses = [
  {
    dropdownName: "ADNPLC-ADNAMS PLC, ADNAMS DISTRIBUTION CENTRE, IP18 6SG",
    dpAccountRef: "ADNPLC",
    deliveryName: "ADNAMS PLC",
    deliveryAddress1: "ADNAMS DISTRIBUTION CENTRE",
    deliveryAddress2: "HALESWORTH ROAD",
    deliveryAddress3: "REYDON**MUST PAY CCT**",
    deliveryTownOrCounty: "SOUTHWOLD",
    deliveryPostcode: "IP18 6SG",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "AKWGLO-AKW GLOBAL WAREHOUSING LTD, K333 , M17 1ED",
    dpAccountRef: "AKWGLO",
    deliveryName: "AKW GLOBAL WAREHOUSING LTD",
    deliveryAddress1: "K333 ",
    deliveryAddress2: "FRASER PLACE",
    deliveryAddress3: "TRAFFORD PARK",
    deliveryTownOrCounty: "MANCHESTER",
    deliveryPostcode: "M17 1ED",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "ATOM-ATOM SUPPLIES LTD, UNIT 3, TN9 1RU",
    dpAccountRef: "ATOM",
    deliveryName: "ATOM SUPPLIES LTD",
    deliveryAddress1: "UNIT 3",
    deliveryAddress2: "2-8 MORLEY ROAD",
    deliveryAddress3: "",
    deliveryTownOrCounty: "TONBRIDGE",
    deliveryPostcode: "TN9 1RU",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "ATOMS-ATOM SUPPLIES LTD, UNIT 3-4, A1 INDUSTRIAL ESTATE, EH15 2QA",
    dpAccountRef: "ATOMS",
    deliveryName: "ATOM SUPPLIES LTD",
    deliveryAddress1: "UNIT 3-4, A1 INDUSTRIAL ESTATE",
    deliveryAddress2: "SIR HARRY LAUDER ROAD",
    deliveryAddress3: "",
    deliveryTownOrCounty: "EDINBURGH",
    deliveryPostcode: "EH15 2QA",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "BBRLTD-BB & R LTD, GEMINI, UNIT 1, RG21 6YT",
    dpAccountRef: "BBRLTD",
    deliveryName: "BB & R LTD",
    deliveryAddress1: "GEMINI, UNIT 1",
    deliveryAddress2: "GEMINI BUSINESS PARK",
    deliveryAddress3: "HAMILTON CLOSE",
    deliveryTownOrCounty: "BASINGSTOKE",
    deliveryPostcode: "RG21 6YT",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "BERRY-BERRY BROS & RUDD LTD, NO 6 BOND, RG21 6YB",
    dpAccountRef: "BERRY",
    deliveryName: "Berry Bros & Rudd Ltd",
    deliveryAddress1: "NO 6 Bond",
    deliveryAddress2: "Hamilton Close",
    deliveryAddress3: "Houndmills **CCT PAID**",
    deliveryTownOrCounty: "BASINGSTOKE",
    deliveryPostcode: "RG21 6YB",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "BERRY2-BERRY BROTHERS & RUDD LTD, GATEWAY HOUSE, RG24 8QN",
    dpAccountRef: "BERRY2",
    deliveryName: "Berry Brothers & Rudd Ltd",
    deliveryAddress1: "Gateway House",
    deliveryAddress2: "Kingsland Ind Est",
    deliveryAddress3: "Wade Road",
    deliveryTownOrCounty: "BASINGSTOKE",
    deliveryPostcode: "RG24 8QN",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "BERRY3-BERRY BROTHERS, UNIT 7, SP11 8E2",
    dpAccountRef: "BERRY3",
    deliveryName: "Berry Brothers",
    deliveryAddress1: "UNIT 7, PIONEER ROAD, ANDOVER BUSINESS PARK",
    deliveryAddress2: "Kingsland Ind Est",
    deliveryAddress3: "Wade Road",
    deliveryTownOrCounty: "ANDOVER",
    deliveryPostcode: "SP11 8EZ",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "BONCOFW-BONCOEUR FINE WINES LTD, MOOR PARK MOOR ROAD, DL10 5PR",
    dpAccountRef: "BONCOFW",
    deliveryName: "BONCOEUR FINE WINES LTD",
    deliveryAddress1: "MOOR PARK MOOR ROAD",
    deliveryAddress2: "MELSONBY",
    deliveryAddress3: "",
    deliveryTownOrCounty: "RICHMOND",
    deliveryPostcode: "DL10 5PR",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "BWALOG-BWA LOGISTICS LIMITED, UNITS 5 & 6, DA17 6AN",
    dpAccountRef: "BWALOG",
    deliveryName: "BWA LOGISTICS LIMITED",
    deliveryAddress1: "UNITS 5 & 6",
    deliveryAddress2: "MULBERRY WAY",
    deliveryAddress3: "",
    deliveryTownOrCounty: "BELVEDERE",
    deliveryPostcode: "DA17 6AN",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "CARFOR-CARGO FORWARDING LTD, TRANSIT 1 **CCT MUST BE PAID**, BT3 9LB",
    dpAccountRef: "CARFOR",
    deliveryName: "CARGO FORWARDING LTD",
    deliveryAddress1: "TRANSIT 1 **CCT MUST BE PAID**",
    deliveryAddress2: "WESTBANK WAY",
    deliveryAddress3: "",
    deliveryTownOrCounty: "BELFAST",
    deliveryPostcode: "BT3 9LB",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "CHICBON-CHICHESTER BOND LTD, UNIT 3 CROMPTON WAY, PO15 5SS",
    dpAccountRef: "CHICBON",
    deliveryName: "CHICHESTER BOND LTD",
    deliveryAddress1: "UNIT 3 CROMPTON WAY",
    deliveryAddress2: "SEGENWORTH WEST",
    deliveryAddress3: "",
    deliveryTownOrCounty: "FAREHAM",
    deliveryPostcode: "PO15 5SS",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "CIDLTD2-CONNOISSEUR INTERNATIONAL DISTRIBUTION LTD, UNIT 9 EGHAM BUSINESS PARK, TW20 8EY",
    dpAccountRef: "CIDLTD2",
    deliveryName: "CONNOISSEUR INTERNATIONAL DISTRIBUTION LTD",
    deliveryAddress1: "UNIT 9 EGHAM BUSINESS PARK",
    deliveryAddress2: "TEN ACRE LANE",
    deliveryAddress3: "",
    deliveryTownOrCounty: "EGHAM",
    deliveryPostcode: "TW20 8EY",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "CMBCOH-CMBC SUPPLY LIMITED, OAKHAM DISTRIBUTION CENTER, NG18 5BY",
    dpAccountRef: "CMBCOH",
    deliveryName: "CMBC SUPPLY LIMITED",
    deliveryAddress1: "OAKHAM DISTRIBUTION CENTER",
    deliveryAddress2: "OAKHAM BUISNESS PARK",
    deliveryAddress3: "LOWER OAKHAM WAY",
    deliveryTownOrCounty: "Mansfield",
    deliveryPostcode: "NG18 5BY",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "COND-CONDOR FERRIES LIMITED, CONTINENTAL FREIGHT FERRY, BH15 4AJ",
    dpAccountRef: "COND",
    deliveryName: "CONDOR FERRIES LIMITED",
    deliveryAddress1: "CONTINENTAL FREIGHT FERRY",
    deliveryAddress2: "TERMINAL, NEW HARBOUR ROAD",
    deliveryAddress3: "SOUTH HAMWORTHY",
    deliveryTownOrCounty: "Mansfield",
    deliveryPostcode: "BH15 4AJ",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LANDW-Coterie Vaults Limited, Port One Logistics Park Unit 6, IP6 0FL",
    dpAccountRef: "COTERIE",
    deliveryName: "Coterie Vaults Limited",
    deliveryAddress1: "Port One Logistics Park Unit 6, Blackacre Road, Great Blakenham",
    deliveryAddress2: "",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Ipswish",
    deliveryPostcode: "IP6 0FL",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "DAVIES-DAVIES TURNER PLC, UNIT A1 CALDER WAY, SL3 0BQ",
    dpAccountRef: "DAVIES",
    deliveryName: "Davies Turner PLC",
    deliveryAddress1: "Unit A1 Calder Way",
    deliveryAddress2: "Colnbrook",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Slough",
    deliveryPostcode: "SL3 0BQ",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "DFWOOL-D F WAREHOUSES LTD, UNIT D, RH10 9AG",
    dpAccountRef: "DFWOOL",
    deliveryName: "D F WAREHOUSES LTD",
    deliveryAddress1: "UNIT D",
    deliveryAddress2: "WOOLBOROUGH LANE IND ESTATE",
    deliveryAddress3: "",
    deliveryTownOrCounty: "CRAWLEY",
    deliveryPostcode: "RH10 9AG",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "DIR2-DIRECT WINES LTD **PAY CCT**, GLOUCESTER DISTRIBTION CENTRE, GL3 4AQ",
    dpAccountRef: "DIR2",
    deliveryName: "DIRECT WINES LTD **PAY CCT**",
    deliveryAddress1: "GLOUCESTER DISTRIBTION CENTRE",
    deliveryAddress2: "HURRICANE HOUSE",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Brockworth",
    deliveryPostcode: "GL3 4AQ",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "DURHAM-LANCHESTER WINE CELLARS LTD, UNIT 2 GREENCROFT IND.ESTATE, DH9 7XP",
    dpAccountRef: "DURHAM",
    deliveryName: "LANCHESTER WINE CELLARS LTD",
    deliveryAddress1: "UNIT 2 GREENCROFT IND.ESTATE",
    deliveryAddress2: "TOWER ROAD, ANNFIELD PLAIN",
    deliveryAddress3: "",
    deliveryTownOrCounty: "STANLEY",
    deliveryPostcode: "DH9 7XP",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "EHD2-EHD LONDON NO1 BOND LTD SUNBURY, UNIT E DOLPHIN ESTATE, TW16 7HE",
    dpAccountRef: "EHD2",
    deliveryName: "EHD London No1 Bond Ltd Sunbury",
    deliveryAddress1: "Unit E Dolphin Estate",
    deliveryAddress2: "Windmill Road",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Sunbury On Thames",
    deliveryPostcode: "TW16 7HE",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "EHD3-EHD LONDON NO1 BOND LTD (CHILMARK), RAF CHILMARK, SP3 5DU",
    dpAccountRef: "EHD3",
    deliveryName: "EHD London No1 Bond Ltd (Chilmark)",
    deliveryAddress1: "RAF Chilmark",
    deliveryAddress2: "Ladydown",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Chilmark",
    deliveryPostcode: "SP3 5DU",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "ELLIS-ELLIS OF RICHMOND LTD, UNIT B1**MUSY PAY CCT**, TW13 6JE",
    dpAccountRef: "ELLIS",
    deliveryName: "Ellis Of Richmond Ltd",
    deliveryAddress1: "Unit B1**Musy Pay CCT**",
    deliveryAddress2: "The Links",
    deliveryAddress3: "Popham Close",
    deliveryTownOrCounty: "Feltham",
    deliveryPostcode: "TW13 6JE",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "EURO1-EUROPA WAREHOUSE LIMITED, EUROPA HOUSE, B76 9EH",
    dpAccountRef: "EURO1",
    deliveryName: "EUROPA WAREHOUSE LIMITED",
    deliveryAddress1: "EUROPA HOUSE",
    deliveryAddress2: "MIDPOINT WAY",
    deliveryAddress3: "",
    deliveryTownOrCounty: "MINWORTH",
    deliveryPostcode: "B76 9EH",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "EWGA-EWGA LTD, HYNING HOME FARM, LA6 1HP",
    dpAccountRef: "EWGA",
    deliveryName: "EWGA LTD",
    deliveryAddress1: "HYNING HOME FARM",
    deliveryAddress2: "DOCK ACRES",
    deliveryAddress3: "",
    deliveryTownOrCounty: "CARNFORTH",
    deliveryPostcode: "LA6 1HP",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "EWGA2-EWGA LTD, THE OLD BREWERY, GL12 8NB",
    dpAccountRef: "EWGA2",
    deliveryName: "EWGA LTD",
    deliveryAddress1: "THE OLD BREWERY",
    deliveryAddress2: "61 STATION ROAD",
    deliveryAddress3: "WICKWAR",
    deliveryTownOrCounty: "WOTTON-UNDER-EDGE",
    deliveryPostcode: "GL12 8NB",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "EWL-ENOTRIA WINE CELLARS LTD, ENOTRIA HOUSE , NW10 7RX",
    dpAccountRef: "EWL",
    deliveryName: "Enotria Wine Cellars Ltd",
    deliveryAddress1: "Enotria House ",
    deliveryAddress2: "23 Cumberland Avenue",
    deliveryAddress3: "Park Royal",
    deliveryTownOrCounty: "LONDON ",
    deliveryPostcode: "NW10 7RX",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "FSPEED-FERRYSPEED, ACKWORTH ROAD, PO3 5JT",
    dpAccountRef: "FSPEED",
    deliveryName: "FerrySpeed",
    deliveryAddress1: "Ackworth Road",
    deliveryAddress2: "Hilsea",
    deliveryAddress3: "",
    deliveryTownOrCounty: "PORTSMOUTH",
    deliveryPostcode: "PO3 5JT",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "FTSFR-F T S (FREIGHT FORWARDING) LTD, UNIT 10 LIDDALL WAY, UB7 8PG",
    dpAccountRef: "FTSFR",
    deliveryName: "F T S (FREIGHT FORWARDING) LTD",
    deliveryAddress1: "UNIT 10 LIDDALL WAY",
    deliveryAddress2: "WEST DRAYTON ",
    deliveryAddress3: "",
    deliveryTownOrCounty: "WEST DRAYTON",
    deliveryPostcode: "UB7 8PG",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "GBDCC-GLOBAL GRANDS LTD, GLOBAL BRANDS DISTRIBUTION CENTRE, S45 9NU",
    dpAccountRef: "GBDCC",
    deliveryName: "GLOBAL GRANDS LTD",
    deliveryAddress1: "GLOBAL BRANDS DISTRIBUTION CENTRE",
    deliveryAddress2: "BRIDGE STREET",
    deliveryAddress3: "CLAY CROSS ",
    deliveryTownOrCounty: "CHESTERFIELD",
    deliveryPostcode: "S45 9NU",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "GBEAR-GREAT BEAR DISTRIBUTION LIMITED , UNIT J, RD PARK, , EN11 0BT",
    dpAccountRef: "GBEAR",
    deliveryName: "GREAT BEAR DISTRIBUTION LIMITED ",
    deliveryAddress1: "UNIT J, RD PARK, ",
    deliveryAddress2: "ST.MARTINS ROAD",
    deliveryAddress3: "",
    deliveryTownOrCounty: "HODDESDON",
    deliveryPostcode: "EN11 0BT",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "GXOLOG-GXO LOGISTICS UK LTD, LEVELS 1, 2 AND 3, UNIT 1, SK17 9JW",
    dpAccountRef: "GXOLOG",
    deliveryName: "GXO LOGISTICS UK LTD",
    deliveryAddress1: "LEVELS 1, 2 AND 3, UNIT 1",
    deliveryAddress2: "HARPUR INDUSTRIAL ESTATE",
    deliveryAddress3: "HARPUR HILL",
    deliveryTownOrCounty: "BUXTON",
    deliveryPostcode: "SK17 9JW",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "HSETED-HOUSE OF TOWNEND LTD, MELTON WEST BUSINESS PARK, HU14 3HE",
    dpAccountRef: "HSETED",
    deliveryName: "HOUSE OF TOWNEND LTD",
    deliveryAddress1: "MELTON WEST BUSINESS PARK",
    deliveryAddress2: "MELTON ",
    deliveryAddress3: "",
    deliveryTownOrCounty: "NORTH FERRIBY",
    deliveryPostcode: "HU14 3HE",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "INBOND2-INBOND LIMITED, MALLEABLE WAY, TS18 2QX",
    dpAccountRef: "INBOND2",
    deliveryName: "INBOND LIMITED",
    deliveryAddress1: "MALLEABLE WAY",
    deliveryTownOrCounty: "STOCKTON-ON-TEES",
    deliveryPostcode: "TS18 2QX",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "JHALL-JAMES HALL & CO HOLDINGS LTD, BOWLAND VIEW, PR2 5QT",
    dpAccountRef: "JHALL",
    deliveryName: "JAMES HALL & CO HOLDINGS LTD",
    deliveryAddress1: "BOWLAND VIEW",
    deliveryAddress2: "PRESTON",
    deliveryTownOrCounty: "PRESTON",
    deliveryPostcode: "PR2 5QT",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "K&N-KUEHNE & NAGEL LTD, UNIT DC3, MIDPOINT WAY, B76 9EH",
    dpAccountRef: "K&N",
    deliveryName: "KUEHNE & NAGEL LTD",
    deliveryAddress1: "UNIT DC3, MIDPOINT WAY",
    deliveryAddress2: "PROLOGIS PARK",
    deliveryTownOrCounty: "Minworth",
    deliveryPostcode: "B76 9EH ",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "KINGS-KINGSLAND DRINKS LTD, FAIRHILLS ROAD, M44 6BD",
    dpAccountRef: "KINGS",
    deliveryName: "KINGSLAND DRINKS LTD",
    deliveryAddress1: "FAIRHILLS ROAD",
    deliveryAddress2: "IRLAM",
    deliveryTownOrCounty: "MANCHESTER",
    deliveryPostcode: "M44 6BD",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LANTAW-LANCHESTER WINE CELLAR LTD, FELLING WAREHOUSE , NE10 0ES",
    dpAccountRef: "LANTAW",
    deliveryName: "LANCHESTER WINE CELLAR LTD",
    deliveryAddress1: "FELLING WAREHOUSE ",
    deliveryAddress2: "NEST ROAD",
    deliveryAddress3: "",
    deliveryTownOrCounty: "GATESHEAD",
    deliveryPostcode: "NE10 0ES",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LAW-LAW DISTRIBUTION LTD , UNIT A HAYDOCK CROSS , WA11 9UX",
    dpAccountRef: "LAW",
    deliveryName: "LAW DISTRIBUTION LTD ",
    deliveryAddress1: "UNIT A  HAYDOCK CROSS ",
    deliveryAddress2: "KILBUCK LANE ",
    deliveryAddress3: "ST HELENS",
    deliveryTownOrCounty: "HAYDOCK",
    deliveryPostcode: "WA11 9UX",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LCBAW-LONDON CITY BOND LTD, UNIT E, CB22 3TJ",
    dpAccountRef: "LCBAW",
    deliveryName: "LONDON CITY BOND LTD",
    deliveryAddress1: "UNIT E",
    deliveryAddress2: "DALES MANOR BUSINESS PARK",
    deliveryAddress3: "GROVE ROAD",
    deliveryTownOrCounty: "SAWSTON",
    deliveryPostcode: "CB22 3TJ",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LCBBG-LONDON CITY BOND LTD, UNIT 2, DE13 8FL",
    dpAccountRef: "LCBBG",
    deliveryName: "LONDON CITY BOND LTD",
    deliveryAddress1: "UNIT 2",
    deliveryAddress2: "GATEWAY ROAD",
    deliveryAddress3: "BARTON UNDER NEEDWOOD",
    deliveryTownOrCounty: "BURTON ON TRENT",
    deliveryPostcode: "DE13 8FL",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LCBCR-LONDON CITY BOND LTD, UNITS 61-64, IG11 0JH",
    dpAccountRef: "LCBCR",
    deliveryName: "LONDON CITY BOND LTD",
    deliveryAddress1: "UNITS 61-64",
    deliveryAddress2: "CREEK ROAD",
    deliveryAddress3: "",
    deliveryTownOrCounty: "BARKING",
    deliveryPostcode: "IG11 0JH",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LCBDS-LONDON CITY BOND LTD, GLOBAL WAREHOUSE, DE14 2LE",
    dpAccountRef: "LCBDS",
    deliveryName: "LONDON CITY BOND LTD",
    deliveryAddress1: "GLOBAL WAREHOUSE",
    deliveryAddress2: "DERBY STREET",
    deliveryAddress3: "",
    deliveryTownOrCounty: "BURTON ON TRENT",
    deliveryPostcode: "DE14 2LE",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LCBDT-LONDON CITY BOND LTD, DINTON WOODS, SP3 5HB",
    dpAccountRef: "LCBDT",
    deliveryName: "LONDON CITY BOND LTD",
    deliveryAddress1: "DINTON WOODS",
    deliveryAddress2: "CATHERINE FORD LANE",
    deliveryAddress3: "",
    deliveryTownOrCounty: "SALISBURY",
    deliveryPostcode: "SP3 5HB",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LCBEL-LONDON CITY BOND, UNIT 1 TOWNGATE LINK, LS9 0SE",
    dpAccountRef: "LCBEL",
    deliveryName: "LONDON CITY BOND",
    deliveryAddress1: "UNIT 1 TOWNGATE LINK",
    deliveryAddress2: "CROSS GREEN WAY",
    deliveryAddress3: "",
    deliveryTownOrCounty: "LEEDS",
    deliveryPostcode: "LS9 0SE",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LCBFP-LONDON CITY BOND, UNIT 2 WELLINGTON TRILOGY, WS13 8RZ",
    dpAccountRef: "LCBFP",
    deliveryName: "LONDON CITY BOND",
    deliveryAddress1: "Unit 2 Wellington Trilogy",
    deliveryAddress2: "Wellington Crescent",
    deliveryAddress3: "Fradley Park",
    deliveryTownOrCounty: "Lichfield",
    deliveryPostcode: "WS13 8RZ",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LCBHT-LONDON CITY BOND LTD, PENILEE ROAD, G52 4UU",
    dpAccountRef: "LCBHT",
    deliveryName: "LONDON CITY BOND LTD",
    deliveryAddress1: "PENILEE ROAD",
    deliveryAddress2: "HILLINGTON",
    deliveryAddress3: "",
    deliveryTownOrCounty: "GLASGOW",
    deliveryPostcode: "G52 4UU",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LCBKD-LONDON CITY BOND, DRAKELOW TUNNELS, DY11 5RZ",
    dpAccountRef: "LCBKD",
    deliveryName: "London City Bond",
    deliveryAddress1: "Drakelow Tunnels",
    deliveryAddress2: "Kingsford Lane",
    deliveryAddress3: "Wolverley",
    deliveryTownOrCounty: "KIDDERMINSTER",
    deliveryPostcode: "DY11 5RZ",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LCBMM-LONDON CITY BOND LTD, HANGERS 1-4, SN12 6SS",
    dpAccountRef: "LCBMM",
    deliveryName: "LONDON CITY BOND LTD",
    deliveryAddress1: "HANGERS 1-4",
    deliveryAddress2: "LANCASTER ROAD",
    deliveryAddress3: "BOWERHILL INDUSTRIAL ESTATE",
    deliveryTownOrCounty: "MELKSHAM",
    deliveryPostcode: "SN12 6SS",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LCBRR-LONDON CITY BOND LTD, OLYMPUS, IG11 0EG",
    dpAccountRef: "LCBRR",
    deliveryName: "LONDON CITY BOND LTD",
    deliveryAddress1: "OLYMPUS",
    deliveryAddress2: "91-101 RIVER ROAD",
    deliveryAddress3: "",
    deliveryTownOrCounty: "BARKING",
    deliveryPostcode: "IG11 0EG",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LCBSH-LONDON CITY BOND LTD, LONDON GATEWAY LG146, SS17 9ER",
    dpAccountRef: "LCBSH",
    deliveryName: "LONDON CITY BOND LTD",
    deliveryAddress1: "LONDON GATEWAY LG146",
    deliveryAddress2: "WEST 5 ",
    deliveryAddress3: "NORTH SEA CROSSING",
    deliveryTownOrCounty: "STANFORD LE HOPE",
    deliveryPostcode: "SS17 9ER",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LCBTD-LONDON CITY BOND LTD, 29-30 BERTHS, RM18 7EH",
    dpAccountRef: "LCBTD",
    deliveryName: "LONDON CITY BOND LTD",
    deliveryAddress1: "29-30 BERTHS",
    deliveryAddress2: "TILBURY FREEPORT",
    deliveryAddress3: "",
    deliveryTownOrCounty: "TILBURY",
    deliveryPostcode: "RM18 7EH",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LCBVT-LONDON CITY BOND LTD, ETON PARK, DE14 1RY",
    dpAccountRef: "LCBVT",
    deliveryName: "LONDON CITY BOND LTD",
    deliveryAddress1: "ETON PARK",
    deliveryAddress2: "DERBY TURN",
    deliveryAddress3: "",
    deliveryTownOrCounty: "BURTON ON TRENT",
    deliveryPostcode: "DE14 1RY",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LCBWT-LONDON CITY BOND LTD, THURROCK 162, RM20 3AX",
    dpAccountRef: "LCBWT",
    deliveryName: "LONDON CITY BOND LTD",
    deliveryAddress1: "THURROCK 162",
    deliveryAddress2: "BARCLAY WAY",
    deliveryAddress3: "",
    deliveryTownOrCounty: "GRAYS",
    deliveryPostcode: "RM20 3AX",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LERICH-LE-RICHE, FERRYSPEED (CI) LTD, PO3 5JT",
    dpAccountRef: "LERICH",
    deliveryName: "LE-RICHE",
    deliveryAddress1: "FERRYSPEED (CI) LTD",
    deliveryAddress2: "ACKWORTH ROAD",
    deliveryAddress3: "HILSEA",
    deliveryTownOrCounty: "PORTSMOUTH",
    deliveryPostcode: "PO3 5JT",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LIONWSL-LIONCFROFT WHOLESALE LIMITED, UPPER THOMAS STREET, B6 5AD",
    dpAccountRef: "LIONWSL",
    deliveryName: "LIONCFROFT WHOLESALE LIMITED",
    deliveryAddress1: "UPPER THOMAS STREET",
    deliveryAddress2: "",
    deliveryAddress3: "",
    deliveryTownOrCounty: "BIRMINGHAM",
    deliveryPostcode: "B6 5AD",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LWCBLAY-LANCHESTER WINE CELLARS LTD, CHURCHILL WORKS, NE21 5TX",
    dpAccountRef: "LWCBLAY",
    deliveryName: "LANCHESTER WINE CELLARS LTD",
    deliveryAddress1: "CHURCHILL WORKS",
    deliveryAddress2: "COWEN ROAD",
    deliveryAddress3: "",
    deliveryTownOrCounty: "BLAYDON-ON-TYNE",
    deliveryPostcode: "NE21 5TX",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LWCMAN-L.W.C. DRINKS LTD, CHADDERTON BOND, M24 1SW",
    dpAccountRef: "LWCMAN",
    deliveryName: "L.W.C. Drinks Ltd",
    deliveryAddress1: "Chadderton Bond",
    deliveryAddress2: "Greenside Way",
    deliveryAddress3: "Greengate",
    deliveryTownOrCounty: "Manchester",
    deliveryPostcode: "M24 1SW",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "MARK3-MARK 3 INTERNATIONAL LTD, UNIT 5, OX29 4JB",
    dpAccountRef: "MARK3",
    deliveryName: "MARK 3 INTERNATIONAL LTD",
    deliveryAddress1: "UNIT 5",
    deliveryAddress2: "SOUTHFIELD ROAD",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Eynsham",
    deliveryPostcode: "OX29 4JB",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "MASTON-MARSTON'S PLC , OAKHAM DISTRIBUTION CENTRE, NG18 5BY",
    dpAccountRef: "MASTON",
    deliveryName: "MARSTON'S PLC ",
    deliveryAddress1: "OAKHAM DISTRIBUTION CENTRE",
    deliveryAddress2: "LOWER OAKHAM WAY",
    deliveryAddress3: "",
    deliveryTownOrCounty: "MANSFIELD",
    deliveryPostcode: "NG18 5BY",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "MATHEW-MATTHEW CLARK WHOLESALE LTD, SHED 4 COMPLEX, BS20 7XL",
    dpAccountRef: "MATHEW",
    deliveryName: "MATTHEW CLARK WHOLESALE LTD",
    deliveryAddress1: "SHED 4 COMPLEX",
    deliveryAddress2: "ROYAL PORTBURY DOCK",
    deliveryAddress3: "PORTBURY",
    deliveryTownOrCounty: "BRISTOL",
    deliveryPostcode: "BS20 7XL",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "MCP-MORGENROT CHEVALIERS PLC, UNIT 2 CANARY WAY, M27 8AW",
    dpAccountRef: "MCP",
    deliveryName: "MORGENROT CHEVALIERS PLC",
    deliveryAddress1: "UNIT 2  CANARY WAY",
    deliveryAddress2: "AGECROFT COMMERCE PARK",
    deliveryAddress3: "SWINTON  MUST PAY CCT ",
    deliveryTownOrCounty: "MANCHESTER",
    deliveryPostcode: "M27 8AW",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "MEACHS-MEACHERS GLOBAL LOGISTICS LTD, UNIT 26/27 MAJESTIC ROAD, SO16 0YT",
    dpAccountRef: "MEACHS",
    deliveryName: "MEACHERS GLOBAL LOGISTICS LTD",
    deliveryAddress1: "UNIT 26/27 MAJESTIC ROAD",
    deliveryAddress2: "NURSLING INDUSTRIAL ESTATE",
    deliveryAddress3: "",
    deliveryTownOrCounty: "SOUTHAMPTON",
    deliveryPostcode: "SO16 0YT",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "MORTON-MORTON MEADOW LOGISTICS LTD, BROOKFOOT BUSINESS CENTRE, HD6 2RW",
    dpAccountRef: "MORTON",
    deliveryName: "MORTON MEADOW LOGISTICS LTD",
    deliveryAddress1: "BROOKFOOT BUSINESS CENTRE",
    deliveryAddress2: "ELLAND ROAD",
    deliveryAddress3: "",
    deliveryTownOrCounty: "BRIGHOUSE",
    deliveryPostcode: "HD6 2RW",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "NICARFOR-CARGO FORWARDING LTD, TRANSIT 1 **CCT MUST BE PAID**, BT3 9LB",
    dpAccountRef: "NICARFOR",
    deliveryName: "CARGO FORWARDING LTD",
    deliveryAddress1: "TRANSIT 1 **CCT MUST BE PAID**",
    deliveryAddress2: "WESTBANK WAY",
    deliveryAddress3: "",
    deliveryTownOrCounty: "BELFAST",
    deliveryPostcode: "BT3 9LB",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "NI Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "NIDRINKSL-DRINKSOLOGY LIMITED , UNIT 4, RYAN LANE, 133 BALLYGOWAN ROAD , BT5 7TZ",
    dpAccountRef: "NIDRINKSL",
    deliveryName: "DRINKSOLOGY LIMITED ",
    deliveryAddress1: "UNIT 4, RYAN LANE, 133 BALLYGOWAN ROAD ",
    deliveryAddress2: "",
    deliveryAddress3: "",
    deliveryTownOrCounty: "BELFAST",
    deliveryPostcode: "BT5 7TZ",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "NI Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "NIJAMESE-JAMES E MCCABE LTD, 4 ANNAGH DRIVE, BT63 5WF",
    dpAccountRef: "NIJAMESE",
    deliveryName: "JAMES E McCABE LTD",
    deliveryAddress1: "4 ANNAGH DRIVE",
    deliveryAddress2: "CARN CRAIGAVON ",
    deliveryAddress3: "",
    deliveryTownOrCounty: "CRAIGAVON",
    deliveryPostcode: "BT63 5WF",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "NI Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "NIMUSTDIST-MUSGRAVE DISTRIBUTION LIMITED, DRINKS INC, , BT12 6SJ",
    dpAccountRef: "NIMUSTDIST",
    deliveryName: "MUSGRAVE DISTRIBUTION LIMITED",
    deliveryAddress1: "DRINKS INC, ",
    deliveryAddress2: "4 FALCON ROAD",
    deliveryAddress3: "",
    deliveryTownOrCounty: "BELFAST",
    deliveryPostcode: "BT12 6SJ",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "NI Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "NISHANON-SHANNON TRANSPORT & , WAREHOUSING COMPANY, BT36 4PX",
    dpAccountRef: "NISHANON",
    deliveryName: "SHANNON TRANSPORT & ",
    deliveryAddress1: "WAREHOUSING COMPANY",
    deliveryAddress2: "52 MALLUSK ROAD, MULLUSK",
    deliveryAddress3: "INDUSTRIAL ESTATE",
    deliveryTownOrCounty: "NEWTOWNABBEY",
    deliveryPostcode: "BT36 4PX",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "NI Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "NIUWMLTD-UNITED WINE MERCHANTS LTD, UNIT 5 SILVERWOOD BUSINESS PK, BT66 6SY",
    dpAccountRef: "NIUWMLTD",
    deliveryName: "UNITED WINE MERCHANTS LTD",
    deliveryAddress1: "UNIT 5 SILVERWOOD BUSINESS PK",
    deliveryAddress2: "LURGAN",
    deliveryAddress3: "",
    deliveryTownOrCounty: "CRAIGAVON",
    deliveryPostcode: "BT66 6SY",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "NI Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "OCTWIL-OCTAVIAN WINE SERVICE , EASTLAYS **CCT PAID ONLY**, SN13 9PP",
    dpAccountRef: "OCTWIL",
    deliveryName: "Octavian Wine Service ",
    deliveryAddress1: "Eastlays **CCT PAID ONLY**",
    deliveryAddress2: "Gastard",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Corsham",
    deliveryPostcode: "SN13 9PP",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "OWSLTD-OCTAVIAN WINE SERVICES COLERNE, HANGAR 25 , SN14 8AT",
    dpAccountRef: "OWSLTD",
    deliveryName: "Octavian Wine Services Colerne",
    deliveryAddress1: "Hangar 25 ",
    deliveryAddress2: "Site 2  Bath Road",
    deliveryAddress3: "Colerne ***MUST BE CCT PAY***",
    deliveryTownOrCounty: "Chippenham",
    deliveryPostcode: "SN14 8AT",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "RARTER-RARTER LTD, LEEDS NO 2 WAREHOUSE, LS19 7XS",
    dpAccountRef: "RARTER",
    deliveryName: "Rarter Ltd",
    deliveryAddress1: "Leeds No 2 Warehouse",
    deliveryAddress2: "Unit 11 Yeadon Airport Ind Estate",
    deliveryAddress3: "Harrogate Road",
    deliveryTownOrCounty: "Yeadon",
    deliveryPostcode: "LS19 7XS",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "RDMWIN-RDM WINES LIMITED, 242 PENARTH ROAD, CF11 8TU",
    dpAccountRef: "RDMWIN",
    deliveryName: "RDM WINES LIMITED",
    deliveryAddress1: "242 PENARTH ROAD",
    deliveryAddress2: "CARDIFF",
    deliveryAddress3: "A/C",
    deliveryTownOrCounty: "CARDIFF",
    deliveryPostcode: "CF11 8TU",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "RSM-RSM SOLUTIONS (NE) LTD, UNIT 11 SOWERBY WAY, TS16 0RB",
    dpAccountRef: "RSM",
    deliveryName: "RSM SOLUTIONS (NE) LTD",
    deliveryAddress1: "UNIT 11 SOWERBY WAY",
    deliveryAddress2: "DURHAM LANE INDUSTRIAL PARK",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Stockton-on-Tees",
    deliveryPostcode: "TS16 0RB",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "SEC-SECKFORD WINES LTD ** MUST PAY CCT**, A/C , IP12 1PE",
    dpAccountRef: "SEC",
    deliveryName: "Seckford Wines Ltd ** MUST PAY CCT**",
    deliveryAddress1: "A/C         ",
    deliveryAddress2: "Dock Lane",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Melton",
    deliveryPostcode: "IP12 1PE",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "STAUS2-ST AUSTELL BREWERY CO LTD, CORNWALL REGIONAL DIST. CENTRE, TR9 6SX",
    dpAccountRef: "STAUS2",
    deliveryName: "ST AUSTELL BREWERY CO LTD",
    deliveryAddress1: "CORNWALL REGIONAL DIST. CENTRE",
    deliveryAddress2: "HURLING WAY",
    deliveryAddress3: "ST COLUMB MAJOR BUSINESS PARK",
    deliveryTownOrCounty: "Saint Columb Major",
    deliveryPostcode: "TR9 6SX",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "SWL3-SEABROOK WAREHOUSING LTD, ADMIRAL HOUSE, RM20 3LG",
    dpAccountRef: "SWL3",
    deliveryName: "SEABROOK WAREHOUSING LTD",
    deliveryAddress1: "ADMIRAL HOUSE",
    deliveryAddress2: "LONDON ROAD",
    deliveryAddress3: "",
    deliveryTownOrCounty: "GRAYS",
    deliveryPostcode: "RM20 3LG",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TANBON-TANNERS WINES, BONDED WAREHOUSE (UNIT 2), SY21 7DF",
    dpAccountRef: "TANBON",
    deliveryName: "TANNERS WINES",
    deliveryAddress1: "BONDED WAREHOUSE (UNIT 2)",
    deliveryAddress2: "SEVERN FARM ENTERPRISE PARK",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Welshpool",
    deliveryPostcode: "SY21 7DF",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TESCOCV-TESCO STORES LTD, TESCO DISTRIBUTION , CV23 8YH",
    dpAccountRef: "TESCOCV",
    deliveryName: "TESCO STORES LTD",
    deliveryAddress1: "TESCO DISTRIBUTION ",
    deliveryAddress2: "DAVENTRY GROCERY",
    deliveryAddress3: "STEPHENSON WAY",
    deliveryTownOrCounty: "KILSBY",
    deliveryPostcode: "CV23 8YH",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TRADE1-TRADETEAM LTD, TRADETEAM DEPOT, RG2 0BS",
    dpAccountRef: "TRADE1",
    deliveryName: "TRADETEAM LTD",
    deliveryAddress1: "TRADETEAM DEPOT",
    deliveryAddress2: "GILLETTE WAY",
    deliveryAddress3: "",
    deliveryTownOrCounty: "READING",
    deliveryPostcode: "RG2 0BS",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TRADEGL-TRADETEAM LTD, 6100 HURRICANE WAY, GL3 4AJ",
    dpAccountRef: "TRADEGL",
    deliveryName: "TRADETEAM LTD",
    deliveryAddress1: "6100 HURRICANE WAY",
    deliveryAddress2: "GLOUCESTER BUSINESS PARK",
    deliveryAddress3: "",
    deliveryTownOrCounty: "BROCKWORTH",
    deliveryPostcode: "GL3 4AJ",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TRADTM-TRADETEAM LIMITED, TRADETEAM DEPOT, G72 7DX",
    dpAccountRef: "TRADTM",
    deliveryName: "TRADETEAM LIMITED",
    deliveryAddress1: "TRADETEAM DEPOT",
    deliveryAddress2: "DALE AVENUE",
    deliveryAddress3: "CAMBUSLANG",
    deliveryTownOrCounty: "GLASGOW",
    deliveryPostcode: "G72 7DX",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TTEAM-TRADETEAM LIMITED, STRETTON BUSINESS PARK, DE13 0BB",
    dpAccountRef: "TTEAM",
    deliveryName: "TRADETEAM LIMITED",
    deliveryAddress1: "STRETTON BUSINESS PARK",
    deliveryAddress2: "EASTERN AVE, OFF DERBY ROAD",
    deliveryAddress3: "",
    deliveryTownOrCounty: "BURTON ON TRENT",
    deliveryPostcode: "DE13 0BB",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "VWOLTD-VIRGIN WINE ONLINE LIMITED, 37-41 ROMAN WAY INDUSTRIAL ESTATE, PR2 5BD",
    dpAccountRef: "VWOLTD",
    deliveryName: "VIRGIN WINE ONLINE LIMITED",
    deliveryAddress1: "37-41 ROMAN WAY INDUSTRIAL ESTATE",
    deliveryAddress2: "RIBBLETON",
    deliveryAddress3: "",
    deliveryTownOrCounty: "PRESTON",
    deliveryPostcode: "PR2 5BD",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "WIELTD-WINE IMPORTERS (EDINBURGH) LTD, THISTLE HOUSE,CAPUTHALL ROAD, EH54 8AS",
    dpAccountRef: "WIELTD",
    deliveryName: "WINE IMPORTERS (EDINBURGH) LTD",
    deliveryAddress1: "THISTLE HOUSE,CAPUTHALL ROAD",
    deliveryAddress2: "DEANS INDUSTRIAL ESTATE",
    deliveryAddress3: "",
    deliveryTownOrCounty: "LIVINGSTONE",
    deliveryPostcode: "EH54 8AS",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "WINSOC-IEC WINE SOCIETY, NO 1 WAREHOUSE, SG1 2BG",
    dpAccountRef: "WINSOC",
    deliveryName: "IEC WINE SOCIETY",
    deliveryAddress1: "NO 1 WAREHOUSE",
    deliveryAddress2: "GUNNELS WOOD ROAD",
    deliveryAddress3: "",
    deliveryTownOrCounty: "STEVENAGE",
    deliveryPostcode: "SG1 2BG",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "WINUB6-WINCANTON HOLDINGS LTD, ROCKWARE AVENUE, UB6 0AA",
    dpAccountRef: "WINUB6",
    deliveryName: "WINCANTON HOLDINGS LTD",
    deliveryAddress1: "ROCKWARE AVENUE",
    deliveryAddress2: "",
    deliveryAddress3: "",
    deliveryTownOrCounty: "GREENFORD",
    deliveryPostcode: "UB6 0AA",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "GB Warehouse Underbond",
    HMRC_ARC_Type: "Tax Warehouse",
  },
];

const exportAddresses = [
  {
    dropdownName: "AHARTEX-A.HARTRODT UK LTD TW14 0PZ, HARTRODT HOUSE, TW14 0PZ",
    dpAccountRef: "AHARTEX",
    deliveryName: "A.Hartrodt UK Ltd TW14 0PZ",
    deliveryAddress1: "Hartrodt House",
    deliveryAddress2: "Green Man Lane",
    deliveryAddress3: "Hatton Cross",
    deliveryTownOrCounty: "FELTHAM",
    deliveryPostcode: "TW14 0PZ",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "AIRMAREX-AIR MARINE LTD, 248 ELGIN CRESCENT, TW6 2RX",
    dpAccountRef: "AIRMAREX",
    deliveryName: "AIR MARINE LTD",
    deliveryAddress1: "248 ELGIN CRESCENT",
    deliveryAddress2: "EASTERN BUSINESS  PARK",
    deliveryAddress3: "",
    deliveryTownOrCounty: "HEATHROW",
    deliveryPostcode: "TW6 2RX",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "ALBEXP-ALBATRANS UK LIMITED G1 2PF, 4TH FLOOR AFTON HOUSE, G1 2PF",
    dpAccountRef: "ALBEXP",
    deliveryName: "Albatrans UK Limited G1 2PF",
    deliveryAddress1: "4th Floor Afton House",
    deliveryAddress2: "26 West Nile Street",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Glasgow",
    deliveryPostcode: "G1 2PF",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ALBEXP2-ALBATRANS LONDON (UK) LTD, SUITE 4, CM13 3XL",
    dpAccountRef: "ALBEXP2",
    deliveryName: "Albatrans London (UK) Ltd",
    deliveryAddress1: "Suite 4",
    deliveryAddress2: "Clocktower House",
    deliveryAddress3: "Station Road",
    deliveryTownOrCounty: "West Horndon",
    deliveryPostcode: "CM13 3XL",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "BERFOREX-BERMUDA FORWARDERS UK LTD, FLOATS ROAD, M23 0GP",
    dpAccountRef: "BERFOREX",
    deliveryName: "BERMUDA FORWARDERS UK LTD",
    deliveryAddress1: "FLOATS ROAD",
    deliveryAddress2: "ROUNDTHORN IND EST",
    deliveryAddress3: "",
    deliveryTownOrCounty: "MANCHESTER",
    deliveryPostcode: "M23 0GP",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "CIDEXP-CONNOISSEUR INTERNATIONAL LTD, UNIT1, TW20 8RE",
    dpAccountRef: "CIDEXP",
    deliveryName: "Connoisseur International Ltd",
    deliveryAddress1: "UNIT1",
    deliveryAddress2: "BETA WAY",
    deliveryAddress3: "",
    deliveryTownOrCounty: "EGHAM",
    deliveryPostcode: "TW20 8RE",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "DAVEXP-DAVIES TURNER PLC, UNIT A1 , SL3 0BQ",
    dpAccountRef: "DAVEXP",
    deliveryName: "Davies Turner Plc",
    deliveryAddress1: "Unit A1 ",
    deliveryAddress2: "Calder Way",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Slough",
    deliveryPostcode: "SL3 0BQ",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "DHLEXP-DHL GLOBAL FORWARDING, KESTRAL WAY, UB3 5EY",
    dpAccountRef: "DHLEXP",
    deliveryName: "DHL Global Forwarding",
    deliveryAddress1: "Kestral Way",
    deliveryAddress2: "",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Hayes",
    deliveryPostcode: "UB3 5EY",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "DHLEXP1-DHL GLOBAL FORWARDING , MIRREN COURT, PA3 2SG",
    dpAccountRef: "DHLEXP1",
    deliveryName: "DHL Global Forwarding ",
    deliveryAddress1: "Mirren Court",
    deliveryAddress2: "123 Renfrew Road",
    deliveryAddress3: "1st Floor North Extension",
    deliveryTownOrCounty: "PAISLEY",
    deliveryPostcode: "PA3 2SG",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "DSVEXEXP-DSV ROAD LTD, 10 BRUNEL WAY, PO15 5TX",
    dpAccountRef: "DSVEXEXP",
    deliveryName: "DSV Road Ltd",
    deliveryAddress1: "10 Brunel Way",
    deliveryAddress2: "Segensworth West",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Fareham",
    deliveryPostcode: "PO15 5TX",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "DSVEXP-DSV ROAD LTD, STONE HOUSE LANE, RM19 1NX",
    dpAccountRef: "DSVEXP",
    deliveryName: "DSV Road Ltd",
    deliveryAddress1: "Stone House Lane",
    deliveryAddress2: "",
    deliveryAddress3: "",
    deliveryTownOrCounty: "PURFLEET-ON-THAMES",
    deliveryPostcode: "RM19 1NX",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "FREIGHPORTEX-FREIGHTPORT LOGISTICS LTD, FREIGHTPORT LOGISTICS LTD GLASGOW, G68 9HW",
    dpAccountRef: "FREIGHPORTEX",
    deliveryName: "FREIGHTPORT LOGISTICS LTD",
    deliveryAddress1: "Freightport Logistics Ltd GLASGOW",
    deliveryAddress2: "Palletways Terminal",
    deliveryAddress3: "Deerdykes Court, Westfield",
    deliveryTownOrCounty: "Cumbernauld",
    deliveryPostcode: "G68 9HW",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "FSMAC-FS MACKENZIE LTD, UNTI 9, SL3 0BH",
    dpAccountRef: "FSMAC",
    deliveryName: "FS MACKENZIE LTD",
    deliveryAddress1: "UNTI 9",
    deliveryAddress2: "BRITANNIA INDUSTRIAL ESTATE",
    deliveryAddress3: "POYLE ROAD",
    deliveryTownOrCounty: "Slough",
    deliveryPostcode: "SL3 0BH",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "FSPEEXP-FERRYSPEED, ACKWORTH ROAD, PO3 5JT",
    dpAccountRef: "FSPEEXP",
    deliveryName: "Ferryspeed",
    deliveryAddress1: "Ackworth Road",
    deliveryAddress2: "Hailsea",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Portsmouth",
    deliveryPostcode: "PO3 5JT",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "FTREXP-FREIGHT TRANSPORT LTD PO6 1TW, C3/C4/C5, PO6 1TW",
    dpAccountRef: "FTREXP",
    deliveryName: "Freight Transport Ltd PO6 1TW",
    deliveryAddress1: "C3/C4/C5",
    deliveryAddress2: "Railway Triangle Industrial Estate",
    deliveryAddress3: "Walton Road",
    deliveryTownOrCounty: "Portsmouth",
    deliveryPostcode: "PO6 1TW",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "HARTRODT-A. HARTRODT (UK) LIMITED TW14 0PZ, HARTRODT HOUSE , EXPORT",
    dpAccountRef: "HARTRODT",
    deliveryName: "A. Hartrodt (UK) Limited",
    deliveryAddress1: "Hartrodt House ",
    deliveryAddress2: "Green Man Lane ",
    deliveryAddress3: "Hatton Cross ",
    deliveryTownOrCounty: "Feltham",
    deliveryPostcode: "TW14 0PZ",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "HERIEXP-HERITAGE INTERNATIONAL, CHESTERFIELD WAY, UB3 3NW",
    dpAccountRef: "HERIEXP",
    deliveryName: "HERITAGE INTERNATIONAL",
    deliveryAddress1: "CHESTERFIELD WAY",
    deliveryAddress2: "",
    deliveryAddress3: "",
    deliveryTownOrCounty: "HAYES",
    deliveryPostcode: "UB3 3NW",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "HILEXP-JF HILLEBRAND UK 1 RM20 3ZP, WESTON AVENUE, RM20 3ZP",
    dpAccountRef: "HILEXP",
    deliveryName: "JF Hillebrand UK 1 RM20 3ZP",
    deliveryAddress1: "Weston Avenue",
    deliveryAddress2: "West Thurrock",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Grays",
    deliveryPostcode: "RM20 3ZP",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "HLAEXP-HUNTER LAING & CO LTD G3 6AX , 16 PARK CIRCUS, G3 6AX",
    dpAccountRef: "HLAEXP",
    deliveryName: "Hunter Laing & Co Ltd G3 6AX ",
    deliveryAddress1: "16 Park Circus",
    deliveryAddress2: "",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Glasgow",
    deliveryPostcode: "G3 6AX",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "IDIMM-M AND M GLOBAL FORWARDING LTD, NO 1 INVICTA PARK, DA1 5BU",
    dpAccountRef: "IDIMM",
    deliveryName: "M and M Global Forwarding Ltd",
    deliveryAddress1: "No 1 Invicta Park",
    deliveryAddress2: "Sandpit Road",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Dartford",
    deliveryPostcode: "DA1 5BU",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "KEREXP-KERRY LOGISTICS TW13 7EJ, UNIT V7 VECTOR PARK, TW13 7EJ",
    dpAccountRef: "KEREXP",
    deliveryName: "Kerry Logistics TW13 7EJ",
    deliveryAddress1: "Unit V7 Vector Park",
    deliveryAddress2: "Forest Road",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Feltham",
    deliveryPostcode: "TW13 7EJ",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "KESTREX-KESTREL LINER AGENCIES, UNIT 5- 11 GLOBE INDUSTRIAL ESTATE, RM17 6SU",
    dpAccountRef: "KESTREX",
    deliveryName: "Kestrel Liner Agencies",
    deliveryAddress1: "Unit 5- 11 Globe Industrial Estate",
    deliveryAddress2: "Enterprise Business Park",
    deliveryAddress3: "Towers Road",
    deliveryTownOrCounty: "Grays",
    deliveryPostcode: "RM17 6SU",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "KHUEXP-KUEHNE & NAGEL RM9 6RS, PHASE V, RM9 6RS",
    dpAccountRef: "KHUEXP",
    deliveryName: "Kuehne & Nagel RM9 6RS",
    deliveryAddress1: "Phase V",
    deliveryAddress2: "Pools Lane",
    deliveryAddress3: "Ripple Road",
    deliveryTownOrCounty: "Dagenham",
    deliveryPostcode: "RM9 6RS",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "KINETEX-KINETSU WORLD EXPRESS UK LTD, PORTERFIELD ROAD, PA4 8DJ",
    dpAccountRef: "KINETEX",
    deliveryName: "KINETSU WORLD EXPRESS UK LTD",
    deliveryAddress1: "PORTERFIELD ROAD",
    deliveryAddress2: "WESTWAY BLOCK L",
    deliveryAddress3: "UNIT 1 G",
    deliveryTownOrCounty: "RENFREW",
    deliveryPostcode: "PA4 8DJ",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "KNPSEX-KUEHNE & NAGEL LTD PA3 2SJ, UNIT C BUILDING 3, PA3 2SJ",
    dpAccountRef: "KNPSEX",
    deliveryName: "KUEHNE & NAGEL LTD PA3 2SJ",
    deliveryAddress1: "Unit C Building 3",
    deliveryAddress2: "Marchburn Drive",
    deliveryAddress3: "Glasgow Airport Business Park",
    deliveryTownOrCounty: "Paisley",
    deliveryPostcode: "PA3 2SJ",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "KWEEXP-KWE / KINTETSU WORLD EXPRESS, BLOCK L, PORTERFIELD ROAD, PA4 8DJ",
    dpAccountRef: "KWEEXP",
    deliveryName: "KWE / Kintetsu World Express",
    deliveryAddress1: "Block L, Porterfield Road",
    deliveryAddress2: "Westway Business Park",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Renfrew",
    deliveryPostcode: "PA4 8DJ",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "MAPEXP-MAP CARGO INTERNATIONAL LTD, MAP CARGO INTERNATIONAL LTD, COLL",
    dpAccountRef: "MAPEXP",
    deliveryName: "Map Cargo International Ltd",
    deliveryAddress1: "Stains Road",
    deliveryAddress2: "Unit 1A Westgate One",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Bedfont",
    deliveryPostcode: "TW14 8RS",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "NNREXP-NNR AIR CARGO, STANWELL ROAD, TW14 8NG",
    dpAccountRef: "NNREXP",
    deliveryName: "NNR AIR CARGO",
    deliveryAddress1: "STANWELL ROAD",
    deliveryAddress2: "",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Feltham",
    deliveryPostcode: "TW14 8NG",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "R.WEXP-R.W.FREIGHT SERVICES RM7 0DJ, 7 BERNARD RD , RM7 0DJ",
    dpAccountRef: "R.WEXP",
    deliveryName: "R.W.Freight Services RM7 0DJ",
    deliveryAddress1: "7 BERNARD RD ",
    deliveryAddress2: "",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Romford",
    deliveryPostcode: "RM7 0DJ",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "SAMSKIPEX-SAMSKIP LOGISTICS, UNIT 16 TABRUMS FARM, SS11 7QX",
    dpAccountRef: "SAMSKIPEX",
    deliveryName: "SAMSKIP LOGISTICS",
    deliveryAddress1: "UNIT 16 TABRUMS FARM",
    deliveryAddress2: "BURNHAM ROAD",
    deliveryAddress3: "",
    deliveryTownOrCounty: "BATTLESBRIDGE",
    deliveryPostcode: "SS11 7QX",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "SEABWTEX-SEABROOKS, SEABROOK EXPORT SERVICES LTD, RM20 3LG",
    dpAccountRef: "SEABWTEX",
    deliveryName: "Seabrooks",
    deliveryAddress1: "Seabrook Export Services Ltd",
    deliveryAddress2: "853 london Road",
    deliveryTownOrCounty: "Garays",
    deliveryPostcode: "RM20 3LG",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "SEAEXP-SEABROOKS, SEABROOK EXPORT SERVICES LTD, IG11 0EG",
    dpAccountRef: "SEAEXP",
    deliveryName: "Seabrooks",
    deliveryAddress1: "Seabrook Export Services Ltd",
    deliveryAddress2: "8 River Road",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Barking",
    deliveryPostcode: "IG11 0EG",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "SUPPLY-SUPPLYCHAIN21 LTD, 5 THE LODGE, LE16 9TU",
    dpAccountRef: "SUPPLY",
    deliveryName: "Supplychain21 Ltd",
    deliveryAddress1: "5 The Lodge",
    deliveryAddress2: "Rectory Farm",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Marston Trussell",
    deliveryPostcode: "LE16 9TU",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "UNIEXP-UNISERVE DRINKS LOGISTICS LTD RM18 7HD, LONDON MEGA TERMINAL, RM18 7HD",
    dpAccountRef: "UNIEXP",
    deliveryName: "Uniserve Drinks Logistics Ltd RM18 7HD",
    deliveryAddress1: "London Mega Terminal",
    deliveryAddress2: "Thurrock Park Way",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Tilbury",
    deliveryPostcode: "RM18 7HD",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "WILEXP-WILDER LOGISTICS LTD, UNIT 455, SL3 OHQ",
    dpAccountRef: "WILEXP",
    deliveryName: "Wilder Logistics Ltd",
    deliveryAddress1: "Unit 455",
    deliveryAddress2: "Colndale Road",
    deliveryAddress3: "Colnebrook",
    deliveryTownOrCounty: "Slough",
    deliveryPostcode: "SL3 OHQ",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "WINEEX-WINEFLOW RH17 5QT, BOLNEY PLACE, RH17 5QT",
    dpAccountRef: "WINEEX",
    deliveryName: "WINEFLOW RH17 5QT",
    deliveryAddress1: "BOLNEY PLACE",
    deliveryAddress2: "COWFORD ROAD",
    deliveryAddress3: "BOLNEY",
    deliveryTownOrCounty: "HAYWARDS HEATH",
    deliveryPostcode: "RH17 5QT",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },

  {
    dropdownName: "WOODEX-WOODSIDE LOGISTICS, UNIT 2 , PO3 5SF",
    dpAccountRef: "WOODEX",
    deliveryName: "WOODSIDE LOGISTICS",
    deliveryAddress1: "UNIT 2 ",
    deliveryAddress2: "NELSON CENTRE",
    deliveryAddress3: "PORTFIELD ROAD",
    deliveryTownOrCounty: "PORTSMOUTH",
    deliveryPostcode: "PO3 5SF",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ZENEXP-ZENITH INTERNATIONAL FREIGHT, ZENITH HOUSE, BD1 4SP",
    dpAccountRef: "ZENEXP",
    deliveryName: "Zenith International Freight",
    deliveryAddress1: "Zenith House",
    deliveryAddress2: "valley Court",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Bradford",
    deliveryPostcode: "BD1 4SP",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "Export (non EC)",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECAHARTEX-A.HARTRODT UK LTD TW14 0PZ, HARTRODT HOUSE, TW14 0PZ",
    dpAccountRef: "ECAHARTEX",
    deliveryName: "A.Hartrodt UK Ltd TW14 0PZ",
    deliveryAddress1: "Hartrodt House",
    deliveryAddress2: "Green Man Lane",
    deliveryAddress3: "",
    deliveryTownOrCounty: "FELTHAM",
    deliveryPostcode: "TW14 0PZ",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECALBEXP-ALBATRANS UK LIMITED G1 2PF, 4TH FLOOR AFTON HOUSE, G1 2PF",
    dpAccountRef: "ECALBEXP",
    deliveryName: "Albatrans UK Limited G1 2PF",
    deliveryAddress1: "4th Floor Afton House",
    deliveryAddress2: "26 West Nile Street",
    deliveryAddress3: "",
    deliveryTownOrCounty: "GLASGOW",
    deliveryPostcode: "G1 2PF",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECALBEXP2-ALBATRANS LONDON (UK) LTD, SUITE 4, CM13 3XL",
    dpAccountRef: "ECALBEXP2",
    deliveryName: "Albatrans London (UK) Ltd",
    deliveryAddress1: "Suite 4",
    deliveryAddress2: "Clocktower House",
    deliveryAddress3: "Station Road",
    deliveryTownOrCounty: "BRENTWOOD",
    deliveryPostcode: "CM13 3XL",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECCIDEXP-CONNOISSEUR INTERNATIONAL LTD, UNIT1, TW20 8RE",
    dpAccountRef: "ECCIDEXP",
    deliveryName: "Connoisseur International Ltd",
    deliveryAddress1: "UNIT1",
    deliveryAddress2: "BETA WAY",
    deliveryAddress3: "",
    deliveryTownOrCounty: "EGHAM",
    deliveryPostcode: "TW20 8RE",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECCOB-COBLEY TRANSPORT, FOXBANK INDUSTRIAL ESTATE, LE9 4NA",
    dpAccountRef: "ECCOB",
    deliveryName: "Cobley Transport",
    deliveryAddress1: "Foxbank Industrial Estate",
    deliveryAddress2: "",
    deliveryAddress3: "Stoney Stanton",
    deliveryTownOrCounty: "Leicester",
    deliveryPostcode: "LE9 4NA",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECDAVEXP-DAVIES TURNER PLC, UNIT A1 , SL3 0BQ",
    dpAccountRef: "ECDAVEXP",
    deliveryName: "Davies Turner Plc",
    deliveryAddress1: "Unit A1 ",
    deliveryAddress2: "Calder Way",
    deliveryAddress3: "Colnbrook",
    deliveryTownOrCounty: "Slough",
    deliveryPostcode: "SL3 0BQ",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECDHLEXP-DHL GLOBAL FORWARDING, KESTRAL WAY, UB3 5EY",
    dpAccountRef: "ECDHLEXP",
    deliveryName: "DHL Global Forwarding",
    deliveryAddress1: "Kestral Way",
    deliveryAddress2: "",
    deliveryAddress3: "",
    deliveryTownOrCounty: "HAYES",
    deliveryPostcode: "UB3 5EY",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECDHLEXP1-DHL GLOBAL FORWARDING , MIRREN COURT, PA3 2SG",
    dpAccountRef: "ECDHLEXP1",
    deliveryName: "DHL Global Forwarding ",
    deliveryAddress1: "Mirren Court",
    deliveryAddress2: "123 Renfrew Road",
    deliveryAddress3: "1st Floor North Extension",
    deliveryTownOrCounty: "Paisley",
    deliveryPostcode: "PA3 2SG",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECDLAEXP-DOUGLAS LAING, DOUGLAS HOUSE, G3 6EQ",
    dpAccountRef: "ECDLAEXP",
    deliveryName: "Douglas Laing",
    deliveryAddress1: "Douglas House",
    deliveryAddress2: "18 Lynedoch Crescent",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Glasgow",
    deliveryPostcode: "G3 6EQ",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECDSVEXP-DSV ROAD LTD, STONE HOUSE LANE, RM19 1NX",
    dpAccountRef: "ECDSVEXP",
    deliveryName: "DSV Road Ltd",
    deliveryAddress1: "Gateway House",
    deliveryAddress2: "Stone House Lane",
    deliveryAddress3: "",
    deliveryTownOrCounty: "PURFLEET-ON-THAMES",
    deliveryPostcode: "RM19 1NX",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECFREIGHPORTEX-FREIGHTPORT LOGISTICS LTD, FREIGHTPORT LOGISTICS LTD GLASGOW, G68 9HW",
    dpAccountRef: "ECFREIGHPORTEX",
    deliveryName: "FREIGHTPORT LOGISTICS LTD",
    deliveryAddress1: "Freightport Logistics Ltd GLASGOW",
    deliveryAddress2: "Palletways Terminal",
    deliveryAddress3: "Deerdykes Court, Westfield",
    deliveryTownOrCounty: "GLASGOW",
    deliveryPostcode: "G68 9HW",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECFRLOEXP-FREIGHTPORT LOGISTICS LTD, UNIT 3, SS15 6SJ",
    dpAccountRef: "ECFRLOEXP",
    deliveryName: "FREIGHTPORT LOGISTICS LTD",
    deliveryAddress1: "UNIT 3",
    deliveryAddress2: "JUNIPER WEST FENTON WAY ",
    deliveryAddress3: "",
    deliveryTownOrCounty: "BASILDON ",
    deliveryPostcode: "SS15 6SJ",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECFSPEEXP-FERRYSPEED, ACKWORTH ROAD, PO3 5JT",
    dpAccountRef: "ECFSPEEXP",
    deliveryName: "Ferryspeed",
    deliveryAddress1: "Ackworth Road",
    deliveryAddress2: "Hailsea",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Portsmouth",
    deliveryPostcode: "PO3 5JT",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECFTREXP-FREIGHT TRANSPORT LTD PO6 1TW, ***MUST PAY CCT ON ALL EXPORTS***, PO6 1TW",
    dpAccountRef: "ECFTREXP",
    deliveryName: "Freight Transport Ltd PO6 1TW",
    deliveryAddress1: "***MUST PAY CCT ON ALL EXPORTS***",
    deliveryAddress2: "Railway Triangle Industrial Estate",
    deliveryAddress3: "Walton Road",
    deliveryTownOrCounty: "Portsmouth",
    deliveryPostcode: "PO6 1TW",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECHELLEX-HELLMAN BEVERAGE LOGISTICS, HPL KENSINGTON FREIGHT LTD, COLL",
    dpAccountRef: "ECHELLEX",
    deliveryName: "HELLMAN BEVERAGE LOGISTICS",
    deliveryAddress1: "HPL KENSINGTON FREIGHT LTD",
    deliveryAddress2: "26-27 MARKET SQUARE",
    deliveryAddress3: "",
    deliveryTownOrCounty: "DOVER",
    deliveryPostcode: "COLL",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECHILEXP-JF HILLEBRAND UK 1 RM20 3ZP, WESTON AVENUE, RM20 3ZP",
    dpAccountRef: "ECHILEXP",
    deliveryName: "JF Hillebrand UK 1 RM20 3ZP",
    deliveryAddress1: "Weston Avenue",
    deliveryAddress2: "West Thurrock",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Grays",
    deliveryPostcode: "RM20 3ZP",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECIDIMM-M AND M GLOBAL FORWARDING LTD, NO 1 INVICTA PARK, DA1 5BU",
    dpAccountRef: "ECIDIMM",
    deliveryName: "M and M Global Forwarding Ltd",
    deliveryAddress1: "No 1 Invicta Park",
    deliveryAddress2: "Sandpit Road",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Dartford",
    deliveryPostcode: "DA1 5BU",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECKCSHIP-KC GROUP SHIPPING LIMITED, KC SHIPPING, G41 1HH",
    dpAccountRef: "ECKCSHIP",
    deliveryName: "KC GROUP SHIPPING LIMITED",
    deliveryAddress1: "KC SHIPPING",
    deliveryAddress2: "5 SEAWARD PLACE",
    deliveryAddress3: "",
    deliveryTownOrCounty: "GLASGOW",
    deliveryPostcode: "G41 1HH",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECKUKEXP-ROBERT KUKLA SPECIDITION UK LTD, UNIT 1 SAXON HOUSE , RM14 3PJ",
    dpAccountRef: "ECKUKEXP",
    deliveryName: "ROBERT KUKLA SPECIDITION UK LTD",
    deliveryAddress1: "UNIT 1 SAXON HOUSE ",
    deliveryAddress2: "UPMINSTER TRADING HOUSE ",
    deliveryAddress3: "WARLEY STREET UPMINSTER ",
    deliveryTownOrCounty: "UPMINSTER",
    deliveryPostcode: "RM14 3PJ",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECMAMEXP-MAMMOET FERRY TRANSPORT UK BV, ASTON LANE NORTH, WA7 3GE",
    dpAccountRef: "ECMAMEXP",
    deliveryName: "Mammoet Ferry Transport UK BV",
    deliveryAddress1: "Aston Lane North",
    deliveryAddress2: "Preston Brook",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Runcorn",
    deliveryPostcode: "WA7 3GE",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECRWFEX-R.W.FREIGHT SERVICES , 7 BERNARD RD , COLL",
    dpAccountRef: "ECRWFEX",
    deliveryName: "R.W.FREIGHT SERVICES ",
    deliveryAddress1: "7 BERNARD RD ",
    deliveryAddress2: "",
    deliveryAddress3: "",
    deliveryTownOrCounty: "ROMFORD ",
    deliveryPostcode: "RM7 0JD",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECSEABWT-SEABROOKS, SEABROOK EXPORT SERVICES LTD, RM20 3LG",
    dpAccountRef: "ECSEABWT",
    deliveryName: "Seabrooks",
    deliveryAddress1: "Seabrook Export Services Ltd",
    deliveryAddress2: "853 london Road",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Grays",
    deliveryPostcode: "RM20 3LG",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECSEAEXP-SEABROOKS, SEABROOK EXPORT SERVICES LTD, IG11 0EG",
    dpAccountRef: "ECSEAEXP",
    deliveryName: "Seabrooks",
    deliveryAddress1: "Seabrook Export Services Ltd",
    deliveryAddress2: "8 River Road",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Barking",
    deliveryPostcode: "IG11 0EG",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECSTEDER-STEDER GROUP (UK) LTD, UNIT 3, G52 4UG",
    dpAccountRef: "ECSTEDER",
    deliveryName: "Steder Group (UK) Ltd",
    deliveryAddress1: "Unit 3",
    deliveryAddress2: "Sentinal Court",
    deliveryAddress3: "Hillington Park",
    deliveryTownOrCounty: "Glasgow",
    deliveryPostcode: "G52 4UG",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECSUPPLY-SUPPLYCHAIN21 LTD, 5 THE LODGE, LE16 9TU",
    dpAccountRef: "ECSUPPLY",
    deliveryName: "Supplychain21 Ltd",
    deliveryAddress1: "5 The Lodge",
    deliveryAddress2: "Rectory Farm",
    deliveryAddress3: "Marston Trussell",
    deliveryTownOrCounty: "RUNCORN",
    deliveryPostcode: "LE16 9TU",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECWINEEX-WINEFLOW RH17 5QT, BOLNEY PLACE, RH17 5QT",
    dpAccountRef: "ECWINEEX",
    deliveryName: "WINEFLOW RH17 5QT",
    deliveryAddress1: "BOLNEY PLACE",
    deliveryAddress2: "COWFORD ROAD",
    deliveryAddress3: "BOLNEY",
    deliveryTownOrCounty: "HAYWARDS HEATH",
    deliveryPostcode: "RH17 5QT",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECWOODEX-WOODSIDE LOGISTICS, UNIT 2 , PO3 5SF",
    dpAccountRef: "ECWOODEX",
    deliveryName: "WOODSIDE LOGISTICS",
    deliveryAddress1: "UNIT 2 ",
    deliveryAddress2: "NELSON CENTRE",
    deliveryAddress3: "PORTFIELD ROAD",
    deliveryTownOrCounty: "PORTSMOUTH",
    deliveryPostcode: "PO3 5SF",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECXPONN-XPO LOGISTICS, ELDON WAY, NN6 7SL",
    dpAccountRef: "ECXPONN",
    deliveryName: "XPO Logistics",
    deliveryAddress1: "Eldon Way",
    deliveryAddress2: "",
    deliveryAddress3: "",
    deliveryTownOrCounty: "NORTHAMPTON",
    deliveryPostcode: "NN6 7SL",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "ECZENEXP-ZENITH INTERNATIONAL FREIGHT, ZENITH HOUSE, BD1 4SP",
    dpAccountRef: "ECZENEXP",
    deliveryName: "Zenith International Freight",
    deliveryAddress1: "Zenith House",
    deliveryAddress2: "valley Court",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Bradford",
    deliveryPostcode: "BD1 4SP",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
  {
    dropdownName: "EXACPEXP-ACP FREIGHT SERVICES LTD, OLD COMMERCIAL ROAD 373-375, PO1 4QG",
    dpAccountRef: "EXACPEXP",
    deliveryName: "ACP Freight Services Ltd",
    deliveryAddress1: "Old Commercial Road 373-375",
    deliveryAddress2: "Units 1-3",
    deliveryAddress3: "",
    deliveryTownOrCounty: "Portsmouth",
    deliveryPostcode: "PO1 4QG",
    deliveryCountry: "GB",
    Type: "EX",
    TypeDescription: "EC Export",
    HMRC_ARC_Type: "Export",
    Note: "collection only",
  },
];

const transferOrderAddresses = [
  {
    dropdownName: "LCB Linton - code LCBLT",
    dpAccountRef: "LCB Linton",
    deliveryName: "Account Name",
    deliveryAddress1: "Station Road",
    deliveryAddress2: "Linton",
    deliveryAddress3: "Reserve code (if known)",
    deliveryTownOrCounty: "Cambridgeshire",
    deliveryPostcode: "CB21 4UX",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "Transfer to another LCB customer",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "LCBAW-ACCOUNT NAME, ACCOUNT CODE, CB22 3TJ",
    dpAccountRef: "LCBAW",
    deliveryName: "Account Name",
    deliveryAddress1: "Account Code",
    deliveryAddress2: "AW",
    deliveryAddress3: "Reserve code (if known)",
    deliveryTownOrCounty: "SAWSTON",
    deliveryPostcode: "CB22 3TJ",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "Transfer to another LCB customer",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TLCBBG-ACCOUNT NAME, ACCOUNT CODE, DE13 8FL",
    dpAccountRef: "TLCBBG",
    deliveryName: "Account Name",
    deliveryAddress1: "Account Code",
    deliveryAddress2: "BG",
    deliveryAddress3: "Reserve code (if known)",
    deliveryTownOrCounty: "BURTON UPON TRENT",
    deliveryPostcode: "DE13 8FL",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "Transfer to another LCB customer",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TLCBCR-ACCOUNT NAME, ACCOUNT CODE, IG11 0JH",
    dpAccountRef: "TLCBCR",
    deliveryName: "Account Name",
    deliveryAddress1: "Account Code",
    deliveryAddress2: "CR",
    deliveryAddress3: "Reserve code (if known)",
    deliveryTownOrCounty: "BARKING",
    deliveryPostcode: "IG11 0JH",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "Transfer to another LCB customer",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TLCBDS-ACCOUNT NAME, ACCOUNT CODE, DE14 2LE",
    dpAccountRef: "TLCBDS",
    deliveryName: "Account Name",
    deliveryAddress1: "Account Code",
    deliveryAddress2: "DS",
    deliveryAddress3: "Reserve code (if known)",
    deliveryTownOrCounty: "BURTON ON TRENT",
    deliveryPostcode: "DE14 2LE",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "Transfer to another LCB customer",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TLCBDT-ACCOUNT NAME, ACCOUNT CODE, SP3 5HB",
    dpAccountRef: "TLCBDT",
    deliveryName: "Account Name",
    deliveryAddress1: "Account Code",
    deliveryAddress2: "DT",
    deliveryAddress3: "Reserve code (if known)",
    deliveryTownOrCounty: "Salisbury",
    deliveryPostcode: "SP3 5HB",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "Transfer to another LCB customer",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TLCBEL-ACCOUNT NAME, ACCOUNT CODE, LS9 0SE",
    dpAccountRef: "TLCBEL",
    deliveryName: "Account Name",
    deliveryAddress1: "Account Code",
    deliveryAddress2: "EL",
    deliveryAddress3: "Reserve code (if known)",
    deliveryTownOrCounty: "LEEDS",
    deliveryPostcode: "LS9 0SE",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "Transfer to another LCB customer",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TLCBFP-ACCOUNT NAME, ACCOUNT CODE, WS13 8RZ",
    dpAccountRef: "TLCBFP",
    deliveryName: "Account Name",
    deliveryAddress1: "Account Code",
    deliveryAddress2: "FP",
    deliveryAddress3: "Reserve code (if known)",
    deliveryTownOrCounty: "Lichfield",
    deliveryPostcode: "WS13 8RZ",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "Transfer to another LCB customer",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TLCBHT-ACCOUNT NAME, ACCOUNT CODE, G52 4UU",
    dpAccountRef: "TLCBHT",
    deliveryName: "Account Name",
    deliveryAddress1: "Account Code",
    deliveryAddress2: "HT",
    deliveryAddress3: "Reserve code (if known)",
    deliveryTownOrCounty: "GLASGOW",
    deliveryPostcode: "G52 4UU",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "Transfer to another LCB customer",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TLCBKD-ACCOUNT NAME, ACCOUNT CODE, DY11 5RZ",
    dpAccountRef: "TLCBKD",
    deliveryName: "Account Name",
    deliveryAddress1: "Account Code",
    deliveryAddress2: "KD",
    deliveryAddress3: "Reserve code (if known)",
    deliveryTownOrCounty: "Kidderminster",
    deliveryPostcode: "DY11 5RZ",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "Transfer to another LCB customer",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TLCBMM-ACCOUNT NAME, ACCOUNT CODE, SN12 6SS",
    dpAccountRef: "TLCBMM",
    deliveryName: "Account Name",
    deliveryAddress1: "Account Code",
    deliveryAddress2: "MM",
    deliveryAddress3: "Reserve code (if known)",
    deliveryTownOrCounty: "MELKSHAM",
    deliveryPostcode: "SN12 6SS",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "Transfer to another LCB customer",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TLCBRR-ACCOUNT NAME, ACCOUNT CODE, IG11 0EG",
    dpAccountRef: "TLCBRR",
    deliveryName: "Account Name",
    deliveryAddress1: "Account Code",
    deliveryAddress2: "RR",
    deliveryAddress3: "Reserve code (if known)",
    deliveryTownOrCounty: "BARKING",
    deliveryPostcode: "IG11 0EG",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "Transfer to another LCB customer",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TLCBSH-ACCOUNT NAME, ACCOUNT CODE, SS17 9ER",
    dpAccountRef: "TLCBSH",
    deliveryName: "Account Name",
    deliveryAddress1: "Account Code",
    deliveryAddress2: "SH",
    deliveryAddress3: "Reserve code (if known)",
    deliveryTownOrCounty: "STANFORD LE HOPE",
    deliveryPostcode: "SS17 9ER",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "Transfer to another LCB customer",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TLCBTD-ACCOUNT NAME, ACCOUNT CODE, RM18 7EH",
    dpAccountRef: "TLCBTD",
    deliveryName: "Account Name",
    deliveryAddress1: "Account Code",
    deliveryAddress2: "TD",
    deliveryAddress3: "Reserve code (if known)",
    deliveryTownOrCounty: "TILBURY",
    deliveryPostcode: "RM18 7EH",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "Transfer to another LCB customer",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TLCBVT-ACCOUNT NAME, ACCOUNT CODE, DE14 1RY",
    dpAccountRef: "TLCBVT",
    deliveryName: "Account Name",
    deliveryAddress1: "Account Code",
    deliveryAddress2: "VT",
    deliveryAddress3: "Reserve code (if known)",
    deliveryTownOrCounty: "BURTON ON TRENT",
    deliveryPostcode: "DE14 1RY",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "Transfer to another LCB customer",
    HMRC_ARC_Type: "Tax Warehouse",
  },
  {
    dropdownName: "TLCBWT-ACCOUNT NAME, ACCOUNT CODE, RM20 3AX",
    dpAccountRef: "TLCBWT",
    deliveryName: "Account Name",
    deliveryAddress1: "Account Code",
    deliveryAddress2: "WT",
    deliveryAddress3: "Reserve code (if known)",
    deliveryTownOrCounty: "GRAYS",
    deliveryPostcode: "RM20 3AX",
    deliveryCountry: "GB",
    Type: "OU",
    TypeDescription: "Transfer to another LCB customer",
    HMRC_ARC_Type: "Tax Warehouse",
  },
];

export { underbondAddresses, exportAddresses, transferOrderAddresses };
