import { useState } from "react";
import { makeStyles, Modal, Box, Typography, Select, TextField, MenuItem } from "@material-ui/core";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { strings } from "common";
import ModalButtons from "../Common/ModalButtons";
import { Label } from "../Common";
import { issueType } from "common/constants";
import CloseIcon from "@material-ui/icons/Close";
import { useCreateTicket } from "../../utils/issues";
import { useMyUser } from "../../utils/users";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalHeader: {
    width: "100%",
    paddingBottom: theme.spacing(7),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalContent: {
    width: "800px",
    background: "white",
    outline: "none",
    padding: theme.spacing(12, 12, 12, 12),
  },
  input: {
    marginBottom: "10px",
  },
  label: {
    marginTop: "12px",
  },
}));

const initialState = {
  type: Object.keys(issueType)[0],
  subject: "",
  description: "",
};

interface CreateTicketProps {
  modalVisible: boolean;
  setModalVisible: (val: boolean) => void;
  caseIds: number[];
}

const CreateTicket = ({ modalVisible, setModalVisible, caseIds }: CreateTicketProps) => {
  const [error, setError] = useState<any>(null);
  const classes = useStyles();
  const { mutateAsync: createTicket } = useCreateTicket();
  const { data } = useMyUser();

  return (
    <Formik
      initialValues={initialState}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={Yup.object().shape({
        type: Yup.string(),
        subject: Yup.string().required(),
        description: Yup.string().required(),
      })}
      onSubmit={async ({ type, subject, description }, { setSubmitting }) => {
        try {
          const userData = data as { id: number };
          setSubmitting(true);
          setError(null);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          await createTicket({
            type: type ?? "QUERY",
            description: subject,
            comment: { comment: description },
            caseIds,
            userId: userData.id,
          });
          setModalVisible(false);
        } catch (e) {
          setError(strings.wrong);
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <Modal id="createTicketModal" className={classes.modal} open={modalVisible}>
          <Box className={classes.modalContent}>
            <Box className={classes.modalHeader}>
              <Typography variant="h3">{strings.create_ticket}</Typography>
              <CloseIcon id="closeCreateTicketModal" onClick={() => setModalVisible(false)} />
            </Box>
            <Label className={classes.label} variant="caption">
              {strings.type}
            </Label>
            <Select
              label={strings.type}
              className={classes.input}
              fullWidth
              name="type"
              defaultValue={values.type}
              onChange={handleChange}
            >
              {Object.keys(issueType).map((el) => (
                <MenuItem key={el} value={el}>
                  {issueType[el as keyof typeof issueType]}
                </MenuItem>
              ))}
            </Select>

            <Box className={classes.input}>
              <Label variant="caption">{strings.subject}</Label>
              <TextField
                multiline
                minRows={4}
                maxRows={4}
                name="subject"
                value={values.subject}
                fullWidth
                onChange={handleChange}
                error={Boolean(errors.subject)}
                helperText={errors?.subject}
              />
            </Box>

            <Box className={classes.input}>
              <Label variant="caption">{strings.description}</Label>
              <TextField
                multiline
                fullWidth
                minRows={4}
                maxRows={4}
                name="description"
                value={values.description}
                onChange={handleChange}
                error={error || Boolean(errors.description)}
                helperText={error || errors?.description}
              />
            </Box>
            <ModalButtons
              submitId="submitCreateTicketModal"
              cancelId="cancelCreateTicketModal"
              closeModal={() => setModalVisible(false)}
              onClick={handleSubmit}
              isSubmitting={false}
            />
          </Box>
        </Modal>
      )}
    </Formik>
  );
};

CreateTicket.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
  caseIds: PropTypes.any,
};

export default CreateTicket;
