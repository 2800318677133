import { NotificationResponseSchema } from "../interfaces/notification.interface";

const NUMBER_ROWS = 20;

const getPages = (pageSize: number, count: number) => {
  if (count === 0 || count <= pageSize) return 1;

  return Math.ceil(count / pageSize);
};

const nextPageOptions = {
  getNextPageParam: (lastPage: NotificationResponseSchema, allPages: Array<NotificationResponseSchema>) => {
    const pages = allPages.length;
    if (lastPage.data.length < NUMBER_ROWS) {
      // no more pages
      return 0;
    }

    return pages;
  },
};

export { getPages, nextPageOptions };
