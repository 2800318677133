import PropTypes from 'prop-types';

export const ticketCommentShape = PropTypes.shape({
  author: PropTypes.string,
  date: PropTypes.string,
  text: PropTypes.string,
});

export const ticketDataShape = PropTypes.shape({
  id: PropTypes.number,
  type: PropTypes.string,
  rotation: PropTypes.string,
  status: PropTypes.string,
  author: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  comment: PropTypes.string,
  size: PropTypes.string,
  wine: PropTypes.string,
  supplier: PropTypes.string,
  vintage: PropTypes.string,
  comments: PropTypes.arrayOf(ticketCommentShape),
});

export const historyDataShape = PropTypes.shape({
  type: PropTypes.string,
  comment: PropTypes.string,
  currentOwner: PropTypes.string,
  timeStamp: PropTypes.string,
  blockHash: PropTypes.string,
});

export const stockDataShape = PropTypes.shape({
  id: PropTypes.number,
  warehouseShortCode: PropTypes.string,
  supplierName: PropTypes.string,
  lwin: PropTypes.string,
  wineName: PropTypes.string,
  vintage: PropTypes.number,
  size: PropTypes.string,
  stockId: PropTypes.number,
  rotationNumber: PropTypes.string,
  purchasePrice: PropTypes.number,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  status: PropTypes.string,
  hasNote: PropTypes.bool,
  purchaseOrderNumber: PropTypes.string,
  batchNumber: PropTypes.number,
  departedDate: PropTypes.string,
  currentOwner: PropTypes.string,
  clientName: PropTypes.string,
  note: PropTypes.string,
  bondedStatus: PropTypes.string,
  marketPrice: PropTypes.number,
  landingDate: PropTypes.string,
  hash: PropTypes.string,
  cwPurchasePrice: PropTypes.number,
  clientPurchasePrice: PropTypes.number,
});

export const dealsDataShape = PropTypes.shape({
  id: PropTypes.number,
  status: PropTypes.string,
  type: PropTypes.string,
  dealNumber: PropTypes.string,
  clientName: PropTypes.string,
  note: PropTypes.string,
  portfolioManagerName: PropTypes.string,
  paidDate: PropTypes.string,
  deliveryCountry: PropTypes.string,
});

export const dealsDetailDataShape = PropTypes.shape({
  id: PropTypes.number,
  clientName: PropTypes.string,
  dealNumber: PropTypes.string,
  note: PropTypes.string,
  paidDate: PropTypes.string,
  portfolioManagerName: PropTypes.string,
  status: PropTypes.string,
  type: PropTypes.string,
  address: PropTypes.shape({
    address: PropTypes.string,
    postCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }),
  deliveryAddress: PropTypes.string,
});

export const auditDataShape = PropTypes.shape({
  id: PropTypes.number,
  service: PropTypes.string,
  entity: PropTypes.string,
  auxiliarEntityId: PropTypes.number,
  entityId: PropTypes.number,
  data: PropTypes.any,
  userName: PropTypes.string,
  action: PropTypes.string,
  createdAt: PropTypes.string,
});

export const navigation = PropTypes.shape({
  navigate: PropTypes.func.isRequired,
});

export const stockNotification = PropTypes.shape({
  size: PropTypes.string,
  lwin: PropTypes.string,
  caseId: PropTypes.number,
  entity: PropTypes.string,
  stockId: PropTypes.number,
  vintage: PropTypes.string,
  wineName: PropTypes.string,
  supplierName: PropTypes.string,
  rotationNumber: PropTypes.string,
});

export const ticketNotification = PropTypes.shape({
  entity: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  rotationNumber: PropTypes.string,
  authorName: PropTypes.string,
  createdAt: PropTypes.string,
  issueId: PropTypes.number,
});

export const users = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  email: PropTypes.string,
  role: PropTypes.string,
  warehouseNames: PropTypes.arrayOf(PropTypes.string),
});

export const transactionDataShape = PropTypes.shape({
  transactionId: PropTypes.string,
  block: PropTypes.string,
  transactionCreatedDate: PropTypes.string,
  assetId: PropTypes.number,
  from: PropTypes.string,
  to: PropTypes.string,
  transactionType: PropTypes.string,
  wineName: PropTypes.string,
  vintage: PropTypes.string,
  unitSize: PropTypes.string,
  region: PropTypes.string,
});
