import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useEffect } from "react";
import { strings } from "common";
import { MainButton } from "../components/Common";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    flexDirection: "column",
    border: "1px solid red",
  },
});

const reloadPage = () => window.location.reload();

function ErrorFallback({ error }: { error: Error }) {
  const appInsights = useAppInsightsContext();

  useEffect(() => {
    if (error) {
      appInsights.trackException({ error: error, severityLevel: SeverityLevel.Error });
    }
  }, [appInsights, error]);

  const classes = useStyles();
  return (
    <Grid className={classes.container} container direction="column" spacing={10}>
      <Grid item>
        <Typography variant="h1">{error.message}</Typography>
      </Grid>
      <Grid item>
        <Typography variant="h4">{`${strings.wrong}. ${strings.try_again}`}</Typography>
      </Grid>
      <Grid item>
        <MainButton onClick={reloadPage}>{strings.reload_page}</MainButton>
      </Grid>
    </Grid>
  );
}

// ErrorFallback.propTypes = {
//   error: PropTypes.shape({
//     message: PropTypes.string,
//     status: PropTypes.number,
//   }),
// };

export default ErrorFallback;
