import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { strings } from "common";
import PropTypes from "prop-types";
import React, { FormEvent, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { CancelFilledButton, MainButton } from "../components/Common";
import { BreadCrumb } from "../components/Table";
import { UserDeleteModal } from "../components/Users";
import { roles, rolesList } from "../constants";
import { useCreateUser, useSetUserWarehouse, useUpdateUser } from "../utils/users";
import { useUsersDetail } from "../utils/usersDetail";
import { validateEmail } from "../utils/validation";
import { useWarehouses } from "../utils/warehouses";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(13),
  },
  section: {
    padding: theme.spacing(8, 8, 8, 8),
    justifyContent: "space-between",
    background: theme.palette.grey[50],
  },
  role: {
    marginTop: theme.spacing(4),
  },
  warehouses: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(8),
  },
  save: {
    marginTop: theme.spacing(4),
  },
  adminCheck: {
    marginTop: theme.spacing(4),
  },
  deleteButton: {
    marginLeft: theme.spacing(7),
  },
}));

interface UserDataSchema {
  name: string;
  role: string;
  email: string;
  admin: boolean;
}

const UsersDetail = ({ createUser }: { createUser: boolean }) => {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const history = useHistory();

  const { data: warehousesResponseData, isLoading: isLoadingWarehouses } = useWarehouses(false);
  const { data: responseData, isLoading: isLoadingUser } = useUsersDetail(id, {
    enabled: !createUser,
  });
  const { mutateAsync: setUserWarehouse } = useSetUserWarehouse();
  const { mutateAsync: setUserUpdate } = useUpdateUser();
  const { mutateAsync: postUser } = useCreateUser();

  const [validEmail, setValidEmail] = useState(true);

  const [state, setState] = useState({
    name: "",
    email: "",
    role: "",
    admin: false,
  });

  const [error, setError] = useState(false);
  const [warehouses, setWarehouses] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const userData = responseData;
  console.log("userData", userData);
  const selectedWarehouseIds = useMemo(() => userData?.warehouses?.map((item) => item.id), [userData?.warehouses]);
  const warehousesData = warehousesResponseData ?? [];
  useEffect(() => {
    if (selectedWarehouseIds && warehousesData.length > 0 && selectedWarehouseIds?.length > 0) {
      setWarehouses(
        warehousesData.reduce(
          (accum, wh) => ({
            ...accum,
            [wh.id]: selectedWarehouseIds?.includes(wh.id),
          }),
          {}
        )
      );
    }
  }, [selectedWarehouseIds, warehousesData]);

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      const { name, role, email, admin }: UserDataSchema = userData;

      setState({
        name,
        role,
        email,
        admin,
      });
    }
  }, [isLoadingUser, userData]);

  const onChangeRole = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === rolesList.OPERATIONS) {
      setWarehouses({});
    }
    setState({ ...state, role: e.target.value });
  };

  const setStateKey = (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [key]: e.target.value });
  };

  const closeModal = () => {
    setIsDeleting(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const warehouseIds = Object.keys(warehouses).filter((key) => warehouses[key as keyof typeof warehouses]);

    try {
      setError(false);

      if (createUser) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await postUser(state);
      } else {
        /**update user */
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await setUserWarehouse({ id, warehouseIds });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await setUserUpdate({ id, ...state });
      }

      history.push("/users");
    } catch (error) {
      setError(true);
    }
  };

  const isLoading = isLoadingWarehouses || isLoadingUser;
  const isInvalidSubmission =
    state.email.length === 0 || state.name.length === 0 || state.role.length === 0 || !validEmail;
  const handleEmailValidation = () => setValidEmail(validateEmail(state.email));

  return (
    <Box>
      <BreadCrumb path="Users" title={`${userData?.name || ""}`} isLoading={isLoading} />

      <Box marginTop={8} className={classes.section}>
        <Box className={classes.title}>
          <Typography variant="h4">{strings.information}</Typography>
        </Box>

        {!isLoading && (
          <form onSubmit={handleSubmit}>
            <Grid spacing={10} container>
              <Grid item xs={6}>
                <TextField
                  label="Name"
                  required
                  inputProps={{ "aria-label": "name" }}
                  fullWidth
                  value={state.name}
                  onChange={setStateKey("name")}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  required
                  error={!validEmail}
                  onBlur={handleEmailValidation}
                  inputProps={{ "aria-label": "email" }}
                  fullWidth
                  value={state.email}
                  onChange={setStateKey("email")}
                />
              </Grid>
            </Grid>

            <FormControlLabel
              className={classes.adminCheck}
              control={
                <Checkbox
                  color="primary"
                  checked={state.admin}
                  name={"adminCheckbox"}
                  onChange={(e) => setState({ ...state, admin: e.target.checked })}
                />
              }
              label={strings.admin}
            />

            <Box className={classes.role}>
              <Typography variant="caption" color="primary">
                {strings.role}
              </Typography>

              <Box>
                <RadioGroup row aria-label="role" name="role" value={state.role} onChange={onChangeRole}>
                  <FormControlLabel
                    value={rolesList.OPERATIONS}
                    control={<Radio color="primary" />}
                    label={roles.OPERATIONS}
                  />
                  <FormControlLabel
                    value={rolesList.WAREHOUSING}
                    control={<Radio color="primary" />}
                    label={roles.WAREHOUSING}
                  />
                  <FormControlLabel
                    value={rolesList.ADMIN_WAREHOUSING}
                    control={<Radio color="primary" />}
                    label={roles.ADMIN_WAREHOUSING}
                  />
                </RadioGroup>
              </Box>
            </Box>

            <Box className={classes.warehouses}>
              <Box>
                <Typography variant="caption" color="primary">
                  {strings.warehouses}
                </Typography>
              </Box>

              {warehousesData.map((currentWarehouse, index) => (
                <FormControlLabel
                  key={`checkbox-${index}`}
                  checked={warehouses[currentWarehouse.id as keyof typeof warehouses] || false}
                  onChange={(_e, checked) => setWarehouses({ ...warehouses, [currentWarehouse.id]: checked })}
                  control={<Checkbox color="primary" value={currentWarehouse} name={`checkbox-${index}`} />}
                  label={currentWarehouse.warehouseName}
                />
              ))}
            </Box>

            <Box display="flex" flexDirection="row">
              <MainButton disabled={isInvalidSubmission} type="submit" className={classes.save}>
                {strings.save}
              </MainButton>

              {!createUser && (
                <Box className={classes.deleteButton}>
                  <CancelFilledButton className={classes.save} onClick={() => setIsDeleting(true)}>
                    {strings.delete}
                  </CancelFilledButton>
                </Box>
              )}
            </Box>

            <Box marginTop={6}>{error && <Typography color="error">{strings.wrong}</Typography>}</Box>
          </form>
        )}
      </Box>

      {isDeleting && userData && (
        <UserDeleteModal
          isOpen={isDeleting}
          id={+id}
          role={roles[userData.role]}
          name={userData.name}
          closeModal={closeModal}
        />
      )}
    </Box>
  );
};

UsersDetail.propTypes = {
  createUser: PropTypes.bool,
};

UsersDetail.defaultProps = {
  createUser: false,
};

export default UsersDetail;
