import { Box, makeStyles, TextField, Typography } from "@material-ui/core";
import { DateTime } from "luxon";
import React, { ChangeEvent } from "react";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { dateFormat, filtersToParams } from "../../constants";
import { strings, types } from "common";
import { ClickableIcon, LabelValue } from "../Common";
import { useUpdateDeal } from "../../utils/deals";
import { useQueryClient } from "react-query";
import { DealDetailInformationSchema } from "../../interfaces/deal.interface";

const useStyles = makeStyles((theme) => ({
  information: {
    display: "flex",
    marginTop: theme.spacing(8),
    background: theme.palette.grey[50],
    flexDirection: "column",
    padding: theme.spacing(8, 8, 8, 8),
  },
  gridContainer: {
    "grid-template-columns": "repeat(7, 1fr)",
  },
  gridItem: {
    "grid-column": 1 / 7,
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
  close: {
    color: theme.palette.error.main,
  },
  comment: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    marginLeft: -theme.spacing(2),
  },
}));

const Information = ({ data }: { data: DealDetailInformationSchema }) => {
  const classes = useStyles();
  const [isFocused, setFocus] = React.useState<boolean>();
  const [comment, setComment] = React.useState(data.note || "");
  const [error, setError] = React.useState<boolean>(false);
  const inputRef = React.useRef<any>();

  const { mutateAsync: updateDeal } = useUpdateDeal();
  const queryClient = useQueryClient();

  const onFocus = () => {
    setFocus(true);
  };

  const onCancel = () => {
    setFocus(false);
    setComment(data.note || "");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    inputRef?.onBlur?.();

    if (error) {
      queryClient.invalidateQueries("dealsSearch");
      setError(false);
    }
  };

  const onAccept = async () => {
    setFocus(false);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    inputRef?.onBlur?.();

    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await updateDeal({ id: data.id, note: comment });
      queryClient.refetchQueries("dealsSearch");
      setError(false);
    } catch (error) {
      setError(true);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const note = e.target.value;
    setComment(note);
  };
  return (
    <Box className={classes.information}>
      <Typography variant="h5">{strings.information}</Typography>

      <Box marginTop={8}>
        <Box display="grid" className={classes.gridContainer}>
          <Box display="grid" className={classes.gridItem}>
            <LabelValue label={strings.accountHolderName} value={data.accountHolderName}></LabelValue>
          </Box>
          <Box display="grid" className={classes.gridItem}>
            <LabelValue label={strings.client} value={data.clientName}></LabelValue>
          </Box>
          <Box display="grid" className={classes.gridItem}>
            <LabelValue label={strings.deal_transaction_type} value={data.type}></LabelValue>
          </Box>
          <Box display="grid" className={classes.gridItem}>
            <LabelValue label={strings.delivery_address} value={data.deliveryAddress}></LabelValue>
          </Box>
          <Box display="grid" className={classes.gridItem}>
            <LabelValue
              label={strings.paid_date}
              value={data.paidDate ? `${DateTime.fromISO(data.paidDate).toFormat(dateFormat)}` : "-"}
            ></LabelValue>
          </Box>
          <Box display="grid" className={classes.gridItem}>
            <LabelValue label={strings.pm} value={data.portfolioManagerName}></LabelValue>
          </Box>
          <Box display="grid" className={classes.gridItem}>
            <LabelValue
              label={strings.status}
              value={filtersToParams[data.status as keyof typeof filtersToParams]}
            ></LabelValue>
          </Box>
          <Box display="grid" className={classes.gridItem}>
            <LabelValue label={strings.cases_ship} value={`${data.countToShip}`}></LabelValue>
          </Box>
        </Box>
      </Box>

      <Box marginTop={18}>
        <Typography color="primary" variant="caption">
          {strings.comment}
        </Typography>
        <TextField
          inputProps={{ "aria-label": "comment" }}
          ref={inputRef}
          fullWidth
          value={comment}
          error={Boolean(error)}
          helperText={error ? strings.comment_failed : ""}
          onChange={onChange}
          onFocus={onFocus}
        />
        {isFocused && (
          <Box className={classes.comment}>
            <ClickableIcon aria-label="accept" onClick={onAccept}>
              <CheckIcon fontSize="small" className={classes.checkIcon} />
            </ClickableIcon>

            <ClickableIcon aria-label="cancel" onClick={onCancel}>
              <CloseIcon fontSize="small" className={classes.close} />
            </ClickableIcon>
          </Box>
        )}
      </Box>
    </Box>
  );
};

Information.propTypes = {
  data: types.dealsDetailDataShape,
};

export default Information;
