import { strings } from "common";
import { issueStatus } from "../../constants";
import { TableCellSchema } from "../../interfaces/table.interface";

export const cells: TableCellSchema[] = [
  {
    width: "3%",
    text: strings.id,
    key: "id",
  },
  {
    width: "6%",
    text: strings.type,
    key: "type",
    disableInput: true,
  },
  {
    width: "10%",
    text: strings.wine_name,
    key: "wineName",
  },
  {
    width: "6%",
    text: strings.lwin_18,
    key: "lwin18",
  },
  {
    width: "7%",
    text: strings.rotation,
    key: "rotationNumber",
  },
  {
    width: "6%",
    text: strings.status,
    key: "status",
    selectOptions: Object.keys(issueStatus).map((status) => ({
      key: issueStatus[status] ?? "Open",
      value: status,
    })),
  },
  {
    width: "3%",
    text: strings.author,
    key: "authorName",
  },
  {
    width: "8%",
    text: strings.supplier,
    key: "supplierName",
  },
  {
    width: "8%",
    text: strings.creation_date,
    key: "createdAt",
    disableInput: true,
  },
  {
    width: "7%",
    text: strings.update_date,
    key: "updatedAt",
    disableInput: true,
  },
  {
    width: "7%",
    text: strings.vintage,
    key: "vintage",
  },
  {
    width: "6%",
    text: strings.size,
    key: "unitSize",
  },
];

export const emptyCells = {
  firstCell: {
    width: "3%",
  },
  lastCell: {
    width: "4%",
  },
};
