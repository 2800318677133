import { Box, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { Visibility } from "@material-ui/icons";
import { useHistory } from "react-router";
import { dateFormat, filtersToParams, salesType } from "../../constants";
import { strings } from "common";
import { ClickableIcon, LabelValue } from "../Common";
import { DealDetailResponseSchema } from "../../interfaces/deal.interface";

const useStyles = makeStyles((theme) => ({
  visibilityIcon: {
    height: "28px",
    width: "28px",
  },
  information: {
    display: "flex",
    marginTop: theme.spacing(8),
    background: theme.palette.grey[50],
    flexDirection: "column",
    padding: theme.spacing(8, 8, 8, 8),
  },
  gridContainer: {
    "grid-template-columns": "repeat(8, 1fr)",
  },
  gridItem: {
    "grid-column": 1 / 8,
  },
  checkIcon: {
    color: theme.palette.success.main,
  },
  close: {
    color: theme.palette.error.main,
  },
  comment: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    marginLeft: -theme.spacing(2),
  },
}));

const MultipleDealsInformation = ({ data }: { data: Array<DealDetailResponseSchema> }) => {
  const classes = useStyles();
  const history = useHistory();

  const goToDetail = (dealId: number) => {
    history.push(`/deals/${dealId}`);
  };

  return (
    <Box className={classes.information}>
      <Typography variant="h5">{strings.information}</Typography>

      <Box marginTop={8}>
        <Box display="grid" className={classes.gridItem} marginBottom={8}>
          <LabelValue label={strings.accountHolderName} value={data[0]?.accountHolderName}></LabelValue>
        </Box>
        {data.map((deal) => {
          // const deal = data[index];
          if (deal && deal.dealNumber) {
            return (
              <Box display="grid" className={classes.gridContainer}>
                <Box display="grid" className={classes.gridItem}>
                  <LabelValue label={strings.deal_number} value={deal.dealNumber}></LabelValue>
                </Box>
                <Box display="grid" className={classes.gridItem}>
                  <LabelValue label={strings.sales_type} value={salesType[deal.type]}></LabelValue>
                </Box>
                <Box display="grid" className={classes.gridItem}>
                  <LabelValue label={strings.client} value={deal.clientName}></LabelValue>
                </Box>
                <Box display="grid" className={classes.gridItem}>
                  <LabelValue label={strings.delivery_address} value={deal.deliveryAddress}></LabelValue>
                </Box>
                <Box display="grid" className={classes.gridItem}>
                  <LabelValue
                    label={strings.paid_date}
                    value={deal.paidDate ? `${DateTime.fromISO(deal.paidDate).toFormat(dateFormat)}` : "-"}
                  ></LabelValue>
                </Box>
                <Box display="grid" className={classes.gridItem}>
                  <LabelValue label={strings.pm} value={deal.portfolioManagerName}></LabelValue>
                </Box>
                <Box display="grid" className={classes.gridItem}>
                  <LabelValue
                    label={strings.status}
                    value={filtersToParams[deal.status as keyof typeof filtersToParams]}
                  ></LabelValue>
                </Box>
                <Box display="grid" className={classes.gridItem}>
                  <ClickableIcon
                    aria-label="dealDetail"
                    onClick={() => goToDetail(deal.id)}
                    className={classes.visibilityIcon}
                  >
                    <Visibility fontSize="small" color="primary" />
                  </ClickableIcon>
                </Box>
              </Box>
            );
          } else {
            return <></>;
          }
        })}
      </Box>
    </Box>
  );
};

MultipleDealsInformation.propTypes = {
  data: PropTypes.object,
};

export default MultipleDealsInformation;
