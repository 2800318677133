import { useMutation, UseMutationResult, useQuery } from "react-query";
import { useClient } from "../providers/auth-context";
import { paramsToQueryString } from "./api-client";
import { SaleOrderResponseData, TableSearchSchema } from "../interfaces/table.interface";
import { CreateSaleOrderResponseSchema } from "../interfaces/case.interface";
import { ClientType } from "../interfaces/apiClient.interface";

function useSalesOrder(): UseMutationResult<CreateSaleOrderResponseSchema> {
  const client = useClient<CreateSaleOrderResponseSchema>();

  return useMutation((body) =>
    client(`sales-order`, {
      method: "POST",
      body: JSON.stringify(body),
    })
  );
}

const getPreadvicesSearchConfig = (client: ClientType<SaleOrderResponseData>, params: TableSearchSchema) => ({
  queryKey: ["sales-order", { params }],
  queryFn: () =>
    client(`sales-order?${paramsToQueryString(params)}`)
      .then((data) => data)
      .catch((err) => console.log(err)),
});

function useSearchSalesorders(params: TableSearchSchema) {
  const client = useClient<SaleOrderResponseData>();
  const result = useQuery(getPreadvicesSearchConfig(client, params));

  return { ...result, data: result.data };
}

export { useSalesOrder, useSearchSalesorders };
