import { strings } from "common";
import { httpCodes } from "../constants";
import { ClientConfigSchema, ParamType } from "../interfaces/apiClient.interface";
import { TableSearchSchema } from "../interfaces/table.interface";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const apiURL = window.env.REACT_APP_API_URL;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const apimKey = window.env.REACT_APP_APIM_KEY;

async function client<T>(
  endpoint: string,
  { data, customURL, customAPIM, ...customConfig }: ClientConfigSchema
): Promise<T> {
  const headers = new Headers();
  headers.set("Content-Type", data || customConfig.body ? "application/json" : "");
  if (apimKey) {
    headers.set("Ocp-Apim-Subscription-Key", customAPIM || apimKey);
  }
  const config: RequestInit = {
    credentials: "include",
    headers: headers,
    method: data ? "POST" : "GET",
    body: data ? JSON.stringify(data) : undefined,
    ...customConfig,
  };

  return window.fetch(`${customURL || apiURL}/${endpoint}`, config).then(async (response) => {
    if (response.status === httpCodes.UNAUTHORIZED) {
      if (endpoint === "users/my-user") {
        return {} as T;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.location.assign(window.location);
      return Promise.reject({ message: strings.re_authenticate });
    }

    if (response.status === httpCodes.NO_CONTENT) {
      return {} as T;
    }

    const data = (await response.json()) as T;

    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });
}

// if both options [true, false] are selected
// we should avoid sending the param
const shouldIgnoreParam = (arr: ParamType) => {
  if (Array.isArray(arr)) {
    return arr.length === 0 || (arr.some((el) => el === "true") && arr.some((el) => el === "false"));
  }

  return false;
};

const buildQueryStringElement = (key: string, value: ParamType) => {
  if (Array.isArray(value)) {
    return `${key}=${value.join(",")}`;
  }
  return `${key}=${value}`;
};

const paramsToQueryString = (params: TableSearchSchema) => {
  const queryString = Object.keys(params)
    .filter(
      (key) =>
        params[key as keyof TableSearchSchema] !== "" &&
        !shouldIgnoreParam(params[key as keyof TableSearchSchema]) &&
        key !== "currentDate"
    )
    .map((key) => buildQueryStringElement(key, params[key as keyof TableSearchSchema]))
    .join("&");

  return queryString || "";
};

export { client, paramsToQueryString };
