import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import { CancelButton, MainButton } from ".";
import PropTypes from "prop-types";
import { strings } from "common";
import { MouseEventHandler } from "react";

const useStyles = makeStyles((theme) => ({
  buttonRow: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(12),
  },
  cancel: {
    marginLeft: theme.spacing(8),
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: theme.spacing(4),
  },
}));

interface ModalButtonsProps {
  submitId: string;
  cancelId: string;
  step?: number;
  actionText?: string | undefined;
  isSubmitting: boolean;
  closeModal: MouseEventHandler;
  onClick: any;
}

const ModalButtons = ({
  submitId,
  cancelId,
  step,
  actionText,
  isSubmitting,
  closeModal,
  onClick,
}: ModalButtonsProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.buttonRow}>
      <MainButton id={submitId} disabled={isSubmitting} type="submit" onClick={onClick}>
        {actionText || (step === 0 ? strings.next : strings.save)}
      </MainButton>

      <CancelButton id={cancelId} className={classes.cancel} disabled={isSubmitting} onClick={closeModal}>
        {strings.cancel}
      </CancelButton>

      {isSubmitting && (
        <Box data-testid="loading" className={classes.spinner}>
          <CircularProgress size={20} />
        </Box>
      )}
    </Box>
  );
};

ModalButtons.propTypes = {
  submitId: PropTypes.string.isRequired,
  cancelId: PropTypes.string.isRequired,
  step: PropTypes.number,
  isSubmitting: PropTypes.bool,
  closeModal: PropTypes.func,
  actionText: PropTypes.string,
  additionalActionText: PropTypes.string,
  onClick: PropTypes.func,
  onAdditionalActionClick: PropTypes.func,
};

export default ModalButtons;
